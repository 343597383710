import { connect } from "react-redux";
import { createForm, storePartialFormData, } from "../../../redux/form/form.actions";
import { makeGetSchemas, mergedPartialFormDataSelector, partialFormDataSelector, withSummarySelector, } from "../../../redux/form/form.selectors";
import FormMultistep from "./FormMultistep";
var mapStateToProps = function (state, _a) {
    var formId = _a.formId, stepIndex = _a.stepIndex;
    var getSchemas = makeGetSchemas();
    return {
        schemas: getSchemas(state, formId),
        mergedPartialFormData: mergedPartialFormDataSelector(state, formId),
        partialFormData: partialFormDataSelector(state, formId, stepIndex),
        withSummary: withSummarySelector(state, formId),
    };
};
var mapDispatchToProps = {
    createForm: createForm,
    storePartialFormData: storePartialFormData,
};
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(FormMultistep);
