var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Platform, Text, TouchableOpacity, View, } from "react-native";
import styled from "../../theme/styled-components.native";
import { EButtonSize, ETextSize } from "./ButtonPrimary";
export var Wrap = styled(TouchableOpacity)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", "px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  border-radius: 4px;\n  opacity: ", ";\n  width: ", ";\n  display: ", ";\n  border-width: 1px;\n  border-color: ", ";\n  background-color: ", ";\n"], ["\n  padding: ", "px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  border-radius: 4px;\n  opacity: ", ";\n  width: ", ";\n  display: ", ";\n  border-width: 1px;\n  border-color: ", ";\n  background-color: ", ";\n"])), function (props) { return (props.isLoading ? 2 : 12); }, function (props) { return (props.disabled ? 0.5 : 1); }, function (props) {
    switch (props.buttonSize) {
        case EButtonSize.Small:
            return "96px";
        case EButtonSize.Medium:
            return "160px";
        case EButtonSize.Large:
            return "190px";
        case EButtonSize.ExtraLarge:
            return "240px";
        case EButtonSize.Full:
            return "100%";
        case EButtonSize.Inline:
        default:
            return "auto";
    }
}, function (props) {
    if (props.buttonSize === EButtonSize.Inline) {
        return Platform.OS === "web" ? "inline-block" : "flex";
    }
    return Platform.OS === "web" ? "auto" : "flex";
}, function (props) { return props.backgroundColor || props.theme.colors.global5; }, function (props) {
    switch (true) {
        case !!props.backgroundColor:
            return props.backgroundColor;
        case props.isInverted:
            return "transparent";
        default:
            return props.theme.colors.global5;
    }
});
export var InnerWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n  flex-direction: row;\n"], ["\n  justify-content: center;\n  align-items: center;\n  flex-direction: row;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.isInverted
        ? props.theme.colors.global5
        : props.tintColor || props.theme.colors.neutral2,
}); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 8px;\n  height: 1px;\n"], ["\n  margin-right: 8px;\n  height: 1px;\n"])));
export var Title = styled(Text).attrs(function () { return ({
    numberOfLines: 1,
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: bold;\n  font-family: ", ";\n  font-size: ", ";\n  color: ", ";\n"], ["\n  font-size: 16px;\n  font-weight: bold;\n  font-family: ", ";\n  font-size: ", ";\n  color: ", ";\n"])), function (props) { return props.theme.fonts.primary; }, function (props) {
    switch (props.textSize) {
        case ETextSize.Small:
            return "14px";
        case ETextSize.Medium:
            return "16px";
        case ETextSize.Large:
            return "18px";
        default:
            return "16px";
    }
}, function (props) {
    switch (true) {
        case !!props.tintColor:
            return props.tintColor;
        case props.isInverted:
            return props.theme.colors.global5;
        case !props.isInverted:
            return props.tintColor || props.theme.colors.neutral2;
        default:
            return props.theme.colors.neutral2;
    }
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
