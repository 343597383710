import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { apiDateWithoutTimeToDate } from "utils/date.utils";
import { EField, IExtraData } from "./schema.contactInfo.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { lead } = extraData;

  return {
    [EField.ContactDamagePastYears]:
      (lead.contactDamageHistory || 0) > 0 ? "yes" : "no",
    [EField.ContactDamagePastAmount]: lead.contactDamageHistory,
    [EField.ContactDamagePastTermination]: "no",
    [EField.ContactFirstname]: lead.contactFirstname,
    [EField.ContactLastname]: lead.contactLastname,
    [EField.ContactEmail]: lead.contactEmail,
    [EField.ContactMobileNumber]: lead.contactMobileNumber,
    [EField.ContactDateOfBirth]: lead.contactDateOfBirth
      ? apiDateWithoutTimeToDate(lead.contactDateOfBirth)
      : undefined,
    // [EField.ContactPassportNumber]: lead.contactNationalNumber
    //   ? lead.contactNationalNumber
    //   : undefined,
  };
};
