var _a;
export var isContact = function (possibleContact) {
    return (possibleContact === null || possibleContact === void 0 ? void 0 : possibleContact.accountId) !== undefined;
};
export var EContactType;
(function (EContactType) {
    EContactType["Normal"] = "N";
    EContactType["Personal"] = "P";
    EContactType["Support"] = "S";
    // Repair = "R", // Disabled for now
})(EContactType || (EContactType = {}));
export var EContactRoles;
(function (EContactRoles) {
    EContactRoles["Public"] = "PUBLIC";
})(EContactRoles || (EContactRoles = {}));
export var EContactInitialType;
(function (EContactInitialType) {
    EContactInitialType["Owner"] = "O";
    EContactInitialType["Tenant"] = "T";
    EContactInitialType["Manager"] = "M";
    EContactInitialType["Other"] = "X";
})(EContactInitialType || (EContactInitialType = {}));
export var CONTACT_INITIAL_TYPE_READABLE = (_a = {},
    _a[EContactInitialType.Owner] = "owner",
    _a[EContactInitialType.Tenant] = "tenant",
    _a[EContactInitialType.Manager] = "manager",
    _a[EContactInitialType.Other] = "other",
    _a);
export var EExtraIbanSelectValues;
(function (EExtraIbanSelectValues) {
    EExtraIbanSelectValues[EExtraIbanSelectValues["AddIban"] = -1] = "AddIban";
    EExtraIbanSelectValues[EExtraIbanSelectValues["Unknown"] = -2] = "Unknown";
})(EExtraIbanSelectValues || (EExtraIbanSelectValues = {}));
