import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import assets from "assets";
import { transparentize } from "polished";

export const Wrap = styled.button<{ isConfirmed: boolean }>`
  width: 100%;
  background-color: ${props =>
    props.isConfirmed
      ? transparentize(0.95, props.theme.colors.global5)
      : transparentize(0.8, props.theme.colors.global4)};
  border: 1px solid
    ${props =>
      props.isConfirmed
        ? props.theme.colors.global5
        : props.theme.colors.global2};
  border-radius: 5px;
  text-align: left;
`;

export const InnerWrap = styled.div`
  padding: 12px 4px 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Checkbox = styled.img.attrs<{ isConfirmed: boolean }>(props => ({
  src: props.isConfirmed
    ? assets.ICO_CHECK_CHECKED
    : assets.ICO_CHECK_UNCHECKED,
}))<{ isConfirmed: boolean }>`
  margin-right: 12px;
`;

export const Title = styled.span`
  font-weight: 14px;
  font-weight: 500px;
  color: ${props => props.theme.colors.global2};
`;
