export var createRoutine = function (base) {
    var types = {
        START: "".concat(base, "/START"),
        SUCCESS: "".concat(base, "/SUCCESS"),
        FAILURE: "".concat(base, "/FAILURE"),
    };
    var payloads = {
        start: function (payload) { return payload; },
        success: function (payload) { return payload; },
        failure: function (payload) { return payload; },
    };
    var actions = {
        start: function (payload) { return ({ type: types.START, payload: payload }); },
        success: function (payload) { return ({ type: types.SUCCESS, payload: payload }); },
        failure: function (payload) { return ({ type: types.FAILURE, payload: payload }); },
    };
    return { types: types, payloads: payloads, actions: actions };
};
