var store;
export var setStore = function (newStore) {
    store = newStore;
};
export var getStore = function () {
    if (!store) {
        // eslint-disable-next-line no-console
        console.warn("No store set, please use `setStore` in your application to pass the store to the shared module.");
    }
    return store;
};
