var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Text, View } from "react-native";
import styled from "../../theme/styled-components.native";
export var Wrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  background-color: ", ";\n  padding-left: 4px;\n  padding-right: 4px;\n  border-radius: 4px;\n  min-height: 16px;\n"], ["\n  flex-direction: row;\n  align-items: center;\n  background-color: ", ";\n  padding-left: 4px;\n  padding-right: 4px;\n  border-radius: 4px;\n  min-height: 16px;\n"])), function (props) { return props.theme.colors.errorText; });
export var Icon = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 12px;\n  height: 12px;\n"], ["\n  width: 12px;\n  height: 12px;\n"])));
export var Spacing = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 4px;\n"], ["\n  width: 4px;\n"])));
export var Title = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 13px;\n  font-weight: 600;\n"], ["\n  color: ", ";\n  font-size: 13px;\n  font-weight: 600;\n"])), function (props) { return props.theme.colors.neutral2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
