import React from "react";
import * as S from "./InfoList.styles";

interface IProps {
  title: string;
  info: string;
}

const InfoList: React.FC<IProps> = (props: IProps) => {
  const { title, info } = props;
  return (
    <S.Wrap>
      <S.Title>{title}</S.Title>
      <S.Info>{info}</S.Info>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default InfoList;
