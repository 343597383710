import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "./payment.actions";
import * as paymentSelectors from "./payment.selectors";
export var useGetPayment = function (params) {
    var leadId = params.leadId, quoteId = params.quoteId, paymentId = params.paymentId, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var payment = useSelector(function (state) {
        return paymentSelectors.getPayment(state, paymentId || "-1");
    });
    var isFetching = useSelector(function (state) {
        return paymentSelectors.isFetchingPayment(state, paymentId || "-1");
    });
    var fetchError = useSelector(function (state) {
        return paymentSelectors.fetchPaymentError(state, paymentId || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (isFetching) {
            return;
        }
        if (!leadId || !quoteId || !paymentId) {
            return;
        }
        dispatch(paymentActions.getPayment.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            paymentId: paymentId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId, quoteId, paymentId]);
    // Actions
    var fetchPayment = function () {
        if (!leadId || !quoteId || !paymentId) {
            return;
        }
        dispatch(paymentActions.getPayment.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            paymentId: paymentId,
            publicId: publicId,
        }));
    };
    return { payment: payment, isFetching: isFetching, fetchError: fetchError, fetchPayment: fetchPayment };
};
// Initialization flow:
// Fetches quote's activePaymentId if available & sets fetched payment as activePayment
export var useActivePayment = function (params) {
    var leadId = params.leadId, quote = params.quote, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var activePayment = useSelector(function (state) {
        return paymentSelectors.getActivePaymentByQuoteId(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var isFetching = useSelector(function (state) {
        return paymentSelectors.isFetchingPayment(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var fetchError = useSelector(function (state) {
        return paymentSelectors.fetchPaymentError(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var isCreating = useSelector(function (state) {
        return paymentSelectors.isCreatingPayment(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    var createError = useSelector(function (state) {
        return paymentSelectors.createPaymentError(state, (quote === null || quote === void 0 ? void 0 : quote.id) || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (!leadId || !quote || !publicId) {
            return;
        }
        if (!quote.activePaymentId) {
            return;
        }
        if (isFetching) {
            return;
        }
        dispatch(paymentActions.getPayment.actions.start({
            leadId: leadId,
            quoteId: quote.id,
            paymentId: quote.activePaymentId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId, quote]);
    // Actions
    var createPayment = function () {
        if (!leadId || !quote || !publicId) {
            return;
        }
        if (isCreating) {
            return;
        }
        dispatch(paymentActions.createPayment.actions.start({
            leadId: leadId,
            quoteId: quote.id,
            publicId: publicId,
        }));
    };
    return {
        activePayment: activePayment,
        isFetching: isFetching,
        fetchError: fetchError,
        isCreating: isCreating,
        createError: createError,
        createPayment: createPayment,
    };
};
