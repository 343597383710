import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import FormContactSelectInlineV2JsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormContactSelectInlineV2/components/FormContactSelectInlineJsonFormSchema";
import FormDropdownSelectJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormDropdownSelect/components/FormDropdownSelectJsonFormSchema";
import { FormHelpInfoJsonFormSchema } from "@rentiohq/shared-frontend/dist/components/components/FormHelpInfo";
import FormIbanSelectInlineJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormIbanSelectInline/components/FormIbanSelectInlineJsonFormSchema";
import FormInlineSelectJsonFromSchema from "@rentiohq/shared-frontend/dist/components/components/FormInlineSelect/components/FormInlineSelectJsonFromSchema";
import FormInputCaptchaJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputCaptcha/components/FormInputCaptchaJsonFormSchema";
import FormInputDateJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputDate/components/FormInputDateJsonFormSchema";
import FormInputAddressJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputAddressJsonFormSchema";
import FormInputAddressStreetJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputAddressStreetJsonFormSchema";
import FormInputEmailJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputEmailJsonFormSchema";
import FormInputIbanJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputIbanJsonFormSchema";
import FormInputNumberJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputNumberJsonFormSchema";
import FormInputPhoneJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputPhoneJsonFormSchema";
import FormInputPhoneOrEmailJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputPhoneOrEmailJsonFormSchema";
import FormInputTextJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputTextJsonFormSchema";
import FormPropertySelectInlineJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormPropertySelectInline/components/FormPropertySelectInlineJsonFormSchema";
import { FormSectionHeaderJsonFormSchema } from "@rentiohq/shared-frontend/dist/components/components/FormSectionHeader";
import { FormSectionSubtitleJsonFormSchema } from "@rentiohq/shared-frontend/dist/components/components/FormSectionSubtitle";
import FormSegmentedControlJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormSegmentedControl/components/FormSegmentedControlJsonFormSchema";
import FormSwitchContactSelectJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormSwitchContactSelect/components/FormSwitchContactSelectJsonFormSchema";

export const fields = {
  [ERentioFormField.Address]: FormInputAddressJsonFormSchema,
  [ERentioFormField.AddressStreet]: FormInputAddressStreetJsonFormSchema,
  [ERentioFormField.Captcha]: FormInputCaptchaJsonFormSchema,
  [ERentioFormField.ContactSelectInlineV2]:
    FormContactSelectInlineV2JsonFormSchema,
  [ERentioFormField.Date]: FormInputDateJsonFormSchema,
  [ERentioFormField.DropdownSelect]: FormDropdownSelectJsonFormSchema,
  [ERentioFormField.Email]: FormInputEmailJsonFormSchema,
  [ERentioFormField.HelpInfo]: FormHelpInfoJsonFormSchema,
  [ERentioFormField.Iban]: FormInputIbanJsonFormSchema,
  [ERentioFormField.IbanSelectInline]: FormIbanSelectInlineJsonFormSchema,
  [ERentioFormField.InlineSelect]: FormInlineSelectJsonFromSchema,
  [ERentioFormField.Number]: FormInputNumberJsonFormSchema,
  [ERentioFormField.Phone]: FormInputPhoneJsonFormSchema,
  [ERentioFormField.PhoneOrEmail]: FormInputPhoneOrEmailJsonFormSchema,
  [ERentioFormField.PropertySelectInline]:
    FormPropertySelectInlineJsonFormSchema,
  [ERentioFormField.SectionHeader]: FormSectionHeaderJsonFormSchema,
  [ERentioFormField.SectionSubtitle]: FormSectionSubtitleJsonFormSchema,
  [ERentioFormField.SegmentedControl]: FormSegmentedControlJsonFormSchema,
  [ERentioFormField.SwitchContactSelect]: FormSwitchContactSelectJsonFormSchema,
  [ERentioFormField.Text]: FormInputTextJsonFormSchema,
};
