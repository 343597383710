var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import uniq from "lodash/uniq";
import React from "react";
import { getFieldInfo, getFieldTitle } from "../../../../redux/form/form.utils";
import FormContactSelectInline from "../FormContactSelectInline.container";
var FormContactSelectInlineJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormContactSelectInlineJsonFormSchema, _super);
    function FormContactSelectInlineJsonFormSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        _this.handleSelectAccount = function (accountId) {
            if (!_this.isMultiSelect()) {
                _this.updateFormData([accountId]);
                return;
            }
            var _a = _this.props.formData, formData = _a === void 0 ? [] : _a;
            var newContacts = uniq(__spreadArray(__spreadArray([], formData, true), [accountId], false));
            _this.updateFormData(newContacts);
        };
        _this.handleDeselectAccount = function (accountId) {
            if (!_this.isMultiSelect()) {
                _this.updateFormData([]);
                return;
            }
            var _a = _this.props.formData, formData = _a === void 0 ? [] : _a;
            var newContacts = formData.filter(function (randomId) { return randomId !== accountId; });
            _this.updateFormData(newContacts);
        };
        _this.handlePressSelectMoreContacts = function (getSubtitle, extraAccounts) {
            var _a, _b;
            var _c = _this.props, uiSchema = _c.uiSchema, _d = _c.formContext, formContext = _d === void 0 ? {} : _d, name = _c.name, formData = _c.formData;
            var disallowedAccountIds = (_a = uiSchema.getDisallowedAccountIds) === null || _a === void 0 ? void 0 : _a.call(uiSchema, formContext.getAllFormData());
            var disallowedContactIds = (_b = uiSchema.getDisallowedContactIds) === null || _b === void 0 ? void 0 : _b.call(uiSchema, formContext.getAllFormData());
            if (formContext.onSelectMore) {
                formContext.onSelectMore(name, formData, {
                    type: "more",
                    extraAccounts: extraAccounts,
                    getSubtitle: getSubtitle,
                    disallowedAccountIds: disallowedAccountIds,
                    disallowedContactIds: disallowedContactIds,
                });
            }
        };
        // Update
        _this.updateFormData = function (contactIds) {
            var _a = _this.props, onChange = _a.onChange, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, name = _a.name;
            var contactIdsFormatted = contactIds.length > 0 ? contactIds : undefined;
            onChange(contactIdsFormatted);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, contactIdsFormatted);
                }
            }, 0);
        };
        _this.isMultiSelect = function () {
            var uiSchema = _this.props.uiSchema;
            var customUiSchema = uiSchema;
            return customUiSchema.isMultiSelect;
        };
        return _this;
    }
    // Render
    FormContactSelectInlineJsonFormSchema.prototype.render = function () {
        var _a = this.props, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, formData = _a.formData, formContext = _a.formContext;
        var customUiSchema = uiSchema;
        var isDisabled = customUiSchema.isDisabled;
        var disabled = isDisabled
            ? isDisabled(formContext.getAllFormData())
            : undefined;
        return (_jsx(FormContactSelectInline, __assign({}, customUiSchema, { formData: formContext.getAllFormData(), selectedAccountIds: formData, onPressExtraInfo: this.handlePressExtraInfo, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), onSelectAccount: this.handleSelectAccount, onDeselectAccount: this.handleDeselectAccount, isMultiSelect: this.isMultiSelect(), onPressSelectMoreContacts: this.handlePressSelectMoreContacts, error: rawErrors[0], disabled: disabled })));
    };
    return FormContactSelectInlineJsonFormSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormContactSelectInlineJsonFormSchema;
