import assets from "assets";
import React from "react";
import { AlertComponentPropsWithStyle } from "react-alert";
import { StyleProp, ViewStyle } from "react-native";
import * as S from "./Alert.styles";

class Alert extends React.PureComponent<AlertComponentPropsWithStyle> {
  // Lifecycle methods
  public render = () => {
    const { style, options, message, close } = this.props;
    return (
      <S.Wrap
        style={style as StyleProp<ViewStyle>}
        type={options.type}
        onPress={close}
      >
        <S.CloseButton onPress={close}>
          <S.CloseButtonImage
            src={(() => {
              switch (options.type) {
                case "error":
                  return assets.ICO_CLOSE_ERROR;
                case "success":
                  return assets.ICO_CLOSE_SUCCESS;
                case "info":
                default:
                  return assets.ICO_CLOSE_INFO;
              }
            })()}
          />
        </S.CloseButton>
        <S.Message type={options.type}>{message}</S.Message>
      </S.Wrap>
    );
  };
}

// eslint-disable-next-line import/no-default-export
export default Alert;
