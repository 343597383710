var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ContactFetchListItem } from "../ContactFetchListItem";
import FormInputBase from "../FormInputBase";
import Spacer, { ESpacerWeight } from "../Spacer";
import SwitchToggle from "../SwitchToggle";
import * as S from "./FormSwitchContactSelect.styles";
var FormSwitchContactSelect = /** @class */ (function (_super) {
    __extends(FormSwitchContactSelect, _super);
    function FormSwitchContactSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderContactForOption = function (option) { return function (contact) {
            var _a;
            return (_jsxs(S.Content, { children: [_jsx(S.Title, { isTitle: !option.shouldShowSwitch, isActive: _this.isActive(option.id), children: ((_a = option.getLabel) === null || _a === void 0 ? void 0 : _a.call(option, contact)) || option.label }), option.shouldShowSwitch && (_jsx(SwitchToggle, { onValueChange: _this.handleChange(_this.isActive(option.id), option.id), value: _this.isActive(option.id) }))] }));
        }; };
        // Event handlers
        _this.handleChange = function (isActive, optionId) { return function () {
            _this.props.onChangeValue(isActive, optionId);
        }; };
        // Helpers
        _this.isActive = function (id) { return _this.props.selectedOptionIds.includes(id); };
        return _this;
    }
    // Render
    FormSwitchContactSelect.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, getOptions = _a.getOptions, error = _a.error, info = _a.info, disabled = _a.disabled, optional = _a.optional, showDisclosureIcon = _a.showDisclosureIcon, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo;
        return (_jsxs(FormInputBase, { title: title, info: info, error: error, disabled: disabled, optional: optional, showDisclosureIcon: showDisclosureIcon, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, showBorder: false, children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(S.Wrap, { children: getOptions().map(function (option) { return (_jsx(ContactFetchListItem, { accountId: option.accountId, renderContact: _this.renderContactForOption(option) }, option.id)); }) })] }));
    };
    return FormSwitchContactSelect;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormSwitchContactSelect;
