var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var removeUndefinedValuesFromObject = function (input) {
    var result = __assign({}, input);
    for (var property in result) {
        if (result.hasOwnProperty(property) && result[property] === undefined) {
            delete result[property];
        }
    }
    return result;
};
export var removeNullValuesFromObject = function (input) {
    var result = __assign({}, input);
    for (var property in result) {
        if (result.hasOwnProperty(property) && result[property] === null) {
            delete result[property];
        }
    }
    return result;
};
