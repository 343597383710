var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Text, TouchableOpacity, View, } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var Wrap = styled(TouchableOpacity)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: row;\n  justify-content: space-between;\n  height: 48px;\n  padding-left: 16px;\n  padding-right: 16px;\n  width: 100%;\n  ", "\n"], ["\n  flex-direction: row;\n  justify-content: space-between;\n  height: 48px;\n  padding-left: 16px;\n  padding-right: 16px;\n  width: 100%;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-left: 24px;\n    padding-right: 24px;\n  "], ["\n    padding-left: 24px;\n    padding-right: 24px;\n  "])))));
export var IconWrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n"], ["\n  justify-content: center;\n  align-items: center;\n  width: 40px;\n"])));
export var AddIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_ADD_CIRCLE,
}); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 25px;\n  height: 25px;\n  ", "\n"], ["\n  width: 25px;\n  height: 25px;\n  ", "\n"])), desktopStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 30px;\n    height: 30px;\n  "], ["\n    width: 30px;\n    height: 30px;\n  "])))));
export var TextWrap = styled(View)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  justify-content: center;\n  margin-left: ", ";\n  ", ";\n"], ["\n  flex: 1;\n  justify-content: center;\n  margin-left: ", ";\n  ", ";\n"])), function (props) { return (props.showAddIcon ? "12px" : "0px"); }, desktopStyles(css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-left: 24px;\n  "], ["\n    margin-left: 24px;\n  "])))));
export var AddText = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
export var IconDisclosureWrap = styled(View)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n"], ["\n  align-items: center;\n  justify-content: center;\n"])));
export var IconDisclosure = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 24px;\n  height: 40px;\n"], ["\n  width: 24px;\n  height: 40px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
