import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import ButtonPrimary from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import {
  IConfirm,
  registerConfirmTrigger,
} from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import React from "react";
import * as S from "./ConfirmProvider.styles";

let didInitialize = false;

interface IProps {}

const ConfirmProvider = (props: React.PropsWithChildren<IProps>) => {
  const { children } = props;

  const [confirm, setConfirm] = React.useState<IConfirm | undefined>(undefined);

  // Lifecycle
  React.useEffect(() => {
    if (didInitialize) {
      return;
    }
    didInitialize = true;

    const confirmTrigger = (newConfirm: IConfirm) => {
      setConfirm(newConfirm);
    };
    registerConfirmTrigger(confirmTrigger);
  }, []);

  // Event handlers
  const handleClickDismiss = () => {
    if (!confirm) {
      return;
    }

    confirm.onClose?.();

    setConfirm(undefined);
  };

  const handlePress = (onPress?: () => void) => () => {
    onPress?.();

    setConfirm(undefined);
  };

  // Render
  return (
    <>
      {children}
      {confirm && (
        <S.Wrap>
          <S.InvisibleDismissButton onClick={handleClickDismiss} />
          <S.CardWrap>
            <S.Title>{confirm.title}</S.Title>
            <S.Info>{confirm.info}</S.Info>
            {(confirm.primaryActions?.length || 0) > 0 && (
              <S.PrimaryButtonsWrap>
                {confirm.primaryActions?.map(action => (
                  <ButtonPrimary
                    title={action.title}
                    onPress={handlePress(action.onPress)}
                  />
                ))}
              </S.PrimaryButtonsWrap>
            )}
            {confirm.secondaryAction && (
              <S.SecondaryButtonsWrap>
                <ButtonBasic
                  title={confirm.secondaryAction.title}
                  onPress={handlePress(confirm.secondaryAction.onPress)}
                />
              </S.SecondaryButtonsWrap>
            )}
          </S.CardWrap>
        </S.Wrap>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConfirmProvider;
