import React from "react";
import * as S from "./ProgressBar.styles";

interface IProps {
  steps: string[];
  currentStep: number;
}

const ProgressBar: React.FC<IProps> = (props: IProps) => {
  const { steps, currentStep } = props;

  const numberOfSteps = steps.length;

  return (
    <S.Wrap>
      <S.Line numberOfSteps={numberOfSteps}>
        <S.ActiveLine numberOfSteps={numberOfSteps} currentStep={currentStep} />
      </S.Line>
      {steps.map((step, index) => {
        const isCompleted = index <= currentStep;
        const isCurrent = index === currentStep;
        return (
          <S.StepWrap
            key={step}
            isCompleted={isCompleted}
            numberOfSteps={numberOfSteps}
          >
            <S.StepCircle isCompleted={isCompleted} isCurrent={isCurrent} />
            <S.StepTitle isCompleted={isCompleted} isCurrent={isCurrent}>
              {step}
            </S.StepTitle>
          </S.StepWrap>
        );
      })}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default ProgressBar;
