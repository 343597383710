var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useSelector } from "react-redux";
import { getFieldTitle } from "../../../redux/form/form.utils";
import { htmlTextAsReactText } from "../../../utils/text/text.utils";
import Spacer, { ESpacerWeight } from "../Spacer";
import * as S from "./FormHelpInfo.styles";
import { EHelpInfoDisplayType } from "./FormHelpInfo.styles";
var FormHelpInfo = /** @class */ (function (_super) {
    __extends(FormHelpInfo, _super);
    function FormHelpInfo(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClose = function () {
            _this.setState({
                didClose: true,
            });
        };
        _this.state = { didClose: false };
        return _this;
    }
    FormHelpInfo.prototype.renderExtraInfoButton = function () {
        var _a = this.props, _b = _a.displayType, displayType = _b === void 0 ? EHelpInfoDisplayType.Info : _b, infoButtonTitle = _a.infoButtonTitle, onPressInfoButton = _a.onPressInfoButton;
        if (!infoButtonTitle || !onPressInfoButton) {
            return null;
        }
        return (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(S.HelpButton, { title: infoButtonTitle, onPress: onPressInfoButton, displayType: displayType })] }));
    };
    FormHelpInfo.prototype.render = function () {
        var _a = this.props, _b = _a.displayType, displayType = _b === void 0 ? EHelpInfoDisplayType.Info : _b, icon = _a.icon, title = _a.title, subtitle = _a.subtitle, _c = _a.showCloseButton, showCloseButton = _c === void 0 ? true : _c;
        if (this.state.didClose) {
            return null;
        }
        return (
        // TODO REFACTOR
        _jsx(S.Wrap, { displayType: displayType, children: title || icon ? (_jsxs(S.InnerWrap, { children: [_jsxs(S.HeaderWrap, { children: [icon && _jsx(S.Icon, { source: icon }), title && _jsx(S.Title, { displayType: displayType, children: title }), showCloseButton && (_jsx(S.CloseButtonWrap, { onPress: this.handleClose, children: _jsx(S.CloseButton, {}) }))] }), _jsx(S.Info, { displayType: displayType, children: htmlTextAsReactText(subtitle) }), this.renderExtraInfoButton()] })) : (_jsxs(S.InnerWrapSubtitle, { children: [_jsx(S.Info, { displayType: displayType, children: htmlTextAsReactText(subtitle) }), showCloseButton && (_jsx(S.CloseButtonWrap, { onPress: this.handleClose, children: _jsx(S.CloseButton, {}) })), this.renderExtraInfoButton()] })) }));
    };
    return FormHelpInfo;
}(React.PureComponent));
export var FormHelpInfoJsonFormSchema = function (_a) {
    var uiSchema = _a.uiSchema, formContext = _a.formContext;
    var _b = uiSchema, displayType = _b.displayType, icon = _b.icon, subtitle = _b.subtitle, getSubtitle = _b.getSubtitle, showCloseButton = _b.showCloseButton, infoButtonTitle = _b.infoButtonTitle, onPressInfoButton = _b.onPressInfoButton;
    var customSubtitle = getSubtitle
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useSelector(function (state) {
                return getSubtitle(state, formContext.getAllFormData());
            })
        : subtitle;
    return (_jsx(FormHelpInfo, { displayType: displayType, icon: icon, title: getFieldTitle(uiSchema, formContext), subtitle: customSubtitle || "", showCloseButton: showCloseButton, infoButtonTitle: infoButtonTitle, onPressInfoButton: onPressInfoButton }));
};
// eslint-disable-next-line import/no-default-export
export default FormHelpInfo;
