import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import assets from "assets";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
`;

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.global1};
`;

export const EditIcon = styled.img.attrs({
  src: assets.ICO_EDIT,
})`
  width: 20px;
  height: 20px;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 8px;
`;

export const InfoItemTitle = styled.span`
  font-size: 17px;
  color: ${props => props.theme.colors.global1};
`;

export const InfoItemValue = styled.span<{ asError: boolean }>`
  font-size: 16px;
  font-weight: 500;
  color: ${props =>
    props.asError ? props.theme.colors.errorText : props.theme.colors.global1};
`;
