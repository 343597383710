var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, View } from "react-native";
import styled from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 320px;\n"], ["\n  width: 320px;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n"], ["\n  width: 100%;\n  height: 48px;\n"])));
export var Separator = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var EmptyButtonWrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 16px;\n  margin-right: 16px;\n  margin-top: 8px;\n  margin-bottom: 16px;\n"], ["\n  margin-left: 16px;\n  margin-right: 16px;\n  margin-top: 8px;\n  margin-bottom: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
