import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import rentioTheme from "@rentiohq/shared-frontend/dist/components/theme/theme.rentio_insurance";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import assets from "assets";
import { lighten } from "polished";
import Slider from "rc-slider";
import React from "react";
import { roundToNearest } from "utils/number.utils";
import QuoteOptionBase from "../QuoteOptionBase";
import * as S from "./QuoteOptionContentPremium.styles";

interface IProps {
  quote?: IQuote;
  isCreatingQuote?: boolean;
  onValueChange: (isActive: boolean) => void;
  onAmountOfContentChange: (contentAmount: /* in euro */ number) => void;
}

const QuoteOptionContentPremium: React.FC<IProps> = (props: IProps) => {
  const { quote, isCreatingQuote, onValueChange, onAmountOfContentChange } =
    props;

  // State
  const [didSlide, setDidSlide] = React.useState(false);
  const [amount, setAmount] = React.useState(
    quote?.optionContentPremiumAmountOfContent ||
      CONFIG.INSURANCE_PREMIUMS.CONTENT_DEFAULT_AMOUNT_OF_CONTENT,
  );

  // Lifecycle
  React.useEffect(() => {
    if (!quote) {
      return;
    }

    setAmount(
      quote.optionContentPremiumAmountOfContent ||
        CONFIG.INSURANCE_PREMIUMS.CONTENT_DEFAULT_AMOUNT_OF_CONTENT,
    );
  }, [quote]);

  React.useEffect(() => {
    if (isCreatingQuote) {
      return;
    }

    setDidSlide(false);
  }, [isCreatingQuote]);

  // Event handlers
  const handleRangeChange = (value: number) => {
    const formattedValue = roundToNearest(5_000, value);

    setAmount(formattedValue);
  };

  const handleAfterChange = (value: number) => {
    const formattedValue = roundToNearest(5_000, value);

    setAmount(formattedValue);
    onAmountOfContentChange(formattedValue);

    setDidSlide(true);
  };

  const handlePressLegalInfo = () => {
    confirm({
      title: getLocalizedText(
        "insurance.options.premium.content.legal_info.alert.title",
      ),
      info: getLocalizedText(
        "insurance.options.premium.content.legal_info.alert.info",
      ),
      primaryActions: [
        {
          title: getLocalizedText(
            "insurance.options.premium.content.legal_info.alert.cta.confirm",
          ),
          onPress: () => {},
        },
      ],
      onClose: () => {},
    });
  };

  // Render
  const renderExtraContent = () => {
    if (!quote) {
      return null;
    }

    if (!quote.optionContentPremium || !quote.optionContentPremiumValue) {
      return null;
    }

    const stepSize =
      (CONFIG.INSURANCE_PREMIUMS.CONTENT_MAX_AMOUNT_OF_CONTENT -
        CONFIG.INSURANCE_PREMIUMS.CONTENT_MIN_AMOUNT_OF_CONTENT) /
      4;

    const marksVisual: { [key: number]: string } = {};
    const marksText = [];

    for (let index = 0; index <= 4; index++) {
      const markAmount =
        CONFIG.INSURANCE_PREMIUMS.CONTENT_MIN_AMOUNT_OF_CONTENT +
        stepSize * index;

      marksVisual[markAmount] = "";
      if (index % 2 === 0) {
        marksText.push(markAmount);
      }
    }

    return (
      <S.Wrap>
        <S.TitleWrap>
          <S.Title>
            {getLocalizedText(
              "insurance.options.premium.content.content_amount",
            )}
            <S.Badge>{formatCurrency(amount, false)}</S.Badge>
          </S.Title>
          {didSlide && <S.ActivityIndicatorTitle />}
        </S.TitleWrap>
        <S.DotLabelsWrap>
          {marksText.map(mark => (
            <S.DotLabel>{formatCurrency(mark, false)}</S.DotLabel>
          ))}
        </S.DotLabelsWrap>
        <S.SliderWrap>
          {/*
          // @ts-ignore */}
          <Slider
            value={amount}
            min={CONFIG.INSURANCE_PREMIUMS.CONTENT_MIN_AMOUNT_OF_CONTENT}
            max={CONFIG.INSURANCE_PREMIUMS.CONTENT_MAX_AMOUNT_OF_CONTENT}
            onChange={handleRangeChange}
            onAfterChange={handleAfterChange}
            marks={marksVisual}
            // @ts-ignore
            trackStyle={[{ backgroundColor: rentioTheme.colors.global5 }]}
            railStyle={{
              backgroundColor: lighten(0.2, rentioTheme.colors.global5),
            }}
            activeDotStyle={{
              backgroundColor: rentioTheme.colors.global5,
              borderColor: rentioTheme.colors.global5,
            }}
            dotStyle={{
              backgroundColor: lighten(0.2, rentioTheme.colors.global5),
              borderColor: lighten(0.2, rentioTheme.colors.global5),
            }}
            // @ts-ignore
            handleStyle={[
              {
                backgroundColor: rentioTheme.colors.global5,
                borderColor: rentioTheme.colors.global5,
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                borderWidth: 8,
              },
            ]}
          />
        </S.SliderWrap>
        <S.LegalWrap>
          {amount <= 10_000 && (
            <S.LegalInfo>
              {getLocalizedText("insurance.options.premium.content.legal_info")}
            </S.LegalInfo>
          )}
          <ButtonBasic
            title={getLocalizedText(
              "insurance.options.premium.content.legal_info.cta.help",
            )}
            onPress={handlePressLegalInfo}
          />
        </S.LegalWrap>
      </S.Wrap>
    );
  };

  return (
    <QuoteOptionBase
      quote={quote}
      icon={assets.ICO_HOUSEHOLD_EFFECTS}
      title={getLocalizedText("insurance.options.premium.content.title")}
      info={getLocalizedText("insurance.options.premium.content.info")}
      price={quote?.optionContentPremiumValue}
      isCreatingQuote={isCreatingQuote}
      isActive={quote?.optionContentPremium || false}
      onValueChange={onValueChange}
      renderExtraContent={renderExtraContent}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteOptionContentPremium;
