var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// TODO: Add generator for default API endpoints
export var sanitizeQuery = function (params) {
    var query = params.query;
    var result = __assign({}, query);
    // Clean search query
    if (result.search) {
        var sanitizedSearch = result.search.trim();
        if (sanitizedSearch.length > 0) {
            result.search = sanitizedSearch;
        }
        else {
            delete result.search;
        }
    }
    else {
        delete result.search;
    }
    return result;
};
