export var getContactId = function (_a) {
    var contactId = _a.contactId, contact = _a.contact;
    if (contactId) {
        return contactId;
    }
    if (contact) {
        return contact.id;
    }
};
export var getAccountId = function (_a) {
    var accountId = _a.accountId, account = _a.account;
    if (accountId) {
        return accountId;
    }
    if (account) {
        return account.id;
    }
};
