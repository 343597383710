import * as leadActions from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.actions";
import * as leadSelectors from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.selectors";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IRootState } from "redux/reducers";
import { dateToApiDateWithoutTime } from "utils/date.utils";
import { EField, IExtraData } from "./schema.contactInfo.types";

export const isPerformingSubmitActionSelector =
  (leadId: string) => (state: IRootState) =>
    leadSelectors.isUpdatingLead(state, leadId);

export const performingSubmitActionErrorSelector =
  (leadId: string) => (state: IRootState) =>
    leadSelectors.updateLeadError(state, leadId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { lead, publicId } = extraData;

    const updatedLead: Partial<ILead> = {
      contactFirstname: formData[EField.ContactFirstname],
      contactLastname: formData[EField.ContactLastname],
      contactEmail: formData[EField.ContactEmail],
      contactMobileNumber: formData[EField.ContactMobileNumber],
      contactDateOfBirth: dateToApiDateWithoutTime(
        formData[EField.ContactDateOfBirth],
      ),
      // contactNationalNumber: formData[EField.ContactPassportNumber],
      contactDamageHistory:
        formData[EField.ContactDamagePastYears] === "yes"
          ? formData[EField.ContactDamagePastAmount] || 1
          : 0,
    };

    const locale = getLocale();
    if (locale !== ELocale.INTERNAL) {
      updatedLead.locale = locale;
    }

    return leadActions.updateLead.actions.start({
      leadId: lead.id,
      updatedLead,
      publicId,
    });
  };
