import { compact } from "lodash";
import { getQueryHash } from "../utils/hash.utils";
import { generateUtils } from "./generator.utils";
export var generateSelectors = function (params) {
    var reducerKey = params.reducerKey;
    var utils = generateUtils(params);
    var getEntityState = function (state) {
        var mappedState = state;
        return mappedState[reducerKey];
    };
    var getDetailBase = function (state, id, customPath) {
        var _a;
        var key = utils.getDetailKey({ id: id, customPath: customPath });
        if (!key) {
            return undefined;
        }
        return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail[key];
    };
    var getDetail = function (state, id, customPath) {
        var _a;
        return (_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.entity;
    };
    var isFetchingDetail = function (state, id, customPath) {
        var _a;
        return ((_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var fetchDetailError = function (state, id, customPath) {
        var _a;
        return (_a = getDetailBase(state, id, customPath)) === null || _a === void 0 ? void 0 : _a.error;
    };
    var getPaged = function (state, pagedQuery, customPath) {
        var _a, _b, _c;
        if (!pagedQuery) {
            return;
        }
        var hash = getQueryHash(pagedQuery, customPath);
        var pagedState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.paged[hash];
        if (!pagedState) {
            return;
        }
        var pagedStateForPage = (_b = pagedState === null || pagedState === void 0 ? void 0 : pagedState.pages) === null || _b === void 0 ? void 0 : _b[pagedQuery.page];
        var _d = pagedStateForPage || {}, pending = _d.pending, error = _d.error, data = _d.data;
        var items = (_c = data === null || data === void 0 ? void 0 : data.ids) === null || _c === void 0 ? void 0 : _c.map(function (id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail["".concat(id)]) === null || _b === void 0 ? void 0 : _b.entity; });
        return {
            totalItems: pagedState.totalItems,
            totalPages: pagedState.totalPages,
            isFetching: pending,
            isExhausted: pagedState.isExhausted,
            fetchError: error,
            items: items ? compact(items) : undefined,
        };
    };
    var getAllWhere = function (state, getAllQuery, customPath) {
        var _a;
        if (!getAllQuery && !customPath) {
            return;
        }
        var hash = getQueryHash(getAllQuery, customPath);
        var getAllState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.allWhere[hash];
        var _b = getAllState || {}, pending = _b.pending, error = _b.error, ids = _b.ids, isExhausted = _b.isExhausted;
        var items = ids === null || ids === void 0 ? void 0 : ids.map(function (id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.detail["".concat(id)]) === null || _b === void 0 ? void 0 : _b.entity; });
        return {
            isFetching: pending,
            isExhausted: isExhausted,
            fetchError: error,
            items: items ? compact(items) : undefined,
        };
    };
    var isFetchingAllWhere = function (state, getAllQuery) {
        var _a;
        var hash = getQueryHash(getAllQuery);
        var entityState = getEntityState(state);
        return ((_a = entityState === null || entityState === void 0 ? void 0 : entityState.allWhere[hash]) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var getInfiniteLoad = function (state, infiniteLoadQuery, customPath) {
        var _a, _b;
        if (!infiniteLoadQuery) {
            return;
        }
        var hash = getQueryHash(infiniteLoadQuery, customPath);
        var infiniteLoadState = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.infiniteLoad[hash];
        if (!infiniteLoadState) {
            return;
        }
        var allLoaded = (_b = infiniteLoadState.ids) === null || _b === void 0 ? void 0 : _b.map(function (id) { var _a; return (_a = getDetailBase(state, id)) === null || _a === void 0 ? void 0 : _a.entity; });
        return {
            totalItems: infiniteLoadState.totalItems,
            totalPages: infiniteLoadState.totalPages,
            isFetching: infiniteLoadState.pending,
            isExhausted: infiniteLoadState.isExhausted,
            fetchError: infiniteLoadState.error,
            items: allLoaded ? compact(allLoaded) : undefined,
        };
    };
    var getCountBase = function (state, query, customPath) {
        var _a;
        if (!query) {
            return;
        }
        var key = utils.getCountKey({ query: query, customPath: customPath });
        return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.count[key];
    };
    var getCount = function (state, query, customPath) {
        var _a;
        return (_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.count;
    };
    var isFetchingCount = function (state, query, customPath) {
        var _a;
        return ((_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.pending) || false;
    };
    var fetchCountError = function (state, query, customPath) {
        var _a;
        return (_a = getCountBase(state, query, customPath)) === null || _a === void 0 ? void 0 : _a.error;
    };
    var getCreatedDetail = function (state) { var _a, _b; return (_b = getDetailBase(state, (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.id)) === null || _b === void 0 ? void 0 : _b.entity; };
    var isCreating = function (state) { var _a; return ((_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.pending) || false; };
    var createError = function (state) { var _a; return (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.create.error; };
    var isUpdating = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.update["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var updateError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.update["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    var isRemoving = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.remove["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var removeError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.remove["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    var isArchiving = function (state, id) { var _a, _b; return ((_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.archiving["".concat(id)]) === null || _b === void 0 ? void 0 : _b.pending) || false; };
    var archivingError = function (state, id) { var _a, _b; return (_b = (_a = getEntityState(state)) === null || _a === void 0 ? void 0 : _a.archiving["".concat(id)]) === null || _b === void 0 ? void 0 : _b.error; };
    return {
        getDetail: getDetail,
        isFetchingDetail: isFetchingDetail,
        fetchDetailError: fetchDetailError,
        getPaged: getPaged,
        getInfiniteLoad: getInfiniteLoad,
        getAllWhere: getAllWhere,
        isFetchingAllWhere: isFetchingAllWhere,
        getCount: getCount,
        isFetchingCount: isFetchingCount,
        fetchCountError: fetchCountError,
        getCreatedDetail: getCreatedDetail,
        isCreating: isCreating,
        createError: createError,
        isUpdating: isUpdating,
        updateError: updateError,
        isRemoving: isRemoving,
        removeError: removeError,
        isArchiving: isArchiving,
        archivingError: archivingError,
    };
};
