import ButtonBasic, {
  IButtonBasicProps,
} from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import assets from "assets";
import React from "react";
import * as S from "./ButtonMail.styles";

interface IProps extends IButtonBasicProps {}

const ButtonMail: React.FC<IProps> = (props: IProps) => {
  const { onPress, ...rest } = props;

  // Render
  return (
    <S.Wrap onClick={onPress}>
      <S.Icon src={assets.ICO_MAIL} alt={"Send icon"} />
      <ButtonBasic onPress={onPress} {...rest} />
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default ButtonMail;
