import { removeUndefinedValuesFromObject } from "@rentiohq/shared-frontend/dist/utils/object.utils";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export const appendQueryParams = (params: {
  path: string;
  queryParams: { [key: string]: string | undefined };
}) => {
  const { path, queryParams } = params;

  const queryParamsParsed = removeUndefinedValuesFromObject(queryParams);

  const result = queryString.parseUrl(path);

  Object.keys(queryParamsParsed).forEach(queryParamKey => {
    result.query[queryParamKey] = queryParamsParsed[queryParamKey];
  });

  return queryString.stringifyUrl(result);
};

export const useInsuranceQueryParams = () => {
  let location = useLocation();

  const { leadId, quoteId, publicId, internalMode } = queryString.parse(
    location.search,
  ) as {
    leadId?: string;
    quoteId?: string;
    publicId?: string;
    internalMode?: string;
  };

  return {
    leadId,
    quoteId,
    publicId,
    internalMode: internalMode !== undefined,
  };
};
