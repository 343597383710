var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, View } from "react-native";
import images from "../../assets";
import styled from "../../theme/styled-components.native";
export var SelectIconWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n"], ["\n  align-items: center;\n  justify-content: center;\n"])));
export var CheckIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_CHECK_WHITE,
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n"], ["\n  width: 10px;\n  height: 10px;\n"])));
export var SelectIcon = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  border-width: 1px;\n  border-color: ", ";\n  background-color: ", ";\n"], ["\n  width: 20px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  border-width: 1px;\n  border-color: ", ";\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global5; }, function (props) {
    return props.selected ? props.theme.colors.global5 : props.theme.colors.neutral2;
});
var templateObject_1, templateObject_2, templateObject_3;
