import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import React from "react";
import { useLocation } from "react-router-dom";

interface IProps {}

const ScrollToTop: React.FC<IProps> = (_: IProps) => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  React.useEffect(() => {
    if (prevPathname === pathname) {
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// eslint-disable-next-line import/no-default-export
export default ScrollToTop;
