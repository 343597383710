var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from "polished";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Separator = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var CardsWrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex-direction: row;\n  flex-wrap: wrap;\n  margin-right: -8px;\n"], ["\n  flex-direction: row;\n  flex-wrap: wrap;\n  margin-right: -8px;\n"])));
export var CardOuterWrap = styled(View)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 33.3%;\n"], ["\n  width: 33.3%;\n"])));
export var CardInnerWrap = styled(TouchableOpacity)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  margin-right: 8px;\n  margin-bottom: 8px;\n  width: auto;\n  height: 152px;\n  align-items: center;\n  justify-content: space-evenly;\n  border: 2px solid\n    ", ";\n  border-radius: 4px;\n  background-color: ", ";\n"], ["\n  position: relative;\n  margin-right: 8px;\n  margin-bottom: 8px;\n  width: auto;\n  height: 152px;\n  align-items: center;\n  justify-content: space-evenly;\n  border: 2px solid\n    ", ";\n  border-radius: 4px;\n  background-color: ", ";\n"])), function (props) {
    return props.selected ? props.theme.colors.global5 : props.theme.colors.global3;
}, function (props) {
    return props.selected
        ? transparentize(0.95, props.theme.colors.global5)
        : "transparent";
});
export var CardIcon = styled(Image).attrs(function (props) {
    return {
        tintColor: props.selected
            ? props.theme.colors.global5
            : props.theme.colors.global2,
    };
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 64px;\n  height: 64px;\n"], ["\n  width: 64px;\n  height: 64px;\n"])));
export var CardTitle = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: bold;\n  text-transform: uppercase;\n  text-align: center;\n  color: 2px solid\n    ", ";\n"], ["\n  font-size: 12px;\n  font-weight: bold;\n  text-transform: uppercase;\n  text-align: center;\n  color: 2px solid\n    ", ";\n"])), function (props) {
    return props.selected ? props.theme.colors.global5 : props.theme.colors.global2;
});
export var CheckIcon = styled(Image)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  height: 20px;\n  width: 20px;\n"], ["\n  position: absolute;\n  top: 8px;\n  right: 8px;\n  height: 20px;\n  width: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
