import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { transparentize } from "polished";

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(3px);
  background-color: ${transparentize(0.7, "black")};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const InvisibleDismissButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const CardWrap = styled.div`
  padding: 24px;
  background-color: ${props => props.theme.colors.neutral2};
  border-radius: 3px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
  margin: 16px;
  width: 480px;
  max-width: 90%;
`;

export const Title = styled.span`
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: ${16 * 1.5}px;
  color: ${props => props.theme.colors.neutral1};
`;

export const Info = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 16px;
  line-height: ${16 * 1.3}px;
  color: ${props => props.theme.colors.global1};
`;

export const PrimaryButtonsWrap = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    justify-content: center;
  }
`;

export const SecondaryButtonsWrap = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    justify-content: center;
  }
`;
