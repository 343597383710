var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import * as authUtils from "../../redux/auth/auth.utils";
// import interceptorResponseLogger from "./interceptors/interceptor.response.logger";
import { CONFIG } from "../../config/app.config";
import { EProduct } from "../../config/app.config.types";
import interceptorResponseRefreshAccessToken from "./interceptors/interceptor.response.refreshAccessToken";
var cancel;
var generateCancelToken = function () {
    cancel = undefined;
    return new axios.CancelToken(function executor(c) {
        cancel = c;
    });
};
var cancelToken = generateCancelToken();
export var cancelPendingCalls = function () {
    // eslint-disable-next-line no-console
    console.log("cancelPendingCalls", { cancel: cancel });
    // if (cancel) {
    //   // Cancel all calls
    //   cancel();
    //   // Generate new cancel token
    //   cancelToken = generateCancelToken();
    // }
};
// Config
var timeout = 40000;
var timeoutServerless = 40000;
var getBaseUrl = function () {
    switch (CONFIG.PRODUCT) {
        case EProduct.Insurance:
        case EProduct.InsuranceBackoffice:
            return CONFIG.BASE_URL_INSURANCE;
        default:
            return CONFIG.BASE_URL;
    }
};
var getBaseUrlV2 = function () {
    switch (CONFIG.PRODUCT) {
        case EProduct.Insurance:
        case EProduct.InsuranceBackoffice:
            return undefined;
        default:
            return CONFIG.BASE_URL_V2;
    }
};
var getBaseUrlServerless = function () {
    switch (CONFIG.PRODUCT) {
        case EProduct.Insurance:
        case EProduct.InsuranceBackoffice:
            return undefined;
        default:
            return CONFIG.BASE_URL_SERVERLESS;
    }
};
var apiRegular = axios.create({
    baseURL: getBaseUrl(),
    timeout: timeout,
});
var apiRegularV2 = axios.create({
    baseURL: getBaseUrlV2(),
    timeout: timeout,
});
var apiServerless = axios.create({
    baseURL: getBaseUrlServerless(),
    timeout: timeoutServerless,
});
// Logging disabled due to spam in Sentry
// apiRegular.interceptors.response.use(
//   interceptorResponseLogger.success,
//   interceptorResponseLogger.error,
// );
apiRegular.interceptors.response.use(interceptorResponseRefreshAccessToken.success, interceptorResponseRefreshAccessToken.error);
apiRegularV2.interceptors.response.use(interceptorResponseRefreshAccessToken.success, interceptorResponseRefreshAccessToken.error);
apiServerless.interceptors.response.use(interceptorResponseRefreshAccessToken.success, interceptorResponseRefreshAccessToken.error);
// Helpers
export var productToSourceHeader = function () {
    switch (CONFIG.PRODUCT) {
        case EProduct.PasswordReset:
            return EProduct.Dashboard.toLowerCase();
        default:
            return CONFIG.PRODUCT.toLowerCase();
    }
};
var getApi = function (config) {
    var _a = config.isServerless, isServerless = _a === void 0 ? false : _a, _b = config.isV2, isV2 = _b === void 0 ? false : _b, _c = config.isFullUrl, isFullUrl = _c === void 0 ? false : _c;
    if (isFullUrl) {
        return axios;
    }
    if (isV2) {
        return apiRegularV2;
    }
    if (isServerless) {
        return apiServerless;
    }
    return apiRegular;
};
var getHeaders = function (config) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, isV2, _b, isServerless, _c, isRentioUrl, _d, authenticated, _e, headers, accessToken;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = config.isV2, isV2 = _a === void 0 ? false : _a, _b = config.isServerless, isServerless = _b === void 0 ? false : _b, _c = config.isRentioUrl, isRentioUrl = _c === void 0 ? true : _c, _d = config.authenticated, authenticated = _d === void 0 ? true : _d, _e = config.headers, headers = _e === void 0 ? {} : _e;
                headers["content-type"] = "application/json";
                if (isRentioUrl) {
                    headers["x-source"] = productToSourceHeader();
                }
                return [4 /*yield*/, authUtils.getAccessToken()];
            case 1:
                accessToken = _f.sent();
                if (isRentioUrl && authenticated && accessToken) {
                    if (isV2 ||
                        isServerless ||
                        CONFIG.PRODUCT === EProduct.InsuranceBackoffice) {
                        headers["Authorization"] = "Bearer ".concat(accessToken);
                    }
                    else {
                        headers["Authorization"] = accessToken;
                    }
                }
                return [2 /*return*/, headers];
        }
    });
}); };
// API
// eslint-disable-next-line import/no-default-export
export default {
    get: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_1, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).get(path, {
                                cancelToken: cancelToken,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_1 = _a.sent();
                        error = unknownError_1;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
    put: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params, data = config.data;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_2, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).put(path, data, {
                                cancelToken: cancelToken,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_2 = _a.sent();
                        error = unknownError_2;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
    post: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params, data = config.data;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_3, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).post(path, data, {
                                cancelToken: cancelToken,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_3 = _a.sent();
                        error = unknownError_3;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
    patch: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params, data = config.data;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_4, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).patch(path, data, {
                                cancelToken: cancelToken,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_4 = _a.sent();
                        error = unknownError_4;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
    delete: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params, data = config.data;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_5, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).delete(path, {
                                cancelToken: cancelToken,
                                data: data,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_5 = _a.sent();
                        error = unknownError_5;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
    head: function (path, config) {
        if (config === void 0) { config = {}; }
        var params = config.params, data = config.data;
        return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
            var headers, result, unknownError_6, error;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, getHeaders(config)];
                    case 1:
                        headers = _a.sent();
                        return [4 /*yield*/, getApi(config).head(path, {
                                cancelToken: cancelToken,
                                data: data,
                                params: params,
                                headers: headers,
                                timeout: config.customTimeout,
                            })];
                    case 2:
                        result = _a.sent();
                        resolve(result);
                        return [3 /*break*/, 4];
                    case 3:
                        unknownError_6 = _a.sent();
                        error = unknownError_6;
                        reject(error);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    },
};
