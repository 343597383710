var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { formatDate } from "../../../utils/date.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import FormInputBase from "../FormInputBase";
import * as S from "./FormInputDate.styles";
export var DatePickerContext = React.createContext({
    showPicker: function (onSubmit, config) { },
});
var FormInputDate = /** @class */ (function (_super) {
    __extends(FormInputDate, _super);
    function FormInputDate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    FormInputDate.prototype.render = function () {
        var _this = this;
        var _a = this.props, value = _a.value, title = _a.title, error = _a.error, info = _a.info, optional = _a.optional, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, minDate = _a.minDate, minDateError = _a.minDateError, maxDate = _a.maxDate, maxDateError = _a.maxDateError, showMonthYearPicker = _a.showMonthYearPicker, showTimePicker = _a.showTimePicker, inverseYears = _a.inverseYears, disabled = _a.disabled;
        var clearValue = function () {
            _this.props.onChangeValue(undefined);
        };
        return (_jsx(FormInputBase, { title: title, info: info, error: error, optional: optional, disabled: disabled, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, children: _jsx(DatePickerContext.Consumer, { children: function (_a) {
                    var showPicker = _a.showPicker;
                    return (_jsx(S.InfoWrap, { onPress: function () {
                            setTimeout(function () {
                                showPicker(_this.props.onChangeValue, {
                                    formInputBaseProps: {
                                        title: title,
                                        info: info,
                                        optional: optional,
                                        extraInfoUrl: extraInfoUrl,
                                    },
                                    initialDate: value,
                                    minDate: minDate,
                                    minDateError: minDateError,
                                    maxDate: maxDate,
                                    maxDateError: maxDateError,
                                    showMonthYearPicker: showMonthYearPicker,
                                    showTimePicker: showTimePicker,
                                    inverseYears: inverseYears,
                                });
                            }, 0);
                        }, children: value ? (_jsxs(_Fragment, { children: [_jsx(S.DateText, { children: formatDate(value, _this.props.dateFormat) }), _jsx(S.ClearButton, { onPress: clearValue, children: _jsx(S.ClearIcon, {}) })] })) : (_jsx(S.Placeholder, { children: getLocalizedText("date_picker.select_date") })) }));
                } }) }));
    };
    return FormInputDate;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputDate;
