var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, TouchableOpacity } from "react-native";
import styled from "../../theme/styled-components.native";
import Badge from "../Badge";
export var Wrap = styled(TouchableOpacity)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n"], ["\n  width: 100%;\n  height: 48px;\n"])));
export var BadgeStyled = styled(Badge)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 4px;\n  margin-top: 3px;\n"], ["\n  margin-left: 4px;\n  margin-top: 3px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
