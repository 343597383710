import { REDUCER_KEY_AGREEMENT } from "./agreement.reducer";
export var getAgreement = function (state, agreementId) {
    return state.agreement.agreements[agreementId];
};
export var isFetchingAgreement = function (state, agreementId) { var _a; return ((_a = state.agreement.agreementsById[agreementId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchAgreementError = function (state, agreementId) { var _a; return (_a = state.agreement.agreementsById[agreementId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getActiveAgreementByQuoteId = function (state, quoteId) {
    var _a;
    var agreementId = (_a = state.agreement.agreementsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.activeAgreementId;
    if (!agreementId) {
        return;
    }
    return state.agreement.agreements[agreementId];
};
export var isCreatingAgreement = function (state, quoteId) { var _a; return ((_a = state.agreement.agreementsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.isCreating) || false; };
export var createAgreementError = function (state, quoteId) { var _a; return (_a = state.agreement.agreementsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.createError; };
export var isMailingAgreement = function (state, agreementId) { var _a; return ((_a = state.agreement.agreementsById[agreementId]) === null || _a === void 0 ? void 0 : _a.isMailing) || false; };
export var mailAgreementError = function (state, agreementId) { var _a; return (_a = state.agreement.agreementsById[agreementId]) === null || _a === void 0 ? void 0 : _a.mailError; };
