var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getFieldInfo, getFieldTitle } from "../../../../redux/form/form.utils";
import { getLocalizedText, getRawLocale, } from "../../../../utils/i18n/i18n.utils";
import FormInputText from "../FormInputText";
var FormInputNumberJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormInputNumberJsonFormSchema, _super);
    function FormInputNumberJsonFormSchema(props) {
        var _this = _super.call(this, props) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        // Parse
        _this.parse = function (input) {
            var uiSchema = _this.props.uiSchema;
            var customUiSchema = uiSchema;
            var isFloat = customUiSchema.isFloat;
            if (isFloat) {
                return _this.parseFloat(input || "");
            }
            return parseInt(input || "");
        };
        _this.getDecimalSeparator = function () {
            var locale = getRawLocale();
            if (locale.toLowerCase().includes("en") ||
                locale.toLowerCase().includes("gb")) {
                return ".";
            }
            return ",";
        };
        _this.parseFloat = function (input) {
            var result = "".concat(input);
            var decimalSeparator = _this.getDecimalSeparator();
            var hasDot = result.includes(".");
            var hasComma = result.includes(",");
            if (hasDot && hasComma) {
                result = result.replace(decimalSeparator, "x");
                result = result.replace(/\./g, " ");
                result = result.replace(/,/g, "");
                result = result.replace(/x/g, ".");
            }
            else {
                result = result.replace(/,/g, ".");
            }
            return parseFloat(result);
        };
        _this.printNumberSimple = function (input) {
            var result = "".concat(input);
            var decimalSeparator = _this.getDecimalSeparator();
            result = result.replace(/\./g, decimalSeparator);
            result = result.replace(/,/g, decimalSeparator);
            return result;
        };
        var formData = props.formData;
        _this.state = {
            value: formData ? "".concat(_this.printNumberSimple(formData)) : undefined,
        };
        return _this;
    }
    // Lifecycle methods
    FormInputNumberJsonFormSchema.prototype.UNSAFE_componentWillReceiveProps = function (_a) {
        var formData = _a.formData;
        // INFO: Code below is added to support handleChange in schemas
        var prevFormData = this.props.formData;
        if (!formData) {
            this.setState({
                value: undefined,
            });
            return;
        }
        if (!formData && !prevFormData) {
            return;
        }
        var isSame = this.parse(formData) === this.parse(this.state.value);
        if (!isSame) {
            this.setState({
                value: this.printNumberSimple(formData),
            });
        }
    };
    // Render
    FormInputNumberJsonFormSchema.prototype.render = function () {
        var _this = this;
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        var isDisabled = customUiSchema.isDisabled, placeholder = customUiSchema.placeholder, suffixText = customUiSchema.suffixText, isFloat = customUiSchema.isFloat, _d = customUiSchema.submitOnBlur, submitOnBlur = _d === void 0 ? false : _d;
        var allFormData = formContext.getAllFormData();
        var handleChangeValue = function (input) {
            var formattedInput = input.length > 0 ? _this.parse(input) : undefined;
            if (formattedInput) {
                _this.setState({
                    value: isFloat ? input : "".concat(formattedInput),
                });
            }
            else {
                _this.setState({
                    value: undefined,
                });
            }
            if (submitOnBlur) {
                return;
            }
            onChange(formattedInput);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, formattedInput);
                }
            }, 0);
        };
        var handleBlur = function (e) {
            if (!submitOnBlur) {
                return;
            }
            var input = e.nativeEvent.text || "";
            var formattedInput = input.length > 0 ? _this.parse(input) : undefined;
            onChange(formattedInput);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, formattedInput);
                }
            }, 0);
        };
        return (_jsx(FormInputText, __assign({}, customUiSchema, { onPressExtraInfo: this.handlePressExtraInfo, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), placeholder: placeholder, suffixText: suffixText, value: this.state.value, onChangeValue: handleChangeValue, error: rawErrors[0], disabled: isDisabled ? isDisabled(allFormData) : false, textInputProps: {
                onBlur: handleBlur,
                keyboardType: "numeric",
            } })));
    };
    // Validate
    FormInputNumberJsonFormSchema.validate = function (formData, errors, schemaGenerator, uiSchema, mergedPartialFormData) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:number") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            var schema = schemaGenerator({});
            var fieldSchema = schema.properties[fieldName];
            // @ts-ignore
            if (fieldSchema.getMinimum) {
                // @ts-ignore
                var minimum = fieldSchema.getMinimum(__assign(__assign({}, (mergedPartialFormData || {})), (formData || {})));
                if (minimum > data) {
                    fieldError.addError(getLocalizedText("system.form.error.number.min", {
                        value: "".concat(minimum),
                    }));
                }
            }
            // @ts-ignore
            if (fieldSchema.getMaximum) {
                // @ts-ignore
                var maximum = fieldSchema.getMaximum(__assign(__assign({}, (mergedPartialFormData || {})), (formData || {})));
                if (maximum && maximum < data) {
                    fieldError.addError(getLocalizedText("system.form.error.number.max", {
                        value: "".concat(maximum),
                    }));
                }
            }
        });
    };
    return FormInputNumberJsonFormSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputNumberJsonFormSchema;
