import { connect } from "react-redux";
import FormInlineSelect from "./FormInlineSelect";
var mapStateToProps = function (state, props) { return ({
    extraOptions: props.customExtraOptionsSelector
        ? props.customExtraOptionsSelector(state, props.formData)
        : [],
    isFetching: props.customIsFetchingSelector
        ? props.customIsFetchingSelector(state, props.formData)
        : false,
}); };
var mapDispatchToProps = function (dispatch, props) { return ({
    getOptions: function (formData) {
        (props.customGetOptionsActions || []).forEach(function (action) {
            dispatch(action(formData));
        });
    },
}); };
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(FormInlineSelect);
