import { useActiveAgreement } from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.hooks";
import { useActivePayment } from "@rentiohq/shared-frontend/dist/reduxInsurance/payment/payment.hooks";
import {
  EQuoteStatus,
  IQuote,
} from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import { appendQueryParams } from "utils/url.utils";

export const useNextStepNavigation = (params: {
  leadId?: string;
  quote?: IQuote;
  publicId?: string;
}) => {
  const { leadId, quote, publicId } = params;

  // Router
  const navigate = useNavigate();

  // State
  const [enableAutoNavigate, setEnableAutoNavigate] = React.useState(false);

  // Custom hooks
  const {
    activeAgreement,
    isCreating: isCreatingAgreement,
    isFetching: isFetchingAgreement,
    createAgreement,
  } = useActiveAgreement({
    leadId,
    quote,
    publicId,
  });
  const previousActiveAgreement = usePrevious(activeAgreement);

  const {
    activePayment,
    isCreating: isCreatingPayment,
    isFetching: isFetchingPayment,
    createPayment,
  } = useActivePayment({
    leadId,
    quote,
    publicId,
  });
  const previousActivePayment = usePrevious(activePayment);

  const isPending =
    isCreatingAgreement ||
    isFetchingAgreement ||
    isCreatingPayment ||
    isFetchingPayment;

  // Actions
  const navigateToNextStep = () => {
    if (!leadId || !quote || !publicId) {
      return;
    }

    setEnableAutoNavigate(true);

    switch (quote.status) {
      case EQuoteStatus.New: {
        if (!activeAgreement) {
          createAgreement();
        }
        return;
      }

      case EQuoteStatus.Signed: {
        if (!activePayment) {
          createPayment();
        }
        return;
      }

      case EQuoteStatus.Paid: {
        navigate(
          appendQueryParams({
            path: "/request/success",
            queryParams: { leadId, quoteId: quote.id, publicId },
          }),
        );
        return;
      }

      case EQuoteStatus.Signing: {
        navigate(
          appendQueryParams({
            path: "/request/signature",
            queryParams: { leadId, quoteId: quote.id, publicId },
          }),
        );
        return;
      }

      case EQuoteStatus.Paying: {
        navigate(
          appendQueryParams({
            path: "/request/payment",
            queryParams: { leadId, quoteId: quote.id, publicId },
          }),
        );
        return;
      }

      default: {
        navigate(
          appendQueryParams({
            path: "/request/pending",
            queryParams: { leadId, quoteId: quote.id, publicId },
          }),
        );
        return;
      }
    }
  };

  // Lifecycle
  React.useEffect(() => {
    if (!enableAutoNavigate) {
      return;
    }

    // Retry navigation when active agreement is created
    if (activeAgreement && !previousActiveAgreement) {
      navigateToNextStep();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAgreement, previousActiveAgreement]);

  React.useEffect(() => {
    if (!enableAutoNavigate) {
      return;
    }

    // Retry navigation when active payment is created
    if (activePayment && !previousActivePayment) {
      navigateToNextStep();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePayment, previousActivePayment]);

  return { navigateToNextStep, isPending };
};
