var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Platform, SafeAreaView, Text, View } from "react-native";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: ", ";\n"], ["\n  width: 100%;\n  height: ", ";\n"])), Platform.OS === "web" ? "100vh" : "100%");
export var Wrap = styled(SafeAreaView)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.background; });
export var InnerWrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 400px;\n  padding: 24px;\n  align-items: center;\n  ", "\n"], ["\n  max-width: 400px;\n  padding: 24px;\n  align-items: center;\n  ", "\n"])), Platform.select({
    web: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      max-width: 480px;\n    "], ["\n      max-width: 480px;\n    "]))),
}));
var icon = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 96px;\n  height: 96px;\n"], ["\n  width: 96px;\n  height: 96px;\n"])));
export var SuccessIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_STATE_SUCCESS,
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), icon);
export var ErrorIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_STATE_ERROR,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), icon);
export var TextWrap = styled(View)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin: 24px;\n"], ["\n  margin: 24px;\n"])));
export var Title = styled(Text)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 24px;\n  font-weight: bold;\n  color: ", ";\n"], ["\n  text-align: center;\n  font-size: 24px;\n  font-weight: bold;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
export var Subtitle = styled(Text)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 8px;\n  font-size: 16px;\n  color: ", ";\n"], ["\n  text-align: center;\n  margin-top: 8px;\n  font-size: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
