import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as leadActions from "./lead.actions";
import * as leadSelectors from "./lead.selectors";
export var useGetLead = function (params) {
    var leadId = params.leadId, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var lead = useSelector(function (state) {
        return leadSelectors.getLead(state, leadId || "-1");
    });
    var isFetching = useSelector(function (state) {
        return leadSelectors.isFetchingLead(state, leadId || "-1");
    });
    var fetchError = useSelector(function (state) {
        return leadSelectors.fetchLeadError(state, leadId || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (isFetching) {
            return;
        }
        if (!leadId) {
            return;
        }
        dispatch(leadActions.getLead.actions.start({
            leadId: leadId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId]);
    // Helpers
    // const retry = () => {
    //   dispatch(
    //     quoteActions.getQuote.actions.start({
    //       leadId,
    //       quoteId,
    //       publicId,
    //     }),
    //   );
    // };
    var storeUpdatedLead = function (updatedLead) {
        if (!leadId) {
            return;
        }
        dispatch(leadActions.getLead.actions.success({
            leadId: leadId,
            lead: updatedLead,
        }));
    };
    return { lead: lead, isFetching: isFetching, fetchError: fetchError, storeUpdatedLead: storeUpdatedLead };
};
