import {
  IAlert,
  registerAlertTrigger,
} from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";

let didInitialize = false;

interface IProps {
  children?: React.ReactNode;
}

const UtilAlertProvider = (props: React.PropsWithChildren<IProps>) => {
  const { children } = props;

  const alert = useAlert();

  // Lifecycle
  useEffect(() => {
    if (didInitialize) {
      return;
    }
    didInitialize = true;

    const alertTrigger = ({ message = "", error, type, onClose }: IAlert) => {
      alert.show(error ? getMessageForError(error) : message, {
        type,
        onClose,
      });
    };
    registerAlertTrigger(alertTrigger);
  }, [alert]);

  // Render
  return <>{children}</>;
};

// eslint-disable-next-line import/no-default-export
export default UtilAlertProvider;
