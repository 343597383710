import ButtonPrimary, {
  EButtonSize,
} from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const ShowOnlyOnMobile = styled.div`
  display: none;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    display: inherit;
  }
`;

export const ShowOnlyOnDesktop = styled.div`
  display: inherit;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    display: none;
  }
`;

export const SubmitButton = styled(ButtonPrimary).attrs(() => ({
  buttonSize: EButtonSize.ExtraLarge,
}))`
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
  }
`;
