import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { lighten } from "polished";

export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Line = styled.div<{ numberOfSteps: number }>`
  position: absolute;
  top: 10px;
  left: ${props => 100 / props.numberOfSteps / 2}%;
  right: ${props => 100 / props.numberOfSteps / 2}%;
  height: 3px;
  background-color: ${props => props.theme.colors.global3};
`;

export const ActiveLine = styled.div<{
  numberOfSteps: number;
  currentStep: number;
}>`
  width: ${props => {
    const widthPercentage =
      (100 / (props.numberOfSteps - 1)) * props.currentStep;

    return Math.max(0, Math.min(100, widthPercentage));
  }}%;
  height: 100%;
  background-color: ${props => props.theme.colors.global5};
`;

export const StepWrap = styled.div<{
  isCompleted: boolean;
  numberOfSteps: number;
}>`
  width: ${props => 100 / props.numberOfSteps}%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
`;

export const StepCircle = styled.div<{
  isCompleted: boolean;
  isCurrent: boolean;
}>`
  width: 12px;
  height: 12px;
  margin: ${props => (props.isCurrent ? "0px" : "6px")};
  background-color: ${props =>
    props.isCompleted
      ? props.theme.colors.global5
      : props.theme.colors.global3};
  border: ${props => (props.isCurrent ? "6px" : "0px")} solid
    ${props =>
      props.isCompleted
        ? lighten(0.45, props.theme.colors.global5)
        : "transparent"};
  border-radius: 100px;
  box-shadow: ${props =>
    props.isCurrent ? "0px 3px 5px rgba(0, 0, 0, 0.4)" : "inherit"};
`;

export const StepTitle = styled.span<{
  isCompleted: boolean;
  isCurrent: boolean;
}>`
  margin-top: 8px;
  max-width: 110%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: ${props => {
    if (props.isCurrent) {
      return props.theme.colors.global5;
    }

    if (props.isCompleted) {
      return lighten(0.15, props.theme.colors.global5);
    }

    return props.theme.colors.global2;
  }};
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    display: none;
  }
`;
