import * as Sentry from "@sentry/browser";
import { EValueType } from "../logger.types";
var logger = {
    key: "sentry",
    setValue: function (_a) {
        var type = _a.type, value = _a.value;
        var mappedType = type;
        switch (type) {
            case EValueType.UserId:
                mappedType = "id";
                break;
            case EValueType.UserEmail:
                mappedType = "email";
                break;
            case EValueType.Locale:
            default:
                break;
        }
        Sentry.configureScope(function (scope) {
            var _a;
            scope.setUser((_a = {}, _a[mappedType] = "".concat(value), _a));
        });
    },
    logEvent: function (_a) {
        var type = _a.type, name = _a.name, _b = _a.parameters, parameters = _b === void 0 ? {} : _b;
        Sentry.addBreadcrumb({
            message: name,
            category: type,
            data: parameters,
        });
    },
    logError: function (_a) {
        var error = _a.error, fatal = _a.fatal, _b = _a.parameters, parameters = _b === void 0 ? {} : _b;
        var axiosError = error;
        if (axiosError.isAxiosError) {
            // INFO: Do not track Axios network errors. Axios errors are tracked in interceptor.response.logger
            return;
        }
        var parametersAsExtra = {
            fatal: "".concat(fatal || false),
        };
        Object.keys(parameters).forEach(function (parameterKey) {
            var parameterValue = parameters[parameterKey];
            if (typeof parameterValue === "number") {
                parametersAsExtra[parameterKey] = "".concat(parameterValue);
                return;
            }
            parametersAsExtra[parameterKey] = parameterValue;
        });
        Sentry.captureException(error, {
            extra: parametersAsExtra,
        });
    },
};
// eslint-disable-next-line import/no-default-export
export default logger;
