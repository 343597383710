var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getMessageForError } from "../../../utils/error.utils";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import ButtonPrimary from "../ButtonPrimary";
import * as S from "./FullScreenState.styles";
export var EStateType;
(function (EStateType) {
    EStateType["Success"] = "SUCCESS";
    EStateType["Error"] = "ERROR";
})(EStateType || (EStateType = {}));
var FullScreenState = /** @class */ (function (_super) {
    __extends(FullScreenState, _super);
    function FullScreenState() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FullScreenState.prototype.render = function () {
        var _a = this.props, stateType = _a.stateType, title = _a.title, subtitle = _a.subtitle, actionTitle = _a.actionTitle, onPressAction = _a.onPressAction, _b = _a.showIcon, showIcon = _b === void 0 ? true : _b;
        var isError = stateType === EStateType.Error;
        return (_jsx(S.Wrap, { children: _jsxs(S.InnerWrap, { children: [showIcon && isError && _jsx(S.ErrorIcon, {}), showIcon && !isError && _jsx(S.SuccessIcon, {}), _jsxs(S.TextWrap, { children: [_jsx(S.Title, { children: title ||
                                    getLocalizedText(isError ? "general.error" : "general.success") }), subtitle && _jsx(S.Subtitle, { children: subtitle })] }), onPressAction && (_jsx(ButtonPrimary, { title: actionTitle ||
                            getLocalizedText(isError ? "try.again" : "system.ok"), onPress: onPressAction }))] }) }));
    };
    return FullScreenState;
}(React.PureComponent));
var FullScreenErrorState = /** @class */ (function (_super) {
    __extends(FullScreenErrorState, _super);
    function FullScreenErrorState() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () {
            var error = _this.props.error;
            var messageForError = error ? getMessageForError(error) : undefined;
            return (_jsx(FullScreenState, __assign({}, _this.props, { title: messageForError || _this.props.title, stateType: EStateType.Error })));
        };
        return _this;
    }
    return FullScreenErrorState;
}(React.PureComponent));
export { FullScreenErrorState };
var FullScreenSuccessState = /** @class */ (function (_super) {
    __extends(FullScreenSuccessState, _super);
    function FullScreenSuccessState() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.render = function () { return (_jsx(FullScreenState, __assign({}, _this.props, { stateType: EStateType.Success }))); };
        return _this;
    }
    return FullScreenSuccessState;
}(React.PureComponent));
export { FullScreenSuccessState };
// eslint-disable-next-line import/no-default-export
export default FullScreenState;
