var loggers = {};
// Register/deregister logger
export var registerLogger = function (logger) {
    console.log("logger - Register logger with key: ", logger.key);
    loggers[logger.key] = logger;
};
export var deregisterLogger = function (key) {
    console.log("logger - Deregister logger for key: ", key);
    delete loggers[key];
};
// Log to registered loggers
export var setValue = function (payload) {
    Object.values(loggers).forEach(function (logger) { return logger.setValue(payload); });
};
export var logEvent = function (payload) {
    Object.values(loggers).forEach(function (logger) { return logger.logEvent(payload); });
};
export var logError = function (payload) {
    Object.values(loggers).forEach(function (logger) { return logger.logError(payload); });
};
