var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import * as leadActions from "./lead.actions";
export var REDUCER_KEY_LEAD = "lead";
var getDefaultState = function () { return ({
    leads: {},
    leadsById: {},
    pagedLeads: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(leadActions.getLead.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId;
    return (__assign(__assign({}, state), { leadsById: __assign(__assign({}, state.leadsById), (_b = {}, _b[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(leadActions.getLead.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var leadId = _a.leadId, lead = _a.lead;
    return (__assign(__assign({}, state), { leads: __assign(__assign({}, state.leads), (_b = {}, _b[leadId] = lead, _b)), leadsById: __assign(__assign({}, state.leadsById), (_c = {}, _c[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isFetching: false, fetchError: undefined }), _c)) }));
});
reducer.on(leadActions.getLead.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, error = _a.error;
    return (__assign(__assign({}, state), { leadsById: __assign(__assign({}, state.leadsById), (_b = {}, _b[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isFetching: false, fetchError: error }), _b)) }));
});
reducer.on(leadActions.updateLead.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId;
    return (__assign(__assign({}, state), { leadsById: __assign(__assign({}, state.leadsById), (_b = {}, _b[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isUpdating: true, updateError: undefined }), _b)) }));
});
reducer.on(leadActions.updateLead.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var leadId = _a.leadId, lead = _a.lead;
    return (__assign(__assign({}, state), { leads: __assign(__assign({}, state.leads), (_b = {}, _b[leadId] = lead, _b)), leadsById: __assign(__assign({}, state.leadsById), (_c = {}, _c[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isUpdating: false, updateError: undefined }), _c)) }));
});
reducer.on(leadActions.updateLead.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, error = _a.error;
    return (__assign(__assign({}, state), { leadsById: __assign(__assign({}, state.leadsById), (_b = {}, _b[leadId] = __assign(__assign({}, state.leadsById[leadId]), { isUpdating: false, updateError: error }), _b)) }));
});
reducer.on(leadActions.getLeadsPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 1 : _d, filterData = _a.filterData;
    return merge({}, state, {
        pagedLeads: (_b = {},
            _b[identifier] = {
                filterData: filterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(leadActions.getLeadsPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 1 : _d, leads = _a.leads, count = _a.count;
    return merge({}, state, {
        leads: __assign({}, keyBy(leads, "id")),
        pagedLeads: (_b = {},
            _b[identifier] = {
                count: count,
                pages: (_c = {},
                    _c[page] = {
                        leadIds: leads.map(function (lead) { return lead.id; }),
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(leadActions.getLeadsPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, _d = _a.page, page = _d === void 0 ? 1 : _d, error = _a.error;
    return merge({}, state, {
        pagedLeads: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
