import React from "react";
import { Navigate, useParams } from "react-router-dom";

interface IProps {}

const ConnectiveRedirect: React.FC<IProps> = (_: IProps) => {
  // Custom hooks
  let { publicId, leadId, quoteId } = useParams<{
    publicId: string;
    leadId: string;
    quoteId: string;
  }>();

  // Render
  if (publicId && leadId && quoteId) {
    return (
      <Navigate
        to={`/request/pending?publicId=${publicId}&leadId=${leadId}&quoteId=${quoteId}`}
      />
    );
  }

  return null;
};

// eslint-disable-next-line import/no-default-export
export default ConnectiveRedirect;
