var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Platform } from "react-native";
/* eslint-disable @typescript-eslint/no-var-requires */
var CARD_CONTACT_FINANCIAL = require("../../../assets/images/card_contact_financial.png");
var CARD_CONTACT_TECHNICAL = require("../../../assets/images/card_contact_technical.png");
var CARD_CONTACT_TECHNICAL_AND_FINANCIAL = require("../../../assets/images/card_contact_technical_and_financial.png");
var CARD_CREATE_PROPERTY = require("../../../assets/images/card_create_property.png");
var CARD_INVITE_OWNER = require("../../../assets/images/card_invite_owner.png");
var CARD_WELCOME = require("../../../assets/images/card_welcome.png");
var ICO_ADD_CIRCLE = require("../../../assets/images/ico_add_circle.png");
var ICO_BUILDING_PNG = require("../../../assets/images/ico_building.png");
var ICO_BUILDING_SVG = require("../../../assets/images/ico_building.svg");
var ICO_CALL_PNG = require("../../../assets/images/ico_call.png");
var ICO_CALL_SVG = require("../../../assets/images/ico_call.svg");
var ICO_CALL_WHITE_PNG = require("../../../assets/images/ico_call_white.png");
var ICO_CALL_WHITE_SVG = require("../../../assets/images/ico_call_white.svg");
var ICO_CASH_HAND_PNG = require("../../../assets/images/ico_cash_hand.png");
var ICO_CASH_HAND_SVG = require("../../../assets/images/ico_cash_hand.svg");
var ICO_CHAT_ADD_ATTACHMENT_PNG = require("../../../assets/images/ico_chat_add_attachment.png");
var ICO_CHAT_ADD_ATTACHMENT_SVG = require("../../../assets/images/ico_chat_add_attachment.svg");
var ICO_CHAT_MESSAGE_DELIVERED_PNG = require("../../../assets/images/ico_chat_message_delivered.png");
var ICO_CHAT_MESSAGE_DELIVERED_SVG = require("../../../assets/images/ico_chat_message_delivered.svg");
var ICO_CHAT_MESSAGE_READ_PNG = require("../../../assets/images/ico_chat_message_read.png");
var ICO_CHAT_MESSAGE_READ_SVG = require("../../../assets/images/ico_chat_message_read.svg");
var ICO_CHAT_MESSAGE_SENT_PNG = require("../../../assets/images/ico_chat_message_sent.png");
var ICO_CHAT_MESSAGE_SENT_SVG = require("../../../assets/images/ico_chat_message_sent.svg");
var ICO_CHAT_MULTIPLE_PNG = require("../../../assets/images/ico_chat_multiple.png");
var ICO_CHAT_MULTIPLE_SVG = require("../../../assets/images/ico_chat_multiple.svg");
var ICO_CHAT_PNG = require("../../../assets/images/ico_chat.png");
var ICO_CHAT_SEND_PNG = require("../../../assets/images/ico_chat_send.png");
var ICO_CHAT_SEND_SVG = require("../../../assets/images/ico_chat_send.svg");
var ICO_CHAT_SINGLE_PNG = require("../../../assets/images/ico_chat_single.png");
var ICO_CHAT_SINGLE_SVG = require("../../../assets/images/ico_chat_single.svg");
var ICO_CHAT_SVG = require("../../../assets/images/ico_chat.svg");
var ICO_CHAT_WHITE_PNG = require("../../../assets/images/ico_chat_white.png");
var ICO_CHAT_WHITE_SVG = require("../../../assets/images/ico_chat_white.svg");
var ICO_CHECK_2_PNG = require("../../../assets/images/ico_check_2.png");
var ICO_CHECK_2_SVG = require("../../../assets/images/ico_check_2.svg");
var ICO_CHECK_PNG = require("../../../assets/images/ico_check.png");
var ICO_CHECK_SVG = require("../../../assets/images/ico_check.svg");
var ICO_CHECK_WHITE_PNG = require("../../../assets/images/ico_check_white.png");
var ICO_CHECK_WHITE_SVG = require("../../../assets/images/ico_check_white.svg");
var ICO_CHECKBOX_CHECKED = require("../../../assets/images/ico_checkbox_checked.png");
var ICO_ARCHIVE = require("../../../assets/images/ico_archive.png");
var ICO_CHECKBOX_GREEN = require("../../../assets/images/checkbox-mark.png");
var ICO_CHECKBOX_UNCHECKED = require("../../../assets/images/ico_checkbox_unchecked.png");
var ICO_CHECKLIST_PNG = require("../../../assets/images/ico_checklist.png");
var ICO_CHECKLIST_SVG = require("../../../assets/images/ico_checklist.svg");
var ICO_CLOSE_DARK = require("../../../assets/images/ico_close_dark.png");
var ICO_CLOSE_OVERLAY_PNG = require("../../../assets/images/ico_close_overlay.png");
var ICO_CLOSE_OVERLAY_SVG = require("../../../assets/images/ico_close_overlay.svg");
var ICO_CLOSE_ROUND_DARK_PNG = require("../../../assets/images/ico_close_round_dark.png");
var ICO_CLOSE_ROUND_DARK_SVG = require("../../../assets/images/ico_close_round_dark.svg");
var ICO_CLOSE_ROUND_PNG = require("../../../assets/images/ico_close_round.png");
var ICO_CLOSE_ROUND_SVG = require("../../../assets/images/ico_close_round.svg");
var ICO_CLOSE_WHITE_PNG = require("../../../assets/images/ico_close_white.png");
var ICO_CLOSE_WHITE_SVG = require("../../../assets/images/ico_close_white.svg");
var ICO_CONNECTION_NO = require("../../../assets/images/ico_connection_no.png");
var ICO_DISCLOSURE_PNG = require("../../../assets/images/ico_disclosure.png");
var ICO_DISCLOSURE_SVG = require("../../../assets/images/ico_disclosure.svg");
var ICO_DOCUMENTS = require("../../../assets/images/ico_documents.png");
var ICO_EURO = require("../../../assets/images/ico_euro.png");
var ICO_HISTORY = require("../../../assets/images/ico_history.png");
var ICO_INFO_CIRCLE = require("../../../assets/images/information-circle.png");
var ICO_INFO_CIRCLE_FILL = require("../../../assets/images/information-circle-fill.png");
var ICO_INFO_CIRCLE_GREEN_PNG = require("../../../assets/images/info-circle-green.png");
var ICO_INFO_CIRCLE_GREEN_SVG = require("../../../assets/images/info-circle-green.svg");
var ICO_INFO_CONTRACT_PNG = require("../../../assets/images/ico_info_contract.png");
var ICO_INFO_CONTRACT_SVG = require("../../../assets/images/ico_info_contract.svg");
var ICO_INFO_FORM_FIELD_PNG = require("../../../assets/images/ico_info_form_field.png");
var ICO_INFO_FORM_FIELD_SVG = require("../../../assets/images/ico_info_form_field.svg");
var ICO_LOADING_WHITE_PNG = require("../../../assets/images/ico_loading_white.png");
var ICO_LOADING_WHITE_SVG = require("../../../assets/images/ico_loading_white.svg");
var ICO_PROBLEM_PNG = require("../../../assets/images/ico_problem.png");
var ICO_PROBLEM_SVG = require("../../../assets/images/ico_problem.svg");
var ICO_PROBLEM_WHITE_PNG = require("../../../assets/images/ico_problem_white.png");
var ICO_PROBLEM_WHITE_SVG = require("../../../assets/images/ico_problem_white.svg");
var ICO_PROPERTY_PNG = require("../../../assets/images/ico_property.png");
var ICO_PROPERTY_SVG = require("../../../assets/images/ico_property.svg");
var ICO_SLIDER_X = require("../../../assets/images/slider-x-mark.png");
var ICO_STATE_ERROR = require("../../../assets/images/ico_state_error.png");
var ICO_STATE_SUCCESS = require("../../../assets/images/ico_state_success.png");
var ICO_SYNCHRONIZE_ARROWS = require("../../../assets/images/ico_synchronize_arrows.png");
var PROPERTY_DEFAULT_IMAGE = require("../../../assets/images/property_default_image.png");
var PROPERTY_IMAGE = require("../../../assets/images/property_image.png");
var LOGO_KEYPOINT = require("../../../assets/images/logo-keypoint-no-text.png");
// eslint-disable-next-line import/no-default-export
export default __assign({ CARD_CONTACT_FINANCIAL: CARD_CONTACT_FINANCIAL, CARD_CONTACT_TECHNICAL_AND_FINANCIAL: CARD_CONTACT_TECHNICAL_AND_FINANCIAL, CARD_CONTACT_TECHNICAL: CARD_CONTACT_TECHNICAL, CARD_CREATE_PROPERTY: CARD_CREATE_PROPERTY, CARD_INVITE_OWNER: CARD_INVITE_OWNER, CARD_WELCOME: CARD_WELCOME, ICO_ADD_CIRCLE: ICO_ADD_CIRCLE, ICO_CHECKBOX_CHECKED: ICO_CHECKBOX_CHECKED, ICO_ARCHIVE: ICO_ARCHIVE, ICO_CHECKBOX_GREEN: ICO_CHECKBOX_GREEN, ICO_CHECKBOX_UNCHECKED: ICO_CHECKBOX_UNCHECKED, ICO_CLOSE_DARK: ICO_CLOSE_DARK, ICO_CONNECTION_NO: ICO_CONNECTION_NO, ICO_DOCUMENTS: ICO_DOCUMENTS, ICO_EURO: ICO_EURO, ICO_HISTORY: ICO_HISTORY, ICO_INFO_CIRCLE_FILL: ICO_INFO_CIRCLE_FILL, ICO_INFO_CIRCLE: ICO_INFO_CIRCLE, ICO_SLIDER_X: ICO_SLIDER_X, ICO_STATE_ERROR: ICO_STATE_ERROR, ICO_STATE_SUCCESS: ICO_STATE_SUCCESS, ICO_SYNCHRONIZE_ARROWS: ICO_SYNCHRONIZE_ARROWS, PROPERTY_DEFAULT_IMAGE: PROPERTY_DEFAULT_IMAGE, PROPERTY_IMAGE: PROPERTY_IMAGE, LOGO_KEYPOINT: LOGO_KEYPOINT }, (Platform.OS === "web"
    ? {
        ICO_BUILDING: ICO_BUILDING_SVG,
        ICO_CALL_WHITE: ICO_CALL_WHITE_SVG,
        ICO_CALL: ICO_CALL_SVG,
        ICO_CASH_HAND: ICO_CASH_HAND_SVG,
        ICO_CHAT_ADD_ATTACHMENT: ICO_CHAT_ADD_ATTACHMENT_SVG,
        ICO_CHAT_MESSAGE_DELIVERED: ICO_CHAT_MESSAGE_DELIVERED_SVG,
        ICO_CHAT_MESSAGE_READ: ICO_CHAT_MESSAGE_READ_SVG,
        ICO_CHAT_MESSAGE_SENT: ICO_CHAT_MESSAGE_SENT_SVG,
        ICO_CHAT_MULTIPLE: ICO_CHAT_MULTIPLE_SVG,
        ICO_CHAT_SEND: ICO_CHAT_SEND_SVG,
        ICO_CHAT_SINGLE: ICO_CHAT_SINGLE_SVG,
        ICO_CHAT_WHITE: ICO_CHAT_WHITE_SVG,
        ICO_CHAT: ICO_CHAT_SVG,
        ICO_CHECK_2: ICO_CHECK_2_SVG,
        ICO_CHECK_WHITE: ICO_CHECK_WHITE_SVG,
        ICO_CHECK: ICO_CHECK_SVG,
        ICO_CHECKLIST: ICO_CHECKLIST_SVG,
        ICO_CLOSE_OVERLAY: ICO_CLOSE_OVERLAY_SVG,
        ICO_CLOSE_ROUND_DARK: ICO_CLOSE_ROUND_DARK_SVG,
        ICO_CLOSE_ROUND: ICO_CLOSE_ROUND_SVG,
        ICO_CLOSE_WHITE: ICO_CLOSE_WHITE_SVG,
        ICO_DISCLOSURE: ICO_DISCLOSURE_SVG,
        ICO_INFO_CIRCLE_GREEN: ICO_INFO_CIRCLE_GREEN_SVG,
        ICO_INFO_CONTRACT: ICO_INFO_CONTRACT_SVG,
        ICO_INFO_FORM_FIELD: ICO_INFO_FORM_FIELD_SVG,
        ICO_LOADING_WHITE: ICO_LOADING_WHITE_SVG,
        ICO_PROBLEM_WHITE: ICO_PROBLEM_WHITE_SVG,
        ICO_PROBLEM: ICO_PROBLEM_SVG,
        ICO_PROPERTY: ICO_PROPERTY_SVG,
    }
    : {
        ICO_BUILDING: ICO_BUILDING_PNG,
        ICO_CALL_WHITE: ICO_CALL_WHITE_PNG,
        ICO_CALL: ICO_CALL_PNG,
        ICO_CASH_HAND: ICO_CASH_HAND_PNG,
        ICO_CHAT_ADD_ATTACHMENT: ICO_CHAT_ADD_ATTACHMENT_PNG,
        ICO_CHAT_MESSAGE_DELIVERED: ICO_CHAT_MESSAGE_DELIVERED_PNG,
        ICO_CHAT_MESSAGE_READ: ICO_CHAT_MESSAGE_READ_PNG,
        ICO_CHAT_MESSAGE_SENT: ICO_CHAT_MESSAGE_SENT_PNG,
        ICO_CHAT_MULTIPLE: ICO_CHAT_MULTIPLE_PNG,
        ICO_CHAT_SEND: ICO_CHAT_SEND_PNG,
        ICO_CHAT_SINGLE: ICO_CHAT_SINGLE_PNG,
        ICO_CHAT_WHITE: ICO_CHAT_WHITE_PNG,
        ICO_CHAT: ICO_CHAT_PNG,
        ICO_CHECK_2: ICO_CHECK_2_PNG,
        ICO_CHECK_WHITE: ICO_CHECK_WHITE_PNG,
        ICO_CHECK: ICO_CHECK_PNG,
        ICO_CHECKLIST: ICO_CHECKLIST_PNG,
        ICO_CLOSE_OVERLAY: ICO_CLOSE_OVERLAY_PNG,
        ICO_CLOSE_ROUND_DARK: ICO_CLOSE_ROUND_DARK_PNG,
        ICO_CLOSE_ROUND: ICO_CLOSE_ROUND_PNG,
        ICO_CLOSE_WHITE: ICO_CLOSE_WHITE_PNG,
        ICO_DISCLOSURE: ICO_DISCLOSURE_PNG,
        ICO_INFO_CIRCLE_GREEN: ICO_INFO_CIRCLE_GREEN_PNG,
        ICO_INFO_CONTRACT: ICO_INFO_CONTRACT_PNG,
        ICO_INFO_FORM_FIELD: ICO_INFO_FORM_FIELD_PNG,
        ICO_LOADING_WHITE: ICO_LOADING_WHITE_PNG,
        ICO_PROBLEM_WHITE: ICO_PROBLEM_WHITE_PNG,
        ICO_PROBLEM: ICO_PROBLEM_PNG,
        ICO_PROPERTY: ICO_PROPERTY_PNG,
    }));
