import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ImageWrap = styled.div``;

export const Image = styled.img`
  width: 100%;
  max-width: 650px;
  max-height: 420px;
  object-fit: contain;
  margin-bottom: 48px;
`;

export const Title = styled.span`
  max-width: 700px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.global1};
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: inherit;
  }
`;

export const Info = styled.span`
  max-width: 700px;
  font-size: 18px;
  color: ${props => props.theme.colors.global1};
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: inherit;
  }
`;
