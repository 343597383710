import { append } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
export var getPayment = function (leadId, quoteId, paymentId, publicId) {
    return api.get(append("/leads/".concat(leadId, "/quotes/").concat(quoteId, "/payments/").concat(paymentId), {
        publicId: publicId,
    }, false));
};
export var createPayment = function (leadId, quoteId, publicId) {
    return api.post(append("/leads/".concat(leadId, "/quotes/").concat(quoteId, "/payments"), { publicId: publicId }, false));
};
