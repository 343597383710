var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Platform, Switch } from "react-native";
import images from "../../assets";
import rentioTheme from "../../theme/theme.rentio";
import * as S from "./SwitchToggle.styles";
var SwitchToggle = /** @class */ (function (_super) {
    __extends(SwitchToggle, _super);
    function SwitchToggle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    SwitchToggle.prototype.render = function () {
        var _a = this.props, onValueChange = _a.onValueChange, value = _a.value, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b;
        if (Platform.OS === "web") {
            return (_jsx(S.WebWrap, { value: value, isDisabled: isDisabled, children: _jsx(S.WebInnerWrap, { value: value, onClick: function (e) {
                        var _a, _b;
                        (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                        (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
                        onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange();
                    }, children: _jsx(S.WebIcon, { value: value, children: _jsx(S.WebImg, { src: value ? images.ICO_CHECK_WHITE : images.ICO_CLOSE_WHITE }) }) }) }));
        }
        return (_jsx(Switch, { trackColor: {
                false: "rgb(237, 237, 237)",
                true: rentioTheme.colors.global5,
            }, tintColor: rentioTheme.colors.global5, onTintColor: rentioTheme.colors.global5, thumbColor: rentioTheme.colors.global3, thumbTintColor: rentioTheme.colors.global3, onValueChange: onValueChange, value: value }));
    };
    return SwitchToggle;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default SwitchToggle;
