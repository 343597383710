import FormHelpInfo from "@rentiohq/shared-frontend/dist/components/components/FormHelpInfo";
import FormSectionHeader from "@rentiohq/shared-frontend/dist/components/components/FormSectionHeader";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useMailAgreement } from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.hooks";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import ButtonMail from "components/ButtonMail";
import QuoteInfoBanner from "components/QuoteInfoBanner";
import SubmitButtonGroup from "components/SubmitButtonGroup";
import { getSteps } from "modules/request/config/progressBar.config";
import { useNextStepNavigation } from "modules/request/navigation.utils";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import OverviewSection from "../../components/OverviewSection";
import ProgressBar from "../../components/ProgressBar";
import {
  getContactInfoItems,
  getOptionsInfoItems,
  getPropertyInfoItems,
} from "./Overview.helpers";
import * as S from "./Overview.styles";

interface IProps {}

const Overview: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();

  // Custom hooks
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();

  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
  } = useGetLead({
    leadId,
    publicId,
  });

  const { quote } = useGetQuote({
    leadId,
    quoteId,
    publicId,
  });

  const { navigateToNextStep, isPending: isPendingNavigation } =
    useNextStepNavigation({
      leadId,
      quote,
      publicId,
    });

  const { mailAgreementAndNavigate } = useMailAgreement({
    leadId,
    quote,
    publicId,
  });

  // Event handlers
  const handlePressEditPropertyInfo = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/property",
        queryParams: { leadId, quoteId, publicId },
      }),
    );
  };

  const handlePressEditOptions = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/options",
        queryParams: { leadId, quoteId, publicId },
      }),
    );
  };

  const handlePressEditContactInfo = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/contact",
        queryParams: { leadId, quoteId, publicId },
      }),
    );
  };

  const handlePressBack = () => {
    handlePressEditContactInfo();
  };

  // Render
  if (!leadId || !publicId) {
    return <Navigate to={"/"} />;
  }

  if (!quoteId) {
    return (
      <Navigate
        to={appendQueryParams({
          path: "/property/options",
          queryParams: { leadId, quoteId, publicId },
        })}
      />
    );
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetchingLead) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchLeadError) {
      return;
    }

    return <ErrorLead errorLead={fetchLeadError} />;
  };

  const renderLead = () => {
    if (!lead) {
      return;
    }

    return (
      <>
        <QuoteInfoBanner lead={lead} quote={quote} />

        <Spacer weight={ESpacerWeight.W24} />

        {quote && (
          <>
            <FormHelpInfo
              subtitle={getLocalizedText("insurance.overview.info_costs", {
                estimateCostsAcquisition: `${formatCurrency(
                  quote.estimateCostsAcquisition || 0,
                )}`,
                estimateCostsAdministration: `${formatCurrency(
                  quote.estimateCostsAdministration || 0,
                )}`,
                estimateCostsManagement: `${formatCurrency(
                  quote.estimateCostsManagement || 0,
                )}`,
              })}
              showCloseButton={false}
            />

            <Spacer weight={ESpacerWeight.W24} />
          </>
        )}

        <FormSectionHeader
          title={getLocalizedText("insurance.overview.title")}
        />

        <OverviewSection
          title={getLocalizedText("form.request_insurance.title")}
          items={getPropertyInfoItems(lead)}
          onClickEdit={handlePressEditPropertyInfo}
        />

        <ListItemSeparator />

        {quote && (
          <>
            <OverviewSection
              title={getLocalizedText("insurance.options.summary.title")}
              items={getOptionsInfoItems(quote)}
              onClickEdit={handlePressEditOptions}
            />

            <ListItemSeparator />
          </>
        )}

        <OverviewSection
          title={getLocalizedText("insurance.contact_info.title")}
          items={getContactInfoItems(lead)}
          onClickEdit={handlePressEditContactInfo}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <SubmitButtonGroup
          titleNext={getLocalizedText("system.next")}
          onPressBack={handlePressBack}
          onPressNext={navigateToNextStep}
          isLoadingNext={isPendingNavigation}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <S.ButtonMailWrap>
          <ButtonMail
            title={getLocalizedText("insurance.quote.cta.mail")}
            onPress={mailAgreementAndNavigate}
          />
        </S.ButtonMailWrap>
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        <ProgressBar steps={getSteps()} currentStep={3} />

        <Spacer weight={ESpacerWeight.W32} />

        {renderLead() || renderIsFetching() || renderError() || renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Overview;
