import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";

export interface IExtraData {
  lead: ILead;
  publicId: string;
}

export enum EField {
  Info = "_info",
  // Contract
  ContractStartDate = "contractStartDate",
  // Property
  BuildingSurfaceArea = "buildingSurfaceArea",
  // BuildingFloor = "buildingFloor",
  BuildingLayers = "buildingLayers",
  BuildingAdjoined = "buildingAdjoined",
}
