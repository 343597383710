import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import FormSectionHeader from "@rentiohq/shared-frontend/dist/components/components/FormSectionHeader";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useActiveQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { getQuoteBasePrice } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.utils";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { htmlTextAsReactText } from "@rentiohq/shared-frontend/dist/utils/text/text.utils";
import assets from "assets";
import QuoteInfoBanner from "components/QuoteInfoBanner";
import SubmitButtonGroup from "components/SubmitButtonGroup";
import { getSteps } from "modules/request/config/progressBar.config";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import ProgressBar from "../../components/ProgressBar";
import QuoteOptionContentPremium from "../../components/QuoteOptionContentPremium";
import QuoteOptionLegalAssistancePremium from "../../components/QuoteOptionLegalAssistancePremium";
import QuoteOptionMotorVehiclesPremium from "../../components/QuoteOptionMotorVehiclesPremium";
import QuoteOptionTheftPremium from "../../components/QuoteOptionTheftPremium";
import * as S from "./Options.styles";

interface IProps {}

const Options: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();

  // State
  const [showMoreInfo, setShowMoreInfo] = React.useState(false);
  const [pendingValues, setPendingValues] = React.useState<
    Partial<IQuote> | undefined
  >(undefined);

  // Custom hooks
  const {
    leadId,
    quoteId: initialQuoteId,
    publicId,
  } = useInsuranceQueryParams();

  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
  } = useGetLead({
    leadId,
    publicId,
  });

  const {
    activeQuote,
    createError: quoteCreateError,
    isCreating: isCreatingQuote,
    createQuote,
  } = useActiveQuote({
    leadId,
    publicId,
    initialQuoteId,
  });

  React.useEffect(() => {
    if (isCreatingQuote) {
      return;
    }

    setPendingValues(undefined);
  }, [isCreatingQuote]);

  // Helpers
  const getUserValuesForQuote = () => {
    if (!activeQuote) {
      return {
        // Content
        optionContentPremium: false,
        optionContentPremiumAmountOfContent: 0,

        // Theft
        optionTheftPremium: false,

        // Motor vehicles
        optionMotorVehiclesPremium: false,
        optionMotorVehiclesPremiumAmountOfMotorVehicles: 0,

        // Legal assistance
        optionLegalAssistancePremium: false,

        // Add locally changed values
        ...(pendingValues || {}),
      };
    }

    return {
      // Content
      optionContentPremium: activeQuote.optionContentPremium,
      optionContentPremiumAmountOfContent:
        activeQuote.optionContentPremiumAmountOfContent,

      // Theft
      optionTheftPremium: activeQuote.optionTheftPremium,

      // Motor vehicles
      optionMotorVehiclesPremium: activeQuote.optionMotorVehiclesPremium,
      optionMotorVehiclesPremiumAmountOfMotorVehicles:
        activeQuote.optionMotorVehiclesPremiumAmountOfMotorVehicles,

      // Legal assistance
      optionLegalAssistancePremium: activeQuote.optionLegalAssistancePremium,

      // Add locally changed values
      ...(pendingValues || {}),
    };
  };

  // Event handlers
  const handlePressMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  const handlePressBack = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/property",
        queryParams: { leadId, quoteId: activeQuote?.id, publicId },
      }),
    );
  };

  const handlePressNext = () => {
    if (!leadId || !activeQuote || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/contact",
        queryParams: {
          leadId,
          quoteId: activeQuote.id,
          publicId,
        },
      }),
    );
  };

  const handleValueChange = (values: Partial<IQuote>) => {
    if (!leadId || !activeQuote || !publicId) {
      return;
    }

    const newQuote = {
      ...getUserValuesForQuote(),
      ...values,
    };

    createQuote(newQuote);

    setPendingValues(newQuote);
  };

  const handleValueChangeContentPremium = (isActive: boolean) => {
    const values: Partial<IQuote> = {
      optionContentPremium: isActive,
      optionContentPremiumAmountOfContent: isActive
        ? CONFIG.INSURANCE_PREMIUMS.CONTENT_DEFAULT_AMOUNT_OF_CONTENT
        : undefined,
    };

    // Reset theft & motor vehicles when content premium gets disabled
    if (!isActive) {
      values.optionTheftPremium = false;

      values.optionMotorVehiclesPremium = false;
      values.optionMotorVehiclesPremiumAmountOfMotorVehicles = undefined;
    }

    handleValueChange(values);
  };

  const handleAmountOfContentChange = (contentAmount: number) => {
    handleValueChange({
      optionContentPremium: true,
      optionContentPremiumAmountOfContent: contentAmount,
    });
  };

  const handleValueChangeTheftPremium = (isActive: boolean) => {
    const values: Partial<IQuote> = {
      optionTheftPremium: isActive,
    };

    // Content is mandatory if theft is enabled
    if (isActive) {
      values.optionContentPremium = true;
      values.optionContentPremiumAmountOfContent =
        activeQuote?.optionContentPremiumAmountOfContent ||
        CONFIG.INSURANCE_PREMIUMS.CONTENT_DEFAULT_AMOUNT_OF_CONTENT;
    }

    handleValueChange(values);
  };

  const handleValueChangeVehiclesPremium = (isActive: boolean) => {
    const values: Partial<IQuote> = {
      optionMotorVehiclesPremium: isActive,
      optionMotorVehiclesPremiumAmountOfMotorVehicles: isActive
        ? CONFIG.INSURANCE_PREMIUMS
            .MOTOR_VEHICLES_DEFAULT_AMOUNT_OF_MOTOR_VEHICLES
        : undefined,
    };

    // Content is mandatory if motor vehicles are enabled
    if (isActive) {
      values.optionContentPremium = true;
      values.optionContentPremiumAmountOfContent =
        activeQuote?.optionContentPremiumAmountOfContent ||
        CONFIG.INSURANCE_PREMIUMS.CONTENT_DEFAULT_AMOUNT_OF_CONTENT;
    }

    handleValueChange(values);
  };

  const handleAmountOfMotorVehiclesChange = (motorVehiclesAmount: number) => {
    handleValueChange({
      optionMotorVehiclesPremium: true,
      optionMotorVehiclesPremiumAmountOfMotorVehicles: motorVehiclesAmount,
    });
  };

  const handleValueChangeLegalAssistancePremium = (isActive: boolean) => {
    handleValueChange({
      optionLegalAssistancePremium: isActive,
    });
  };

  // Render
  if (!leadId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetchingLead) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchLeadError) {
      return;
    }

    return <ErrorLead errorLead={fetchLeadError} />;
  };

  const renderLead = () => {
    if (!lead) {
      return;
    }

    return (
      <>
        <QuoteInfoBanner
          lead={lead}
          quote={activeQuote}
          quoteError={quoteCreateError}
          isCreatingQuote={isCreatingQuote}
          showMonthlyPrice={true}
        />

        <Spacer weight={ESpacerWeight.W24} />

        {activeQuote && (
          <S.ExtraInfoBanner>
            <S.ExtraInfoIcon src={assets.ICO_HOUSE_FIRE} />
            <S.ExtraInfoWrap>
              <S.ExtraInfoTitle
                dangerouslySetInnerHTML={{
                  __html: getLocalizedText("insurance.base.info_base", {
                    price: `${formatCurrency(getQuoteBasePrice(activeQuote))}`,
                  }),
                }}
              />
              <ButtonBasic
                title={getLocalizedText(
                  getLocalizedText("insurance.base.cta.show_info_extra"),
                )}
                onPress={handlePressMoreInfo}
              />
              {showMoreInfo && (
                <S.ExtraInfoBody>
                  {htmlTextAsReactText(
                    getLocalizedText("insurance.base.info_extra"),
                  )}
                </S.ExtraInfoBody>
              )}
            </S.ExtraInfoWrap>
          </S.ExtraInfoBanner>
        )}

        {/* <Spacer weight={ESpacerWeight.W32} /> */}

        {/* <QuoteOptionBase
          quote={activeQuote}
          icon={icoHouseFire}
          title={getLocalizedText("insurance.options.premium.base.title")}
          info={getLocalizedText("insurance.options.premium.base.info")}
          price={activeQuote ? getQuoteBasePrice(activeQuote) : 0}
          isCreatingQuote={false}
          isActive={true}
          showToggle={false}
        /> */}

        <Spacer weight={ESpacerWeight.W24} />

        <FormSectionHeader
          title={getLocalizedText("insurance.options.title")}
        />

        <QuoteOptionContentPremium
          quote={activeQuote}
          isCreatingQuote={isCreatingQuote}
          onValueChange={handleValueChangeContentPremium}
          onAmountOfContentChange={handleAmountOfContentChange}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <QuoteOptionTheftPremium
          quote={activeQuote}
          isCreatingQuote={isCreatingQuote}
          onValueChange={handleValueChangeTheftPremium}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <QuoteOptionMotorVehiclesPremium
          quote={activeQuote}
          isCreatingQuote={isCreatingQuote}
          onValueChange={handleValueChangeVehiclesPremium}
          onAmountOfMotorVehiclesChange={handleAmountOfMotorVehiclesChange}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <QuoteOptionLegalAssistancePremium
          quote={activeQuote}
          isCreatingQuote={isCreatingQuote}
          onValueChange={handleValueChangeLegalAssistancePremium}
        />

        <Spacer weight={ESpacerWeight.W24} />

        <SubmitButtonGroup
          titleNext={getLocalizedText("system.next")}
          onPressBack={handlePressBack}
          onPressNext={handlePressNext}
        />
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        <ProgressBar steps={getSteps()} currentStep={1} />

        <Spacer weight={ESpacerWeight.W32} />

        {renderLead() || renderIsFetching() || renderError() || renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Options;
