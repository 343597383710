export var EEnvironment;
(function (EEnvironment) {
    EEnvironment["Dev"] = "DEV";
    EEnvironment["Stag"] = "STAG";
    EEnvironment["Demo"] = "DEMO";
    EEnvironment["Prod"] = "PROD";
})(EEnvironment || (EEnvironment = {}));
export var EProduct;
(function (EProduct) {
    EProduct["App"] = "APP";
    EProduct["Dashboard"] = "DASHBOARD";
    EProduct["Backoffice"] = "BACKOFFICE";
    EProduct["PasswordReset"] = "PASSWORD_RESET";
    EProduct["Intake"] = "INTAKE";
    EProduct["Pay"] = "PAY";
    EProduct["Move"] = "MOVE";
    EProduct["Insurance"] = "INSURANCE";
    EProduct["InsuranceBackoffice"] = "INSURANCE_BACKOFFICE";
})(EProduct || (EProduct = {}));
