var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as quoteActions from "./quote.actions";
export var REDUCER_KEY_QUOTE = "quote";
var getDefaultState = function () { return ({
    quotes: {},
    quotesById: {},
    quotesByLeadId: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(quoteActions.getQuote.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId;
    return (__assign(__assign({}, state), { quotesById: __assign(__assign({}, state.quotesById), (_b = {}, _b[quoteId] = __assign(__assign({}, state.quotesById[quoteId]), { leadId: leadId, isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(quoteActions.getQuote.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var leadId = _a.leadId, quoteId = _a.quoteId, quote = _a.quote;
    return (__assign(__assign({}, state), { quotes: __assign(__assign({}, state.quotes), (_b = {}, _b[quote.id] = quote, _b)), quotesById: __assign(__assign({}, state.quotesById), (_c = {}, _c[quoteId] = __assign(__assign({}, state.quotesById[quoteId]), { leadId: leadId, isFetching: false, fetchError: undefined }), _c)), quotesByLeadId: __assign(__assign({}, state.quotesByLeadId), (_d = {}, _d[leadId] = __assign(__assign({}, state.quotesByLeadId[leadId]), { activeQuoteId: quote.id, isCreating: false, createError: undefined }), _d)) }));
});
reducer.on(quoteActions.getQuote.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, error = _a.error;
    return (__assign(__assign({}, state), { quotesById: __assign(__assign({}, state.quotesById), (_b = {}, _b[quoteId] = __assign(__assign({}, state.quotesById[quoteId]), { leadId: leadId, isFetching: false, fetchError: error }), _b)) }));
});
reducer.on(quoteActions.createQuote.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId;
    return (__assign(__assign({}, state), { quotesByLeadId: __assign(__assign({}, state.quotesByLeadId), (_b = {}, _b[leadId] = __assign(__assign({}, state.quotesByLeadId[leadId]), { isCreating: true, createError: undefined }), _b)) }));
});
reducer.on(quoteActions.createQuote.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var leadId = _a.leadId, quote = _a.quote;
    return (__assign(__assign({}, state), { quotes: __assign(__assign({}, state.quotes), (_b = {}, _b[quote.id] = quote, _b)), quotesByLeadId: __assign(__assign({}, state.quotesByLeadId), (_c = {}, _c[leadId] = __assign(__assign({}, state.quotesByLeadId[leadId]), { activeQuoteId: quote.id, isCreating: false, createError: undefined }), _c)) }));
});
reducer.on(quoteActions.createQuote.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, error = _a.error;
    return (__assign(__assign({}, state), { quotesByLeadId: __assign(__assign({}, state.quotesByLeadId), (_b = {}, _b[leadId] = __assign(__assign({}, state.quotesByLeadId[leadId]), { isCreating: false, createError: error }), _b)) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
