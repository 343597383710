import iban from "iban";
export var formatIban = function (input) {
    if (!input) {
        return;
    }
    return iban.printFormat(input);
};
export var formatIbanWithoutSpaces = function (phone) {
    var parsed = formatIban(phone);
    if (!parsed) {
        return;
    }
    return parsed.replace(/ /g, "");
};
export var isValidIban = function (input) {
    if (!input) {
        return false;
    }
    return iban.isValid(input);
};
