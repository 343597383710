var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as S from "./ListItemSelect.styles";
var ListItemSelect = /** @class */ (function (_super) {
    __extends(ListItemSelect, _super);
    function ListItemSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderComponentLeft = function () {
            var _a = _this.props.showSelectionLeft, showSelectionLeft = _a === void 0 ? false : _a;
            if (!showSelectionLeft) {
                return null;
            }
            return (_jsx(S.RadioCheckStyled, { selected: _this.props.selected, showSelectionLeft: true }));
        };
        _this.renderComponentRight = function () {
            var _a = _this.props.showSelectionLeft, showSelectionLeft = _a === void 0 ? false : _a;
            if (showSelectionLeft) {
                return null;
            }
            return (_jsx(S.RadioCheckStyled, { selected: _this.props.selected, showSelectionLeft: false }));
        };
        return _this;
    }
    // Render
    ListItemSelect.prototype.render = function () {
        var _a = this.props, selected = _a.selected, rest = __rest(_a, ["selected"]);
        return (_jsx(S.Wrap, { children: _jsx(S.SelectListItem, __assign({ titleNumberOfLines: 1, subtitleNumberOfLines: 1, showDisclosureIcon: false, renderComponentLeft: this.renderComponentLeft, renderComponentRight: this.renderComponentRight, extraData: selected }, rest)) }));
    };
    return ListItemSelect;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default ListItemSelect;
