var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import FormInputBase from "../FormInputBase";
import * as S from "./FormSegmentedControl.styles";
var FormSegmentedControl = /** @class */ (function (_super) {
    __extends(FormSegmentedControl, _super);
    function FormSegmentedControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderSegmentedControl = function () {
            var _a = _this.props, disabled = _a.disabled, error = _a.error, options = _a.options, selected = _a.selected, _b = _a.isFormField, isFormField = _b === void 0 ? true : _b;
            return (_jsxs(S.Wrap, { disabled: disabled, isFormField: isFormField, children: [_jsx(S.OptionsWrap, { hasError: !!error, children: options.map(function (_a) {
                            var id = _a.id, value = _a.value;
                            return (_jsx(S.OptionButton, { onPress: _this.handlePressListItemSelect(id), selected: selected === id, children: _jsx(S.OptionTitle, { selected: selected === id, isFormField: isFormField, children: value }) }, id));
                        }) }), error && _jsx(S.ErrorText, { children: error })] }));
        };
        // Event handlers
        _this.handlePressListItemSelect = function (id) { return function () {
            _this.props.onSelect(id);
        }; };
        return _this;
    }
    FormSegmentedControl.prototype.render = function () {
        var _a = this.props, title = _a.title, error = _a.error, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, info = _a.info, optional = _a.optional, disabled = _a.disabled, _b = _a.isFormField, isFormField = _b === void 0 ? true : _b;
        if (isFormField) {
            return (_jsx(FormInputBase, { title: title, info: info, error: error, optional: optional, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, disabled: disabled, showBorder: false, children: this.renderSegmentedControl() }));
        }
        return this.renderSegmentedControl();
    };
    return FormSegmentedControl;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormSegmentedControl;
