import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const HeaderWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const HeaderInnerWrap = styled.div`
  padding: 80px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    flex-direction: column-reverse;
    padding: 40px 24px;
  }
`;

export const HeaderInfo = styled.div`
  width: 50%;
  max-width: 400px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
    max-width: 524px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const HeaderInfoTitle = styled.h1`
  font-size: 36px;
  line-height: 54px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutral1};
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
    max-width: inherit;
    font-size: 26px;
    line-height: 39px;
    text-align: center;
  }
`;

export const HeaderVisual = styled.img`
  width: 50%;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
    max-width: 524px;
  }
`;

export const SectionBackgroundWrap = styled.div`
  background-color: ${props => props.theme.colors.global5};
`;

export const SectionWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
`;

export const SectionInnerWrap = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionTitle = styled.span<{ isInverted: boolean }>`
  margin-bottom: 16px;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  color: ${props =>
    props.isInverted
      ? props.theme.colors.global1
      : props.theme.colors.neutral2};
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: inherit;
  }
`;

export const CirclesWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  max-width: 970px;
`;

export const SectionInfo = styled.span`
  max-width: 330px;
  margin-top: 24px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  color: ${props => props.theme.colors.global1};
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: inherit;
  }
`;
