import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import React from "react";
import { SubmitButton } from "utils/styles.utils";
import * as S from "./SuccessState.styles";

interface IProps {
  image: string;
  title: string | React.ReactNode;
  info: string | React.ReactNode;
  actionTitle?: string;
  onPress?: () => void;
}

const SuccessState: React.FC<IProps> = (props: IProps) => {
  const { image, title, info, actionTitle, onPress } = props;

  // Render
  return (
    <S.Wrap>
      <S.ImageWrap>
        <S.Image src={image} />
      </S.ImageWrap>
      <S.Title>{title}</S.Title>
      <S.Info>{info}</S.Info>
      {onPress && (
        <>
          <Spacer weight={ESpacerWeight.W32} />
          <SubmitButton title={actionTitle || ""} onPress={onPress} />
        </>
      )}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default SuccessState;
