import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import SuccessState from "components/SuccessState";
import { useNextStepNavigation } from "modules/request/navigation.utils";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate } from "react-router-dom";
import { useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import * as S from "./Mailed.styles";

interface IProps {}

const Mailed: React.FC<IProps> = (_: IProps) => {
  // Custom hooks
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();

  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
  } = useGetLead({
    leadId,
    publicId,
  });

  const {
    quote,
    isFetching: isFetchingQuote,
    fetchError: fetchQuoteError,
  } = useGetQuote({
    leadId,
    quoteId,
    publicId,
  });

  const { navigateToNextStep } = useNextStepNavigation({
    leadId,
    quote,
    publicId,
  });

  // Render
  if (!leadId || !quoteId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetchingLead && !isFetchingQuote) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchLeadError && !fetchQuoteError) {
      return;
    }

    return (
      <ErrorLead errorLead={fetchLeadError} errorQuote={fetchQuoteError} />
    );
  };

  const renderContent = () => {
    if (!lead || !quote) {
      return;
    }

    return (
      <SuccessState
        image={assets.IMG_MAILED}
        title={getLocalizedText("insurance.mailed.title", {
          name: lead.contactFirstname,
        })}
        info={getLocalizedText("insurance.mailed.info")}
        actionTitle={getLocalizedText("insurance.mailed.cta.proceed")}
        onPress={navigateToNextStep}
      />
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        {renderContent() ||
          renderIsFetching() ||
          renderError() ||
          renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Mailed;
