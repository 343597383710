import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const Wrap = styled.button<{ circleSize: number }>`
  margin: ${props => props.circleSize / 3}px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin: ${props => props.circleSize / 3}px 8px;
  }
`;

export const Circle = styled.div<{ isInverted: boolean; circleSize: number }>`
  width: ${props => props.circleSize}px;
  height: ${props => props.circleSize}px;
  background-color: ${props =>
    props.isInverted
      ? props.theme.colors.global5
      : props.theme.colors.neutral2};
  border-radius: ${props => Math.floor(props.circleSize / 2)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: ${props => props.circleSize * 0.8}px;
    height: ${props => props.circleSize * 0.8}px;
  }
`;

export const CircleIcon = styled.img<{ circleSize: number }>`
  width: ${props => props.circleSize * 0.5}px;
  height: ${props => props.circleSize * 0.5}px;
`;

export const Title = styled.span<{ isInverted: boolean }>`
  margin-top: 12px;
  font-size: 18px;
  font-weight: bold;
  color: ${props =>
    props.isInverted
      ? props.theme.colors.neutral1
      : props.theme.colors.neutral2};
`;

export const Subtitle = styled.span`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.colors.global5};
`;

export const ExtraInfoWrap = styled.span`
  margin-top: 8px;
`;
