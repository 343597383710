import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import * as S from "./ContactFetchListItem.styles";
import * as utils from "./ContactFetchListItem.utils";
export var ContactFetchListItem = function (props) {
    var account = props.account, accountId = props.accountId, isFetching = props.isFetching, didFetchAccount = props.didFetchAccount, fetchError = props.fetchError, fetchedContact = props.fetchedContact, contact = props.contact, contactId = props.contactId, renderAlways = props.renderAlways, renderLoading = props.renderLoading, renderError = props.renderError, renderContact = props.renderContact, renderAccount = props.renderAccount, getContact = props.getContact, getContactsByAccountIds = props.getContactsByAccountIds;
    // Data
    useEffect(function () {
        if (contact || fetchedContact) {
            return;
        }
        if (isFetching) {
            return;
        }
        var contactIdParsed = utils.getContactId(props);
        if (contactIdParsed) {
            getContact({ contactId: contactIdParsed });
            return;
        }
        if (didFetchAccount) {
            return;
        }
        var accountIdParsed = utils.getAccountId(props);
        if (accountIdParsed) {
            getContactsByAccountIds({ accountIds: [accountIdParsed] });
            return;
        }
    }, [account, accountId, contactId]);
    var contactToRender = fetchedContact || contact;
    if (renderAlways) {
        return renderAlways(contactToRender, account);
    }
    if (contactToRender) {
        return (renderContact === null || renderContact === void 0 ? void 0 : renderContact(contactToRender)) || null;
    }
    if (account) {
        return (renderAccount === null || renderAccount === void 0 ? void 0 : renderAccount(account)) || null;
    }
    if (isFetching) {
        return (renderLoading === null || renderLoading === void 0 ? void 0 : renderLoading()) || _jsx(S.ActivityIndicatorStyled, {});
    }
    // TODO: Render default error
    if (fetchError) {
        return (renderError === null || renderError === void 0 ? void 0 : renderError(fetchError)) || null;
    }
    return null;
};
