import { removeUndefinedValuesFromObject } from "./object.utils";
export var append = function (baseUrl, parameters, stringify) {
    if (stringify === void 0) { stringify = true; }
    var query = Object.keys(removeUndefinedValuesFromObject(parameters)).map(function (parameter) {
        var parameterValueMapped = parameters[parameter];
        // TODO, fix so this is not hardcoded.
        if (parameter === "search") {
            //
        }
        else if (stringify) {
            parameterValueMapped = JSON.stringify(parameters[parameter]);
        }
        return "".concat(parameter, "=").concat(encodeURIComponent(parameterValueMapped));
    });
    if (query.length === 0) {
        return baseUrl;
    }
    return "".concat(baseUrl, "?").concat(query.join("&"));
};
export var appendWhere = function (baseUrl, where) {
    return append(baseUrl, { where: where });
};
export var appendWhereAndSelection = function (baseUrl, where, selection) { return append(baseUrl, { where: where, selection: selection }); };
export var appendFilter = function (baseUrl, filter) {
    return append(baseUrl, { filter: filter });
};
