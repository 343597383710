import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { transparentize } from "polished";

export const Border = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.colors.global4};
`;

export const BackgroundWrap = styled.div`
  background-color: ${props =>
    transparentize(0.97, props.theme.colors.global1)};
`;

export const Wrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
`;

export const InnerWrap = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkSectionsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    flex-direction: column;
  }
`;

export const LinkSectionWrap = styled.div`
  flex: 1;
  padding: 0 16px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 16px 0;
  }
  &:first-child {
    padding-left: 0px;
    @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
      padding-top: 0;
    }
  }
  &:last-child {
    padding-right: 0px;
  }
`;

export const LinkSectionTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutral1};
`;

export const LinkSectionLink = styled.button`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

export const LinkSectionLinkIcon = styled.img<{ isDropdownActive: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  transform: ${props =>
    props.isDropdownActive ? "rotate(180deg)" : "rotate(0deg)"};
  transition: ${props =>
    props.isDropdownActive ? "0.15s ease-in" : "0.05s ease-out"};
`;

export const LinkSectionLinkTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.global1};
  text-align: left;
`;

export const LinkSectionLinkContent = styled.span`
  margin-top: 6px;
  font-size: 14px;
  line-height: 16px;
  color: ${props => props.theme.colors.global1};
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: none;
    font-size: inherit;
  }
`;

export const LegalInfo = styled.span`
  width: 100%;
  max-width: 524px;
  margin: 40px 0;
  font-size: 15px;
  color: ${props => props.theme.colors.global1};
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    max-width: inherit;
  }
`;

export const LegalLinksTitle = styled.span`
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: bold;
  color: ${props => props.theme.colors.global2};
`;

export const LegalLinksWrap = styled.div`
  color: ${props => props.theme.colors.global2};
  font-size: 11px;
  font-weight: bold;
`;

export const LegalLink = styled.a`
  margin: 0;
  padding: 0 4px;
  color: ${props => props.theme.colors.global5};
  cursor: pointer;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
  }
`;
