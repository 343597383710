import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { ActivityIndicator as ActivityIndicatorInternal } from "react-native";

export const Wrap = styled.div<{ isActive: boolean }>`
  border: 2px solid
    ${props =>
      props.isActive ? props.theme.colors.global5 : props.theme.colors.global3};
  border-radius: 5px;
  padding: 16px 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 16px 12px;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrap = styled.div`
  background-color: ${props => props.theme.colors.neutral2};
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 24px;
  width: 60px;
  height: 60px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
`;

export const InfoWrap = styled.div`
  flex: 1;
`;

export const InfoHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.global1};
  display: flex;
`;

export const SwitchAndLoadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActivityIndicatorWrap = styled.div`
  margin-right: 8px;
`;

export const ActivityIndicator = styled(ActivityIndicatorInternal)``;

export const ContentHeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Content = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.global2};
`;

export const PriceWrap = styled.div`
  margin-left: 16px;
  min-width: 130px;
  text-align: right;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin-left: 0px;
    margin-top: 16px;
    min-width: 100%;
    width: 100%;
    text-align: right;
  }
`;

export const Price = styled.span`
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.global1};
`;
