export var ERentioFormField;
(function (ERentioFormField) {
    ERentioFormField["Address"] = "rentio:field:address";
    ERentioFormField["AddressStreet"] = "rentio:field:address_street";
    ERentioFormField["Array"] = "rentio:field:array";
    ERentioFormField["Captcha"] = "rentio:field:captcha";
    ERentioFormField["ContactSelectInlineV2"] = "rentio:field:contact_select_inline_v2";
    ERentioFormField["Date"] = "rentio:field:date";
    ERentioFormField["DocumentSelectInline"] = "rentio:field:document_select_inline";
    ERentioFormField["DropdownSelect"] = "rentio:field:dropdown_select";
    ERentioFormField["Email"] = "rentio:field:email";
    ERentioFormField["Grid"] = "rentio:field:grid";
    ERentioFormField["HelpInfo"] = "rentio:field:help_info";
    ERentioFormField["Iban"] = "rentio:field:iban";
    ERentioFormField["IbanSelectInline"] = "rentio:field:iban_select_inline";
    ERentioFormField["InlineSelect"] = "rentio:field:inline_select";
    ERentioFormField["Number"] = "rentio:field:number";
    ERentioFormField["Phone"] = "rentio:field:phone";
    ERentioFormField["PhoneOrEmail"] = "rentio:field:phone_or_email";
    ERentioFormField["PropertyInfo"] = "rentio:field:property_info";
    ERentioFormField["PropertySelectInline"] = "rentio:field:property_select_inline";
    ERentioFormField["Root"] = "rentio:field:object:root";
    ERentioFormField["SectionHeader"] = "rentio:field:section_header";
    ERentioFormField["SectionSubtitle"] = "rentio:field:section_subtitle";
    ERentioFormField["SegmentedControl"] = "rentio:field:segmented_control";
    ERentioFormField["Switch"] = "rentio:field:switch";
    ERentioFormField["SwitchContactSelect"] = "rentio:field:switch_contact_select";
    ERentioFormField["Text"] = "rentio:field:text";
    ERentioFormField["ManualLatePaymentsSelect"] = "rentio:field:manual_payments_select";
})(ERentioFormField || (ERentioFormField = {}));
export var ERentioFormFormat;
(function (ERentioFormFormat) {
    ERentioFormFormat["Email"] = "email";
    ERentioFormFormat["VATNumber"] = "vat-number";
    ERentioFormFormat["NationalRegisterNumber"] = "national-register-number";
})(ERentioFormFormat || (ERentioFormFormat = {}));
