var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getFieldInfo, getFieldTitle } from "../../../../redux/form/form.utils";
import FormSwitchContactSelect from "../FormSwitchContactSelect";
var FormSwitchContactSelectJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormSwitchContactSelectJsonFormSchema, _super);
    function FormSwitchContactSelectJsonFormSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    FormSwitchContactSelectJsonFormSchema.prototype.render = function () {
        var _a = this.props, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c, schema = _a.schema;
        var customUiSchema = uiSchema;
        var handleChangeValue = function (newValue, id) {
            newValue
                ? onChange(__spreadArray([], (formData || []), true).filter(function (randomId) { return randomId !== id; }))
                : onChange(__spreadArray(__spreadArray([], (formData || []), true), [id], false));
        };
        var getOptions = function () {
            // @ts-ignore
            return schema.getOptions(formContext.getAllFormData());
        };
        return (_jsx(FormSwitchContactSelect, __assign({}, customUiSchema, { getOptions: getOptions, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), selectedOptionIds: formData || [], onChangeValue: handleChangeValue, error: rawErrors[0] })));
    };
    return FormSwitchContactSelectJsonFormSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormSwitchContactSelectJsonFormSchema;
