import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import styledNative from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { ActivityIndicator } from "react-native";

export const Wrap = styled.div``;

export const DropdownWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
  min-width: 150px;
  margin-top: 16px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
`;

export const ActivityIndicatorDropdownWrap = styled.div`
  margin-left: 8px;
  margin-top: 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin-left: 0px;
    margin-top: 8px;
    margin-bottom: 16px;
  }
`;

export const ActivityIndicatorDropdown = styledNative(ActivityIndicator)`
height: 10px;
`;

export const LegalInfo = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.global2};
`;
