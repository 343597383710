import {
  createForm,
  ICreateForm,
} from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import {
  makeGetSchemas,
  mergedPartialFormDataSelector,
} from "@rentiohq/shared-frontend/dist/redux/form/form.selectors";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IRootState } from "redux/reducers";
import FormMultistep from "./FormMultistep";
import { IDispatchProps, IProps, IStateProps } from "./FormMultistep.types";

const mapStateToProps = (
  state: IRootState,
  {
    formId,
    isPerformingSubmitActionSelector,
    performingSubmitActionErrorSelector,
    performingSubmitActionResultSelector,
  }: IProps,
): IStateProps => {
  const getSchemas = makeGetSchemas();

  return {
    schemas: getSchemas(state, formId),
    withSummary: state.form[formId].withSummary,
    partialData: mergedPartialFormDataSelector(state, formId),
    isPerformingSubmitAction: isPerformingSubmitActionSelector
      ? isPerformingSubmitActionSelector(state)
      : false,
    performingSubmitActionResult: performingSubmitActionResultSelector
      ? performingSubmitActionResultSelector(state)
      : undefined,
    performingSubmitActionError: performingSubmitActionErrorSelector
      ? performingSubmitActionErrorSelector(state)
      : undefined,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  props: IProps,
): IDispatchProps => ({
  submitAction: (formData: any) => {
    if (props.submitActionCreator) {
      dispatch(props.submitActionCreator(formData));
    }
  },
  successAction: (id: any) => {
    if (props.successActionCreator) {
      dispatch(props.successActionCreator(id));
    }
  },
  createForm: (payload: ICreateForm) => {
    dispatch(createForm(payload));
  },
});

// eslint-disable-next-line import/no-default-export
export default connect<IStateProps, IDispatchProps, IProps, IRootState>(
  mapStateToProps,
  mapDispatchToProps,
)(FormMultistep);
