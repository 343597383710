import { connect } from "react-redux";
import FormIbanSelectInline from "./FormIbanSelectInline";
var mapStateToProps = function (state, props) { return ({
    isFetching: props.customIsFetchingSelector(state),
    ibans: props.customIbansSelector(state),
}); };
var mapDispatchToProps = function (dispatch, props) { return ({
    getIbans: function () {
        (props.customGetIbansActions || []).forEach(function (action) {
            dispatch(action());
        });
    },
}); };
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(FormIbanSelectInline);
