import FloatingFooterMenu from "components/FloatingFooterMenu";
import Footer from "components/Footer";
import Header from "components/Header";
import ScrollToTop from "components/ScrollToTop";
import { Outlet } from "react-router";

const Page = () => (
  <>
    <ScrollToTop />
    <Header />
    <div style={{ minHeight: "calc(99vh - 78px)" }}>{<Outlet />}</div>
    <Footer />
    <FloatingFooterMenu />
  </>
);

// eslint-disable-next-line import/no-default-export
export default Page;
