var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import groupBy from "lodash/groupBy";
import intersection from "lodash/intersection";
import sortBy from "lodash/sortBy";
import { EContactType } from "../../types/contact.types";
import { REDUCER_KEY_CONTACT } from "./contact.reducer";
import { EContactCustomId } from "./contact.types";
import * as contactUtils from "./contact.utils";
export var getContactMe = function (state) {
    return state.contact.data[EContactCustomId.Me];
};
export var getContactMeMaster = function (state) {
    return state.contact.data[EContactCustomId.MeMaster];
};
export var getContactSupport = function (state) {
    return state.contact.data[EContactCustomId.Support];
};
export var getContact = function (state, contactId, accountId) {
    if (contactId) {
        var contact = state.contact.data[contactId];
        if (contact) {
            return contact;
        }
    }
    if (accountId) {
        var contacts = Object.values(state.contact.data);
        var contactByMyAccountId = contacts.find(function (contact) { return (contact === null || contact === void 0 ? void 0 : contact.accountId) === accountId; });
        if (contactByMyAccountId) {
            return contactByMyAccountId;
        }
        var contactByAnyAccountId = contacts.find(function (contact) { return contact && (contact.accountIds || []).includes(accountId); });
        if (contactByAnyAccountId) {
            return contactByAnyAccountId;
        }
    }
    return;
};
export var getAllContacts = function (state) {
    return Object.values(state.contact.data).filter(function (contact) { return contact !== undefined; });
};
export var getContacts = function (state, params) {
    if (params === void 0) { params = {}; }
    var _a = params.includeMeMaster, includeMeMaster = _a === void 0 ? false : _a, _b = params.includeSupport, includeSupport = _b === void 0 ? false : _b, disallowedContactIds = params.disallowedContactIds, disallowedAccountIds = params.disallowedAccountIds, customContactIds = params.customContactIds;
    var contactIds = (customContactIds || state.contact.ids || [])
        .filter(function (contactId) { return !(disallowedContactIds || []).includes(contactId); })
        .filter(function (contactId) { return !(disallowedAccountIds || []).includes(contactId); });
    var contacts = contactIds
        .map(function (contactId) { return state.contact.data[contactId]; })
        .filter(function (contact) { return !!contact; });
    if (!includeSupport) {
        contacts = contacts.filter(function (contact) { return contact.type !== EContactType.Support; });
    }
    var contactMeMaster = getContactMeMaster(state);
    if (includeMeMaster && contactMeMaster) {
        return __spreadArray([contactMeMaster], contacts, true);
    }
    return contacts;
};
export var getFetchedContacts = function (state) {
    var contactIds = Object.keys(state.contact.data || {}).map(function (contactId) {
        return Number(contactId);
    });
    return getContacts(state, {
        includeMeMaster: true,
        includeSupport: true,
        customContactIds: contactIds,
    });
};
export var getContactIsFetching = function (state, contactId, accountId) {
    if (contactId) {
        var contactState = state.contact.contactsById[contactId];
        if (contactState === null || contactState === void 0 ? void 0 : contactState.isFetching) {
            return true;
        }
    }
    if (accountId) {
        var contactState = state.contact.contactsByAccountId[accountId];
        if (contactState === null || contactState === void 0 ? void 0 : contactState.isFetching) {
            return true;
        }
    }
    return false;
};
export var getContactDidFetch = function (state, accountId) {
    var contactState = state.contact.contactsByAccountId[accountId];
    if (contactState === null || contactState === void 0 ? void 0 : contactState.didFetch) {
        return true;
    }
    return false;
};
export var getContactFetchError = function (state, contactId, accountId) {
    if (contactId) {
        var contactState = state.contact.contactsById[contactId];
        if (contactState === null || contactState === void 0 ? void 0 : contactState.fetchError) {
            return contactState.fetchError;
        }
    }
    if (accountId) {
        var contactState = state.contact.contactsByAccountId[accountId];
        if (contactState === null || contactState === void 0 ? void 0 : contactState.fetchError) {
            return contactState.fetchError;
        }
    }
    return;
};
export var contactIsUpdating = function (state, contactId) {
    var updateState = state.contact.contactsById[contactId];
    if (updateState) {
        return updateState.isUpdating || false;
    }
    return false;
};
export var contactUpdateError = function (state, contactId) {
    var updateState = state.contact.contactsById[contactId];
    if (updateState) {
        return updateState.updateError;
    }
    return;
};
export var getContactsSectionListDataSource = function (state, params) {
    if (params === void 0) { params = {}; }
    var disallowedContactIds = params.disallowedContactIds, disallowedAccountIds = params.disallowedAccountIds;
    var contacts = getContacts(state, params);
    if (contacts.length === 0) {
        return;
    }
    if (disallowedContactIds) {
        contacts = contacts.filter(function (contact) { return !(disallowedContactIds || []).includes(contact.id); });
    }
    if (disallowedAccountIds) {
        contacts = contacts.filter(function (contact) {
            return intersection(disallowedAccountIds, contact.accountIds).length === 0;
        });
    }
    var groupedContacts = groupBy(contacts, function (_a) {
        var lastname = _a.lastname;
        return (lastname || " ").charAt(0).toLowerCase();
    });
    var sections = Object.keys(groupedContacts).map(function (letter) { return ({
        title: letter.toUpperCase(),
        data: sortBy(groupedContacts[letter], ["lastname", "firstname"]).map(function (contact) { return ({
            contactId: contact.id,
            contact: contact,
        }); }),
    }); });
    var sortedSections = sortBy(sections, "title");
    return sortedSections;
};
export var getIban = function (state, ibanId) {
    return Object.values(state.contact.ibans)
        .flat()
        .find(function (iban) { return iban.id === ibanId; });
};
export var getIbans = function (state, contactId, accountId) {
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return;
    }
    return state.contact.ibans[ibanId];
};
export var isFetchingIbans = function (state, contactId, accountId) {
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return false;
    }
    var ibanState = state.contact.ibansById[ibanId];
    if (!ibanState) {
        return false;
    }
    return ibanState.isFetching || false;
};
export var fetchIbanError = function (state, contactId, accountId) {
    var ibanId = contactUtils.getIbanId(contactId, accountId);
    if (!ibanId) {
        return false;
    }
    var ibanState = state.contact.ibansById[ibanId];
    if (!ibanState) {
        return false;
    }
    return ibanState.fetchError || false;
};
export var isCreatingIban = function (state) {
    return state.contact.isCreatingIban;
};
export var createIbanError = function (state) {
    return state.contact.createIbanError;
};
export var getIsAddingIbanForRentDeposit = function (state) {
    return state.contact.isAddingIbanForRentDepositPayout;
};
export var getAddIbanForRentDepositError = function (state) {
    return state.contact.addIbanForRentDepositPayoutError;
};
export var getContactNamesForAccountIds = function (state, accountIds, accounts) {
    if (accounts === void 0) { accounts = []; }
    var contacts = getFetchedContacts(state);
    var result = {};
    accountIds.forEach(function (accountId) {
        var contact = contacts.find(function (contact) {
            return (contact.accountIds || []).includes(accountId);
        });
        if (contact) {
            result[accountId] = contactUtils.getName(contact);
            return;
        }
        var account = accounts.find(function (randomAccount) { return accountId === randomAccount.id; });
        if (account) {
            result[accountId] = contactUtils.getName(account);
        }
    });
    return result;
};
export var getContactByCustomId = function (state, customId) {
    var contact = state.contact.data[customId];
    if (!contact) {
        return;
    }
    return state.contact.data[contact.id];
};
export var getContactsPaged = function (state, id, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.contact.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.ids) === null || _c === void 0 ? void 0 : _c.map(function (id) { return state.contact.data[id]; }).filter(Boolean);
};
export var isFetchingContactsForPage = function (state, id, page) { var _a, _b; return ((_b = (_a = state.contact.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) || false; };
export var propertiesForPageFetchError = function (state, id, page) { var _a, _b; return (_b = (_a = state.contact.paged[id]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
