var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from "reselect";
import { REDUCER_KEY_FORM } from "./form.reducer";
export var getFormData = function (state, formId) { var _a; return (_a = state.form[formId]) === null || _a === void 0 ? void 0 : _a.partialFormData; };
export var formSelector = function (state, formId) {
    return state.form[formId];
};
export var makeGetSchemas = function () {
    return createSelector([formSelector], function (form) {
        if (!form) {
            return;
        }
        return form.schemas;
    });
};
export var mergedPartialFormDataSelector = function (state, formId) {
    var form = state.form[formId];
    if (!form) {
        return;
    }
    return Object.values(form.partialFormData).reduce(function (accumulator, partialFormData) { return (__assign(__assign({}, accumulator), partialFormData)); }, {});
};
export var partialFormDataSelector = function (state, formId, stepIndex) {
    var form = state.form[formId];
    if (!form) {
        return;
    }
    var partialFormData = form.partialFormData[stepIndex];
    return partialFormData || {};
};
export var withSummarySelector = function (state, formId) {
    var form = state.form[formId];
    if (!form) {
        return false;
    }
    return form.withSummary;
};
