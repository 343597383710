var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from "polished";
import styled, { css } from "../../theme/styled-components";
// cspell:ignore khtml
export var WebWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-block;\n  position: relative;\n  cursor: pointer;\n  ", "\n  background-color: white;\n  border-radius: 100px;\n  border: 2px solid\n    ", ";\n  transition: all 250ms;\n\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n"], ["\n  display: inline-block;\n  position: relative;\n  cursor: pointer;\n  ", "\n  background-color: white;\n  border-radius: 100px;\n  border: 2px solid\n    ", ";\n  transition: all 250ms;\n\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -o-user-select: none;\n  user-select: none;\n"])), function (props) {
    return props.isDisabled
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          opacity: 0.5;\n          cursor: not-allowed;\n          pointer-events: none;\n        "], ["\n          opacity: 0.5;\n          cursor: not-allowed;\n          pointer-events: none;\n        "]))) : css(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
}, function (props) {
    return props.value
        ? transparentize(0.45, props.theme.colors.global5)
        : props.theme.colors.global3;
});
export var WebInnerWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 42px;\n  height: 22px;\n  border-radius: 100px;\n  background-color: ", ";\n  transition: all 250ms;\n"], ["\n  width: 42px;\n  height: 22px;\n  border-radius: 100px;\n  background-color: ", ";\n  transition: all 250ms;\n"])), function (props) {
    return props.value
        ? transparentize(0.5, props.theme.colors.global5)
        : props.theme.colors.global4;
});
export var WebIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: ", ";\n  right: ", ";\n  width: 22px;\n  height: 22px;\n  border-radius: 12px;\n  background-color: ", ";\n  box-shadow: 0px 0px 3px ", ";\n  transition: all 250ms;\n"], ["\n  position: absolute;\n  top: 0;\n  left: ", ";\n  right: ", ";\n  width: 22px;\n  height: 22px;\n  border-radius: 12px;\n  background-color: ", ";\n  box-shadow: 0px 0px 3px ", ";\n  transition: all 250ms;\n"])), function (props) { return (props.value ? "auto" : 0); }, function (props) { return (props.value ? 0 : "auto"); }, function (props) {
    return props.value ? props.theme.colors.global5 : props.theme.colors.global2;
}, transparentize(0.5, "black"));
export var WebImg = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  margin: 6px;\n  object-fit: contain;\n  opacity: 0.5;\n  transition: all 250ms;\n"], ["\n  width: 10px;\n  height: 10px;\n  margin: 6px;\n  object-fit: contain;\n  opacity: 0.5;\n  transition: all 250ms;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
