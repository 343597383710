var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getFieldInfo, getFieldTitle } from "../../../../redux/form/form.utils";
import { isAfter, isBefore } from "../../../../utils/date-fns.utils";
import { formatDate } from "../../../../utils/date.utils";
import { getLocalizedText } from "../../../../utils/i18n/i18n.utils";
import FormInputDate from "../FormInputDate";
var FormInputDateJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormInputDateJsonFormSchema, _super);
    function FormInputDateJsonFormSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        return _this;
    }
    // Render
    FormInputDateJsonFormSchema.prototype.render = function () {
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        var getMinDate = customUiSchema.getMinDate, getMaxDate = customUiSchema.getMaxDate, isDisabled = customUiSchema.isDisabled, rest = __rest(customUiSchema, ["getMinDate", "getMaxDate", "isDisabled"]);
        var allFormData = formContext.getAllFormData();
        var handleChangeValue = function (input) {
            onChange(input);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, input);
                }
            }, 0);
        };
        return (_jsx(FormInputDate
        // @ts-ignore TODO: Typescript upgrade
        , __assign({ 
            // @ts-ignore TODO: Typescript upgrade
            onPressExtraInfo: this.handlePressExtraInfo, 
            // @ts-ignore TODO: Typescript upgrade
            title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), value: formData, 
            // @ts-ignore TODO: Typescript upgrade
            onChangeValue: handleChangeValue, error: rawErrors[0], minDate: getMinDate ? getMinDate(formContext.getFormData()) : undefined, maxDate: getMaxDate ? getMaxDate(formContext.getFormData()) : undefined, disabled: isDisabled ? isDisabled(allFormData) : undefined }, rest)));
    };
    // Validation
    FormInputDateJsonFormSchema.validate = function (formData, errors, schema, uiSchema) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:date") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            var date = data;
            var getMinDate = fieldUiSchema.getMinDate;
            if (getMinDate) {
                var minDate = getMinDate(formData);
                if (isBefore(date, minDate)) {
                    fieldError.addError(getLocalizedText("system.form.error.invalid_date.too_early", {
                        inputDate: formatDate(date),
                        referenceDate: formatDate(minDate),
                    }));
                }
            }
            var getMaxDate = fieldUiSchema.getMaxDate;
            if (getMaxDate) {
                var maxDate = getMaxDate(formData);
                if (isAfter(date, maxDate)) {
                    fieldError.addError(getLocalizedText("system.form.error.invalid_date.too_late", {
                        inputDate: formatDate(date),
                        referenceDate: formatDate(maxDate),
                    }));
                }
            }
        });
    };
    return FormInputDateJsonFormSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputDateJsonFormSchema;
