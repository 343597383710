import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, createStore } from "redux";
import middlewares, { sagaMiddleware } from "./middlewares";
import reducers from "./reducers";
import sagas from "./sagas";

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(sagas);

// eslint-disable-next-line import/no-default-export
export default store;
