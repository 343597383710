import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const Wrap = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  margin-top: 1px;
`;
