import React from "react";
import { useInsuranceQueryParams } from "utils/url.utils";

export interface IProps {
  children?: React.ReactNode;
  items?: { [key: string]: string | number | Date | undefined };
}

export const RentioInternalRenderer: React.FC<IProps> = props => {
  const { items } = props;

  // Hooks
  const { internalMode } = useInsuranceQueryParams();

  // Render
  if (!internalMode) {
    return null;
  }

  return (
    <div
      style={{
        border: "2px solid cyan",
        backgroundColor: "rgba(0, 255, 255, 0.1)",
        padding: 4,
        margin: 4,
      }}
    >
      {props.children}

      {items
        ? Object.keys(items).map(key => {
            return (
              <div>
                {key}: {items[key] ? `${items[key]}` : "-"}
              </div>
            );
          })
        : null}
    </div>
  );
};
