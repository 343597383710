import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { EHelpInfoDisplayType } from "@rentiohq/shared-frontend/dist/components/components/FormHelpInfo";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { subYears } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.contactInfo.types";

const NOW = new Date();

const getFooterErrors = (formData: any) => {
  const result = [];

  if (
    formData[EField.ContactDamagePastYears] === "yes" &&
    (formData[EField.ContactDamagePastAmount] || 0) >= 3
  ) {
    result.push(getLocalizedText("insurance.contact_damage_past.amount.error"));
  }

  if (formData[EField.ContactDamagePastTermination] === "yes") {
    result.push(
      getLocalizedText("insurance.contact_damage_past.termination.error"),
    );
  }

  return result;
};

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [
        EField.ContactDamagePastYears,
        EField.ContactDamagePastTermination,
        EField.ContactFirstname,
        EField.ContactLastname,
        EField.ContactEmail,
        EField.ContactMobileNumber,
        EField.ContactDateOfBirth,
        // EField.ContactPassportNumber,
      ],
      properties: {
        [EField.Info2]: {
          default: undefined,
        },

        [EField.ContactFirstname]: {
          default: getValue(
            EField.ContactFirstname,
            initialValues,
            defaultValues,
          ),
        },

        [EField.ContactLastname]: {
          default: getValue(
            EField.ContactLastname,
            initialValues,
            defaultValues,
          ),
        },

        [EField.ContactEmail]: {
          default: getValue(EField.ContactEmail, initialValues, defaultValues),
        },

        [EField.ContactMobileNumber]: {
          default: getValue(
            EField.ContactMobileNumber,
            initialValues,
            defaultValues,
          ),
        },

        [EField.ContactDateOfBirth]: {
          default: getValue(
            EField.ContactDateOfBirth,
            initialValues,
            defaultValues,
          ),
        },

        // [EField.ContactPassportNumber]: {
        //   default: getValue(
        //     EField.ContactPassportNumber,
        //     initialValues,
        //     defaultValues,
        //   ),
        //   minLength: 10,
        // },

        [EField.Info1]: {
          default: undefined,
        },

        [EField.ContactDamagePastYears]: {
          default: getValue(
            EField.ContactDamagePastYears,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id.toLowerCase()}`),
          })),
        },

        [EField.ContactDamagePastAmount]: {
          default: getValue(
            EField.ContactDamagePastAmount,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [1, 2, 3].map(id => ({
            id,
            value: getLocalizedText(
              `insurance.contact_damage_past.amount.${id}`,
            ),
          })),
          showIf: (formData: any) =>
            formData[EField.ContactDamagePastYears] === "yes",
          requiredIf: (formData: any) =>
            formData[EField.ContactDamagePastYears] === "yes",
        },

        [EField.ContactDamagePastTermination]: {
          default: getValue(
            EField.ContactDamagePastTermination,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id.toLowerCase()}`),
          })),
        },

        [EField.ErrorFooter]: {
          default: undefined,
          // @ts-ignore
          showIf: (formDataParam: any) =>
            getFooterErrors(formDataParam).length > 0,
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  [EField.Info2]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("insurance.contact_info.title"),
  },

  [EField.ContactFirstname]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("system.firstname"),
  },

  [EField.ContactLastname]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("system.lastname"),
  },

  [EField.ContactEmail]: {
    "ui:field": ERentioFormField.Email,
    title: getLocalizedText("system.email"),
  },

  [EField.ContactMobileNumber]: {
    "ui:field": ERentioFormField.Phone,
    title: getLocalizedText("system.phone"),
  },

  [EField.ContactDateOfBirth]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("system.date_of_birth"),
    minDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MIN),
    maxDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MAX),
  },

  // [EField.ContactPassportNumber]: {
  //   "ui:field": ERentioFormField.Text,
  //   title: getLocalizedText("system.passport_or_national_registry_number"),
  //   placeholder: getLocalizedText(
  //     "system.passport_or_national_registry_number",
  //   ),
  // },

  [EField.Info1]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("insurance.contact_damage_past.info.title"),
  },

  [EField.ContactDamagePastYears]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("insurance.contact_damage_past.years"),
  },

  [EField.ContactDamagePastAmount]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("insurance.contact_damage_past.amount"),
  },

  [EField.ContactDamagePastTermination]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("insurance.contact_damage_past.termination"),
  },

  [EField.ErrorFooter]: {
    "ui:field": ERentioFormField.HelpInfo,
    showCloseButton: false,
    displayType: EHelpInfoDisplayType.Error,
    title: getLocalizedText("insurance.contact_damage_past.error.title"),
    getSubtitle: (_: any, formDataParam: any) =>
      getFooterErrors(formDataParam)
        .map((item, index) => `${index > 0 ? "\n" : ""}- ${item}`)
        .join(""),
  },
});

export const getValidate = () => (formData: any, errors: FormValidation) => {
  if (errors[EField.ErrorFooter] && getFooterErrors(formData)) {
    errors[EField.ErrorFooter].addError("-");
  }

  return errors;
};
