import { getQuoteBasePrice } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.utils";
import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { apiDateWithoutTimeToDate } from "utils/date.utils";

export const getPropertyInfoItems = (lead: ILead) => {
  const isHouse = lead.propertyType === EPropertyTypeId.House;
  const isApartment = lead.propertyType === EPropertyTypeId.Apartment;

  const result = [
    {
      id: getLocalizedText(
        "form.request_insurance.field.contract_start_date.summary",
      ),
      value: formatDate(lead.contractStartDate),
    },
  ];

  if (isHouse) {
    result.push({
      id: getLocalizedText(
        "form.request_insurance.field.building_adjoined.summary",
      ),
      value: getLocalizedText(
        `property.adjoined.option.${lead.propertyAdjoined}.label`.toLowerCase(),
      ),
    });
  }

  if (isApartment) {
    result.push({
      id: getLocalizedText(
        "form.request_insurance.field.building_surface_area.summary",
      ),
      value: `${lead.propertySurfaceArea} m²`,
    });
    // result.push({
    //   id: getLocalizedText(
    //     "form.request_insurance.field.building_floor.summary",
    //   ),
    //   value: `${lead.propertyFloor}`,
    // });
    result.push({
      id: getLocalizedText(
        "form.request_insurance.field.building_layers.summary",
      ),
      value: `${lead.propertyNumberOfFloors}`,
    });
  }

  return result;
};

export const getOptionsInfoItems = (quote?: IQuote) => {
  if (!quote) {
    return [];
  }

  return [
    {
      id: getLocalizedText("insurance.options.premium.base.title"),
      value: getLocalizedText("system.amount.yearly", {
        amount: formatCurrency(getQuoteBasePrice(quote)),
      }),
    },
    {
      id: getLocalizedText("insurance.options.premium.content.title"),
      valueAsError: !quote.optionContentPremium,
      value:
        quote.optionContentPremium && quote.optionContentPremiumValue
          ? getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.optionContentPremiumValue),
            })
          : getLocalizedText("insurance.options.not_insured"),
      extraInfo:
        quote.optionContentPremium && quote.optionContentPremiumAmountOfContent
          ? formatCurrency(quote.optionContentPremiumAmountOfContent, false)
          : undefined,
    },
    {
      id: getLocalizedText("insurance.options.premium.theft.title"),
      valueAsError: !quote.optionTheftPremium,
      value:
        quote.optionTheftPremium && quote.optionTheftPremiumValue
          ? getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.optionTheftPremiumValue),
            })
          : getLocalizedText("insurance.options.not_insured"),
    },
    {
      id: getLocalizedText("insurance.options.premium.motor_vehicles.title"),
      valueAsError: !quote.optionMotorVehiclesPremium,
      value:
        quote.optionMotorVehiclesPremium &&
        quote.optionMotorVehiclesPremiumValue
          ? getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.optionMotorVehiclesPremiumValue),
            })
          : getLocalizedText("insurance.options.not_insured"),
      extraInfo:
        quote.optionMotorVehiclesPremium &&
        quote.optionMotorVehiclesPremiumAmountOfMotorVehicles
          ? `${getLocalizedText(
              "insurance.options.premium.motor_vehicles.motor_vehicles_amount",
            )}: ${
              quote.optionMotorVehiclesPremiumAmountOfMotorVehicles
            }`.toLowerCase()
          : undefined,
    },
    {
      id: getLocalizedText("insurance.options.premium.legal_assistance.title"),
      valueAsError: !quote.optionLegalAssistancePremium,
      value:
        quote.optionLegalAssistancePremium &&
        quote.optionLegalAssistancePremiumValue
          ? getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.optionLegalAssistancePremiumValue),
            })
          : getLocalizedText("insurance.options.not_insured"),
    },
  ];
};

export const getContactInfoItems = (lead: ILead) => {
  const result = [];

  // Contact info
  result.push({
    id: getLocalizedText("system.firstname"),
    value: lead.contactFirstname,
  });

  result.push({
    id: getLocalizedText("system.lastname"),
    value: lead.contactLastname,
  });

  result.push({
    id: getLocalizedText("system.email"),
    value: lead.contactEmail,
  });

  result.push({
    id: getLocalizedText("system.phone"),
    value: lead.contactMobileNumber,
  });

  result.push({
    id: getLocalizedText("system.date_of_birth"),
    value: lead.contactDateOfBirth
      ? formatDate(apiDateWithoutTimeToDate(lead.contactDateOfBirth))
      : "-",
  });

  // result.push({
  //   id: getLocalizedText("system.passport_or_national_registry_number"),
  //   value: lead.contactNationalNumber,
  // });

  // Damage past
  const { contactDamageHistory = 0 } = lead;

  if (contactDamageHistory === 0) {
    result.push({
      id: getLocalizedText("insurance.contact_damage_past_years.summary"),
      value: getLocalizedText("system.no"),
    });
  }

  if (contactDamageHistory > 0) {
    result.push({
      id: getLocalizedText("insurance.contact_damage_past_amount.summary"),
      value: `${contactDamageHistory}`,
    });
  }

  result.push({
    id: getLocalizedText("insurance.contact_damage_past_termination.summary"),
    value: getLocalizedText("system.no"),
  });

  return result;
};
