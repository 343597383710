var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import uniq from "lodash/uniq";
import React from "react";
import ButtonPrimary from "../ButtonPrimary";
import { ContactFetchListItem } from "../ContactFetchListItem";
import ContactListItem from "../ContactListItem/ContactListItem";
import FormInputBase from "../FormInputBase";
import Spacer, { ESpacerWeight } from "../Spacer";
import * as S from "./FormContactSelectInline.styles";
var FormContactSelectInline = /** @class */ (function (_super) {
    __extends(FormContactSelectInline, _super);
    function FormContactSelectInline() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderAccount = function (account) {
            var _a = _this.props, getSubtitle = _a.getSubtitle, _b = _a.selectedAccountIds, selectedAccountIds = _b === void 0 ? [] : _b;
            return (_jsx(ContactListItem, { account: account, subtitle: getSubtitle({ accountId: account.id, account: account }), selected: selectedAccountIds.includes(account.id), onPress: _this.handlePressAccount(account.id) }));
        };
        _this.renderContact = function (contact) {
            var _a = _this.props, getSubtitle = _a.getSubtitle, _b = _a.extraAccounts, extraAccounts = _b === void 0 ? [] : _b, _c = _a.selectedAccountIds, selectedAccountIds = _c === void 0 ? [] : _c;
            var item = { contact: contact };
            var account = extraAccounts.find(function (randomAccount) {
                return contact.accountIds.includes(randomAccount.id);
            });
            if (account) {
                item.accountId = account.id;
                item.account = account;
            }
            var selected = !!contact.accountIds.find(function (randomAccountId) {
                return selectedAccountIds.includes(randomAccountId);
            });
            return (_jsx(ContactListItem, { contact: contact, subtitle: getSubtitle(item), selected: selected, onPress: account
                    ? _this.handlePressAccount(account.id)
                    : _this.handlePressContact(contact) }));
        };
        // Event handlers
        _this.handlePressAccount = function (accountId) { return function () {
            var _a = _this.props, _b = _a.selectedAccountIds, selectedAccountIds = _b === void 0 ? [] : _b, onDeselectAccount = _a.onDeselectAccount, onSelectAccount = _a.onSelectAccount;
            if (selectedAccountIds.includes(accountId)) {
                onDeselectAccount(accountId);
                return;
            }
            onSelectAccount(accountId);
        }; };
        _this.handlePressContact = function (contact) { return function () {
            _this.handlePressAccount(contact.accountId)();
        }; };
        _this.handlePressSelectMoreContacts = function () {
            _this.props.onPressSelectMoreContacts(_this.props.getSubtitle, _this.props.extraAccounts);
        };
        return _this;
    }
    // Lifecycle methods
    FormContactSelectInline.prototype.componentWillMount = function () {
        this.props.getContacts();
    };
    // Render
    FormContactSelectInline.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, selectContactFromListCtaTitle = _a.selectContactFromListCtaTitle, emptySelectCtaLabel = _a.emptySelectCtaLabel, error = _a.error, optional = _a.optional, disabled = _a.disabled, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, _c = _a.extraAccounts, extraAccounts = _c === void 0 ? [] : _c, _d = _a.selectedAccountIds, selectedAccountIds = _d === void 0 ? [] : _d, isFetching = _a.isFetching, info = _a.info;
        var accountIdsToRender = uniq(__spreadArray(__spreadArray([], extraAccounts.map(function (account) { return account.id; }), true), selectedAccountIds, true));
        var showExtraButton = (accountIdsToRender && accountIdsToRender.length > 0) || isFetching;
        return (_jsxs(FormInputBase, { title: title, info: info, error: error, optional: optional, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, extraButtonTitle: showExtraButton && !readOnly
                ? selectContactFromListCtaTitle
                : undefined, onPressExtraButton: this.handlePressSelectMoreContacts, disabled: disabled || readOnly, isFetching: isFetching, children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), (function () {
                    if (accountIdsToRender && accountIdsToRender.length > 0) {
                        return accountIdsToRender.map(function (accountId, index) {
                            var account = extraAccounts.find(function (randomAccount) { return accountId === randomAccount.id; });
                            return (_jsxs(React.Fragment, { children: [index !== 0 && _jsx(S.Separator, {}), _jsx(ContactFetchListItem, { account: account, accountId: accountId, renderAccount: _this.renderAccount, renderContact: _this.renderContact, extraData: _this.props })] }, index));
                        });
                    }
                    return (_jsx(S.EmptyButtonWrap, { children: _jsx(ButtonPrimary, { title: emptySelectCtaLabel || selectContactFromListCtaTitle, onPress: _this.handlePressSelectMoreContacts, isInverted: true }) }));
                })()] }));
    };
    return FormContactSelectInline;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormContactSelectInline;
