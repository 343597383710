import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";

export interface IExtraData {
  lead: ILead;
  publicId: string;
}

export enum EField {
  Info = "_info",

  PropertyStreet = "propertyStreet",
  PropertyNumber = "propertyNumber",
  PropertyBox = "propertyBox",
  PropertyZipCode = "propertyZipCode",
  PropertyCity = "propertyCity",
}
