var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CONFIG } from "../../../config/app.config";
import * as S from "../FormInputBase/FormInputBase.styles";
import Spacer, { ESpacerWeight } from "../Spacer";
var FormInputCaptcha = /** @class */ (function (_super) {
    __extends(FormInputCaptcha, _super);
    function FormInputCaptcha() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    FormInputCaptcha.prototype.render = function () {
        var _a = this.props, onChangeValue = _a.onChangeValue, error = _a.error;
        return (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), CONFIG.CAPTCHA_KEY && (_jsx(ReCAPTCHA, { sitekey: CONFIG.CAPTCHA_KEY, onChange: function (value) {
                        onChangeValue(value || undefined);
                    } })), error && _jsx(S.ErrorText, { children: error })] }));
    };
    return FormInputCaptcha;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputCaptcha;
