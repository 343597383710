import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutral1};
`;

export const Info = styled.span`
  margin-top: 8px;
  font-size: 14px;
  color: ${props => props.theme.colors.global1};
  line-height: 19px;
`;
