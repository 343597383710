import ASSETS_UNMAPPED from "./assets";

// @ts-ignore
const assetsMapped: Record<keyof typeof ASSETS_UNMAPPED, any> = {};

Object.keys(ASSETS_UNMAPPED).forEach(keyAsString => {
  const key = keyAsString as keyof typeof ASSETS_UNMAPPED;
  const asset = ASSETS_UNMAPPED[key];
  assetsMapped[key] = asset?.default || asset;
});

// eslint-disable-next-line import/no-default-export
export default assetsMapped;
