export var ENTITY = "property";
export var REDUCER_KEY = "PROPERTY_V2";
export var BASE_PATH_ENTITY = "properties";
export var GENERATOR_PARAMS = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    defaultSort: [
        { field: "parentId" },
        { field: "street" },
        { field: "number" },
        { field: "box" },
        { field: "zip" },
        { field: "city" },
        { field: "id" },
    ],
};
