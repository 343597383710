import { connect } from "react-redux";
import FormContactSelectInline from "./FormContactSelectInline";
var mapStateToProps = function (state, props) { return ({
    getSubtitle: function (item) {
        return props.customGetSubtitleSelector(state, props.formData, item);
    },
    extraAccounts: props.customExtraAccountsSelector(state, props.formData),
    isFetching: props.customIsFetchingSelector(state),
}); };
var mapDispatchToProps = function (dispatch, props) { return ({
    getContacts: function () {
        (props.customGetContactsActions || []).forEach(function (action) {
            dispatch(action());
        });
    },
}); };
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(FormContactSelectInline);
