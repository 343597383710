var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Text, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  flex-direction: column;\n  background-color: ", ";\n  opacity: ", ";\n"], ["\n  width: 100%;\n  flex-direction: column;\n  background-color: ", ";\n  opacity: ", ";\n"])), function (props) {
    return props.isActive ? props.theme.colors.global5 : "transparent";
}, function (props) { return (props.disabled ? 0.5 : 1); });
export var Title = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  margin: ", ";\n  color: ", ";\n  ", ";\n"], ["\n  font-size: ", ";\n  margin: ", ";\n  color: ", ";\n  ", ";\n"])), function (props) { return (props.isTitle ? "18px" : "14px"); }, function (props) { return (props.isTitle ? "10px 0px 2px 0px" : "8px 0px 0px 6px"); }, function (props) {
    return props.isTitle ? props.theme.colors.global1 : props.theme.colors.global2;
}, desktopStyles(css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: ", ";\n    color: ", ";\n  "], ["\n    font-size: ", ";\n    color: ", ";\n  "])), function (props) { return (props.isTitle ? "20px" : "16px"); }, function (props) {
    if (props.isActive) {
        return props.theme.colors.global5;
    }
    return props.isTitle ? "rgb(33, 47, 46)" : "rgb(142, 142, 147)";
})));
export var Content = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 6px;\n"], ["\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 6px;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n"], ["\n  width: 100%;\n  height: 48px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
