import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { formatAddress } from "utils/lead.utils";
import * as S from "./PropertyInfoBanner.styles";

interface IProps {
  lead: ILead;
  displayMobileViewSameAsDesktop?: boolean;
}

const PropertyInfoBanner: React.FC<IProps> = (props: IProps) => {
  const { lead, displayMobileViewSameAsDesktop = false } = props;

  // Render
  const isApartment = props.lead.propertyType === 3;
  const propertyTypeKeySuffix = isApartment ? "apartment" : "home";

  return (
    <S.Wrap displayMobileViewSameAsDesktop={displayMobileViewSameAsDesktop}>
      <S.PropertyLogo lead={lead} />
      <S.InfoWrap>
        <S.Title>{formatAddress(lead)}</S.Title>
        <S.Subtitle>
          {getLocalizedText(`property.type.${propertyTypeKeySuffix}`)}
        </S.Subtitle>
      </S.InfoWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyInfoBanner;
