import ButtonPrimary from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import { FullScreenErrorState } from "@rentiohq/shared-frontend/dist/components/components/FullScreenState";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { AxiosError } from "axios";
import InfoList from "components/InfoList";
import HttpStatus from "http-status-codes";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./ErrorLead.styles";

interface IProps {
  errorLead?: Error;
  errorQuote?: Error;
  displayCompact?: boolean;
}

const ErrorLead: React.FC<IProps> = (props: IProps) => {
  const { errorLead, displayCompact = false } = props;

  // Router
  const navigate = useNavigate();

  // Event handlers
  const handlePressBack = () => {
    navigate("/");
  };

  // Render
  let customError = getLocalizedText("general.error");
  let isExpired = false;
  if (errorLead) {
    customError = getMessageForError(errorLead);

    const axiosErrorLead = errorLead as AxiosError;
    if (
      axiosErrorLead.isAxiosError &&
      axiosErrorLead.response?.status === HttpStatus.FORBIDDEN
    ) {
      customError = getLocalizedText("insurance.lead.error.expired");
      isExpired = true;
    }
  }

  return (
    <S.Wrap>
      {displayCompact ? (
        <S.CompactErrorTitle>{customError}</S.CompactErrorTitle>
      ) : (
        <S.ErrorStateWrap>
          <FullScreenErrorState title={customError} />
        </S.ErrorStateWrap>
      )}
      {isExpired && (
        <>
          <InfoList
            title={getLocalizedText("insurance.lead.invalid.title")}
            info={getLocalizedText("insurance.lead.invalid.info")}
          />
          <Spacer weight={ESpacerWeight.W24} />
        </>
      )}
      {!displayCompact && (
        <ButtonPrimary
          title={getLocalizedText("system.go_back")}
          onPress={handlePressBack}
        />
      )}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default ErrorLead;
