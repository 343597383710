import styled2 from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import { AlertType } from "react-alert";
import { Text, TouchableOpacity } from "react-native";

interface IWithType {
  type?: AlertType;
}

export const Wrap = styled(TouchableOpacity)<IWithType>`
  width: 300px;
  flex-direction: row;
  align-items: center;
  background-color: ${props => {
    switch (props.type) {
      case "error":
        return props.theme.colors.error;

      case "success":
        return props.theme.colors.success;

      case "info":
      default:
        return props.theme.colors.info;
    }
  }};
`;

export const CloseButton = styled(TouchableOpacity)`
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
`;

export const CloseButtonImage = styled2.img`
  width: 32px;
  height: 32px;
`;

export const Message = styled(Text)<IWithType>`
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  letter-spacing: -0.15px;
  line-height: 20px;
  padding-right: 16px;
  color: ${props => {
    switch (props.type) {
      case "error":
        return props.theme.colors.errorText;

      case "success":
        return props.theme.colors.successText;

      case "info":
      default:
        return props.theme.colors.infoText;
    }
  }};
`;
