import ListItem from "@rentiohq/shared-frontend/dist/components/components/ListItem";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import * as i18nActions from "@rentiohq/shared-frontend/dist/redux/i18n/i18n.actions";
import * as leadApi from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.api";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import {
  getLocale,
  getLocalizedText,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import React from "react";
import { useDispatch } from "react-redux";
import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from "utils/styles.utils";
import { useInsuranceQueryParams } from "utils/url.utils";
import * as S from "./FloatingFooterMenu.styles";

interface IProps {}

const FloatingFooterMenu: React.FC<IProps> = (_: IProps) => {
  // Refs
  const wrap = React.useRef(null);

  // Redux
  const dispatch = useDispatch();
  const { leadId, publicId } = useInsuranceQueryParams();

  // State
  const [isShowingLanguageMenu, setShowLanguageMenu] = React.useState(false);

  // Event listeners
  React.useEffect(() => {
    document.addEventListener("mousedown", handleMousedown);

    return () => {
      document.removeEventListener("mousedown", handleMousedown);
    };
  }, []);

  // Event handlers
  const handleMousedown = (event: any) => {
    // @ts-ignore
    if (!wrap?.current.contains(event.target)) {
      setShowLanguageMenu(false);
    }
  };

  // Replaced by chatbot on site
  // @ts-ignore
  const handleClickHelp = () => {
    const url = `mailto:${getLocalizedText("insurance.email.support")}`;
    window.open(url, "_blank");
  };

  const handleToggleLanguageMenu = () => {
    setShowLanguageMenu(!isShowingLanguageMenu);
  };

  const handleClickLocale = (locale: ELocale) => async () => {
    if (leadId && publicId && locale !== ELocale.INTERNAL) {
      try {
        await leadApi.updateLead(leadId, { locale }, publicId);
      } catch (error) {
        // console.warn("could not update locale: ", { locale, error });
      }
    }

    setShowLanguageMenu(false);

    dispatch(i18nActions.setLocale(locale));
  };

  // Render
  const renderInternalLocaleListItem = () => {
    if (CONFIG.ENVIRONMENT !== EEnvironment.Dev) {
      return null;
    }

    return (
      <>
        <ListItemSeparator />
        <ListItem
          title={"Keys"}
          onPress={handleClickLocale(ELocale.INTERNAL)}
          isActive={ELocale.INTERNAL === getLocale()}
        />
      </>
    );
  };

  const renderLanguageMenu = () => {
    if (!isShowingLanguageMenu) {
      return;
    }

    const supportLocales =
      CONFIG.ENVIRONMENT === EEnvironment.Dev
        ? [ELocale.NL, ELocale.FR, ELocale.EN]
        : [ELocale.NL, ELocale.FR];

    return (
      <S.Menu>
        <S.MenuHeader>
          {getLocalizedText("system.locale").toUpperCase()}
        </S.MenuHeader>
        <ListItemSeparator />
        {supportLocales.map((locale, index) => (
          <React.Fragment key={locale}>
            {index > 0 && <ListItemSeparator />}
            <ListItem
              title={getLocalizedText(`system.locale.${locale}`)}
              onPress={handleClickLocale(locale)}
              isActive={locale === getLocale()}
            />
          </React.Fragment>
        ))}
        {renderInternalLocaleListItem()}
      </S.Menu>
    );
  };

  return (
    <S.Wrap ref={wrap}>
      <S.InnerWrap>
        {/* <ShowOnlyOnMobile>
          <S.FooterButton onClick={handleClickHelp}>
            <S.FooterButtonIcon src={assets.ICO_HELP_BRAND_COLOR} />
          </S.FooterButton>
        </ShowOnlyOnMobile> */}

        {/* <ShowOnlyOnDesktop>
          <S.FooterButton onClick={handleClickHelp}>
            <S.FooterButtonIcon src={assets.ICO_HELP_WHITE} />
            {getLocalizedText("help_menu.heading")}
          </S.FooterButton>
        </ShowOnlyOnDesktop> */}

        <ShowOnlyOnMobile>
          <S.FooterButton onClick={handleToggleLanguageMenu}>
            <S.FooterButtonIcon src={assets.ICO_CIRCLE} />
            <S.LanguageSwitcherTitle>
              {getLocalizedText("system.locale.short_key")}
            </S.LanguageSwitcherTitle>
            {renderLanguageMenu()}
          </S.FooterButton>
        </ShowOnlyOnMobile>

        <ShowOnlyOnDesktop>
          <S.FooterButton onClick={handleToggleLanguageMenu}>
            {getLocalizedText("system.locale.short_key")}
            {renderLanguageMenu()}
          </S.FooterButton>
        </ShowOnlyOnDesktop>
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default FloatingFooterMenu;
