import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { setLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { connect } from "react-redux";
import { IRootState } from "../redux/reducers";

interface IProps {
  children?: React.ReactNode;
}

interface IStateProps {
  locale?: ELocale;
}

type TProps = IStateProps & IProps;

class I18nProvider extends React.PureComponent<TProps> {
  // Initialize
  constructor(props: TProps) {
    super(props);

    if (props.locale) {
      setLocale(props.locale);
    }
  }

  // Lifecycle methods
  public UNSAFE_componentWillReceiveProps(nextProps: TProps) {
    if (nextProps.locale && nextProps.locale !== this.props.locale) {
      window.location.reload();
    }
  }

  // Lifecycle methods
  public render() {
    return this.props.children;
  }
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  locale: state.i18n.locale,
});

// eslint-disable-next-line import/no-default-export
export default connect<IStateProps, {}, IProps, IRootState>(mapStateToProps)(
  I18nProvider,
);
