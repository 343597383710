import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useActivePayment } from "@rentiohq/shared-frontend/dist/reduxInsurance/payment/payment.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { ELeadStatus } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import QuoteInfoBanner from "components/QuoteInfoBanner";
import { getSteps } from "modules/request/config/progressBar.config";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate } from "react-router-dom";
import { SubmitButton } from "utils/styles.utils";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import ProgressBar from "../../components/ProgressBar";
import * as S from "./Payment.styles";

interface IProps {}

const Payment: React.FC<IProps> = (_: IProps) => {
  // Custom hooks
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();

  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
  } = useGetLead({
    leadId,
    publicId,
  });

  const {
    quote,
    isFetching: isFetchingQuote,
    fetchError: fetchQuoteError,
  } = useGetQuote({
    leadId,
    quoteId,
    publicId,
  });

  const {
    activePayment,
    isFetching: isFetchingActivePayment,
    createPayment,
  } = useActivePayment({
    leadId,
    quote,
    publicId,
  });

  // Lifecycle
  React.useEffect(() => {
    if (activePayment) {
      return;
    }

    createPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  // Event handlers
  const handlePressPay = () => {
    if (!activePayment?.mollieUrl) {
      return;
    }

    window.open(activePayment.mollieUrl, "_self");
  };

  // Render
  if (!leadId || !quoteId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetchingLead && !isFetchingQuote && !isFetchingActivePayment) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchLeadError && !fetchQuoteError) {
      return;
    }

    return (
      <ErrorLead errorLead={fetchLeadError} errorQuote={fetchQuoteError} />
    );
  };

  const renderContent = () => {
    if (!lead || !quote || !activePayment) {
      return;
    }

    switch (lead.status) {
      case ELeadStatus.Paid:
      case ELeadStatus.SentToBaloise:
      case ELeadStatus.ProcessedByBaloise:
        return (
          <Navigate
            to={appendQueryParams({
              path: "/request/success",
              queryParams: {
                leadId,
                quoteId,
                publicId,
              },
            })}
          />
        );

      default:
        break;
    }

    return (
      <>
        <ProgressBar steps={getSteps()} currentStep={5} />

        <Spacer weight={ESpacerWeight.W32} />

        <S.Title>{getLocalizedText("insurance.payment.title")}</S.Title>

        <Spacer weight={ESpacerWeight.W16} />

        <S.NextStepsInfo>
          {getLocalizedText("insurance.payment.info")}
        </S.NextStepsInfo>

        <Spacer weight={ESpacerWeight.W32} />

        <QuoteInfoBanner lead={lead} quote={quote} showDetailedInfo={true} />

        <Spacer weight={ESpacerWeight.W32} />

        <SubmitButton
          title={getLocalizedText("insurance.payment.cta.pay")}
          onPress={handlePressPay}
        />
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        {renderContent() ||
          renderIsFetching() ||
          renderError() ||
          renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Payment;
