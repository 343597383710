export var EQuoteStatus;
(function (EQuoteStatus) {
    EQuoteStatus["New"] = "new";
    EQuoteStatus["Signing"] = "signing";
    EQuoteStatus["Signed"] = "signed";
    EQuoteStatus["Paying"] = "paying";
    EQuoteStatus["Paid"] = "paid";
})(EQuoteStatus || (EQuoteStatus = {}));
export var EQuoteCalculationMethod;
(function (EQuoteCalculationMethod) {
    EQuoteCalculationMethod["Mock"] = "Mock";
    EQuoteCalculationMethod["Baloise"] = "Baloise";
})(EQuoteCalculationMethod || (EQuoteCalculationMethod = {}));
