import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import React from "react";
import * as S from "./Footer.styles";

interface ILinkSectionItem {
  icon: string | undefined;
  title: string;
  content?: string;
  url?: string;
}

interface ILinkSection {
  title: string;
  items: ILinkSectionItem[];
}

interface IProps {}

const Footer: React.FC<IProps> = (_: IProps) => {
  const LINK_SECTIONS: ILinkSection[] = [
    {
      title: getLocalizedText("insurance.footer.q_and_a.title"),
      items: [
        {
          icon: assets.ICO_DROPDOWN,
          title: getLocalizedText("insurance.footer.q_and_a.claim.q"),
          content: getLocalizedText("insurance.footer.q_and_a.claim.a"),
        },
        {
          icon: assets.ICO_DROPDOWN,
          title: getLocalizedText("insurance.footer.q_and_a.who.q"),
          content: getLocalizedText("insurance.footer.q_and_a.who.a"),
        },
        {
          icon: assets.ICO_DROPDOWN,
          title: getLocalizedText("insurance.footer.q_and_a.monthly.q"),
          content: getLocalizedText("insurance.footer.q_and_a.monthly.a"),
        },
        {
          icon: assets.ICO_DROPDOWN,
          title: getLocalizedText("insurance.footer.q_and_a.questions.q"),
          content: getLocalizedText("insurance.footer.q_and_a.questions.a"),
        },
      ],
    },

    {
      title: getLocalizedText("insurance.footer.legal.title"),
      items: [
        {
          icon: assets.ICO_FILE_PDF,
          title: getLocalizedText("insurance.footer.legal.terms.title"),
          url: getLocalizedText("insurance.footer.terms.cta.terms.link"),
        },
        {
          icon: assets.ICO_FILE_PDF,
          title: getLocalizedText("insurance.footer.legal.info.title"),
          url: getLocalizedText("insurance.footer.legal.info.url"),
        },
        {
          icon: assets.ICO_FILE_PDF,
          title: getLocalizedText("insurance.footer.legal.criteria.title"),
          url: getLocalizedText("insurance.footer.legal.criteria.url"),
        },
      ],
    },

    {
      title: getLocalizedText("insurance.footer.contact.title"),
      items: [
        {
          icon: assets.ICO_PHONE,
          title: getLocalizedText("insurance.footer.contact.phone.title"),
          url: getLocalizedText("insurance.footer.contact.phone.url"),
        },
        {
          icon: assets.ICO_MAIL,
          title: getLocalizedText("insurance.footer.contact.email.title"),
          url: getLocalizedText("insurance.footer.contact.email.url"),
        },
      ],
    },
  ];

  // State
  const [activeDropdownIndex, setActiveDropdownIndex] = React.useState<
    string | undefined
  >();

  // Event handlers
  const handleClickItem =
    (item: ILinkSectionItem, dropdownIndex: string) => () => {
      if (item.content) {
        if (dropdownIndex === activeDropdownIndex) {
          setActiveDropdownIndex(undefined);
          return;
        }

        setActiveDropdownIndex(dropdownIndex);
        return;
      }

      if (item.url) {
        window.open(item.url, "_blank");
        return;
      }
    };

  // Render
  return (
    <>
      <S.Border />
      <S.BackgroundWrap>
        <S.Wrap>
          <S.InnerWrap>
            <S.LinkSectionsWrap>
              {LINK_SECTIONS.map((section, sectionIndex) => (
                <S.LinkSectionWrap key={section.title}>
                  <S.LinkSectionTitle>{section.title}</S.LinkSectionTitle>
                  {section.items.map((item, itemIndex) => {
                    const dropdownIndex = `${sectionIndex}-${itemIndex}`;
                    const isDropdownActive =
                      dropdownIndex === activeDropdownIndex;

                    return (
                      <React.Fragment key={item.title}>
                        <S.LinkSectionLink
                          key={item.title}
                          onClick={handleClickItem(item, dropdownIndex)}
                        >
                          {item.icon && (
                            <S.LinkSectionLinkIcon
                              src={item.icon}
                              isDropdownActive={isDropdownActive}
                            />
                          )}
                          <S.LinkSectionLinkTitle
                            dangerouslySetInnerHTML={{
                              __html: getLocalizedText(item.title),
                            }}
                          />
                        </S.LinkSectionLink>
                        {isDropdownActive && item.content && (
                          <S.LinkSectionLinkContent
                            dangerouslySetInnerHTML={{
                              __html: getLocalizedText(item.content),
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </S.LinkSectionWrap>
              ))}
            </S.LinkSectionsWrap>

            <S.LegalInfo>
              {getLocalizedText("insurance.footer.info")}
            </S.LegalInfo>

            <S.LegalLinksTitle>
              {getLocalizedText("insurance.footer.terms.title")}
            </S.LegalLinksTitle>
            <S.LegalLinksWrap>
              <S.LegalLink
                href={getLocalizedText("insurance.footer.terms.cta.terms.link")}
              >
                {getLocalizedText("insurance.footer.terms.cta.terms")}
              </S.LegalLink>
              <span>-</span>
              <S.LegalLink
                href={getLocalizedText(
                  "insurance.footer.terms.cta.data_protection.link",
                )}
              >
                {getLocalizedText("insurance.footer.terms.cta.data_protection")}
              </S.LegalLink>
            </S.LegalLinksWrap>
          </S.InnerWrap>
        </S.Wrap>
      </S.BackgroundWrap>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Footer;
