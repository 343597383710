var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Platform, Text, TextInput, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var TextInputWrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex-direction: row;\n  align-items: center;\n"])));
export var TextInputStyled = styled(TextInput)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  padding: 16px;\n  font-size: 14px;\n  color: ", ";\n  ", "\n"], ["\n  flex: 1;\n  padding: 16px;\n  font-size: 14px;\n  color: ", ";\n  ", "\n"])), function (props) { return props.theme.colors.neutral1; }, function (props) {
    return Platform.select({
        web: "\n        placeholder-text-color: #B9B9B9;\n        caret-color: ".concat(props.theme.colors.neutral1, ";\n        ::placeholder {\n          color: #B9B9B9;\n        }\n      "),
    });
});
export var SuffixText = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-right: 16px;\n  color: ", ";\n"], ["\n  font-size: 14px;\n  margin-right: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
