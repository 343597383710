import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { EQuoteStatus } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { useNextStepNavigation } from "modules/request/navigation.utils";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate } from "react-router-dom";
import { useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import * as S from "./Pending.styles";

interface IProps {}

const Pending: React.FC<IProps> = (_: IProps) => {
  // Custom hooks
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();

  const { fetchError: fetchLeadError } = useGetLead({
    leadId,
    publicId,
  });

  const {
    quote,
    fetchError: fetchQuoteError,
    fetchQuote,
  } = useGetQuote({
    leadId,
    quoteId,
    publicId,
  });
  const prevQuote = usePrevious(quote);

  const { navigateToNextStep } = useNextStepNavigation({
    leadId,
    quote,
    publicId,
  });

  // Lifecycle
  React.useEffect(() => {
    const interval = setInterval(fetchQuote, 2000);
    return () => clearInterval(interval);
  }, [fetchQuote]);

  React.useEffect(() => {
    if (!quote) {
      return;
    }

    if (quote.status === prevQuote?.status) {
      return;
    }

    switch (quote.status) {
      case EQuoteStatus.Signed:
      case EQuoteStatus.Paid:
        navigateToNextStep();
        break;

      case EQuoteStatus.New:
      case EQuoteStatus.Paying:
      case EQuoteStatus.Signing:
      default:
        break;
    }
  }, [navigateToNextStep, quote, prevQuote]);

  // Render
  if (!leadId || !quoteId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderError = () => {
    if (!fetchLeadError && !fetchQuoteError) {
      return;
    }

    return (
      <ErrorLead errorLead={fetchLeadError} errorQuote={fetchQuoteError} />
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>{renderError() || renderLoading()}</S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Pending;
