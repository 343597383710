import FormHelpInfo, {
  EHelpInfoDisplayType,
} from "@rentiohq/shared-frontend/dist/components/components/FormHelpInfo";
import FormSectionHeader from "@rentiohq/shared-frontend/dist/components/components/FormSectionHeader";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as formActions from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as leadApi from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.api";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { ELeadStatus } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import {
  getLocale,
  getLocalizedText,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import FormMultistep from "components/FormMultistep";
import PropertyInfoBanner from "components/PropertyInfoBanner";
import React from "react";
import { ActivityIndicator } from "react-native";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import schemas from "../../forms/addressMatcher";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "../../forms/addressMatcher/schema.addressMatcher.actions";
import * as S from "./AddressMatcher.styles";

interface IProps {}

const AddressMatcher: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();
  const { lead, isFetching, fetchError, storeUpdatedLead } = useGetLead({
    leadId,
    publicId,
  });

  // Redux
  const dispatch = useDispatch();

  // Form
  const [formId] = React.useState(generateFormId());
  const [isFormInitialized, setIsFormInitialized] = React.useState(false);

  // State
  const [showLoading, setShowLoading] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);

  // Lifecycle
  React.useEffect(() => {
    if (!lead || isFormInitialized) {
      return;
    }

    dispatch(
      formActions.createForm({
        formId,
        // @ts-ignore
        schemas: schemas({ lead, publicId }),
        withSummary: false,
      }),
    );

    setIsFormInitialized(true);
  }, [formId, isFormInitialized, lead, publicId]);

  // Event handlers
  const handleSuccess = async () => {
    if (!leadId || !publicId) {
      return;
    }

    setShowLoading(true);

    try {
      const locale = getLocale();
      if (locale !== ELocale.INTERNAL) {
        await leadApi.updateLead(leadId, { locale }, publicId);
      }

      const { data: updatedLead } = await leadApi.startLead(leadId, publicId);

      storeUpdatedLead(updatedLead);

      switch (updatedLead.status) {
        case ELeadStatus.New: {
          showAlert({
            type: "error",
            message: getLocalizedText("system.error"),
          });
          break;
        }

        case ELeadStatus.TechnicalError: {
          setShowHelp(true);
          window.scrollTo(0, 0);
          break;
        }

        case ELeadStatus.Pending:
        default: {
          navigate(
            appendQueryParams({
              path: "/request/property",
              queryParams: { leadId, quoteId, publicId },
            }),
          );
          break;
        }
      }
    } catch (unknownError) {
      const error = unknownError as any;
      showAlert({
        type: "error",
        error,
      });
    }

    setShowLoading(false);
  };

  // Render
  if (!leadId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetching) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchError) {
      return;
    }

    return <ErrorLead errorLead={fetchError} />;
  };

  const renderLead = () => {
    if (!lead) {
      return;
    }

    if (!isFormInitialized) {
      return renderLoading();
    }

    return (
      <>
        <FormSectionHeader
          title={getLocalizedText("insurance.address_matcher.current_address")}
        />
        <PropertyInfoBanner lead={lead} displayMobileViewSameAsDesktop={true} />

        <Spacer weight={ESpacerWeight.W32} />

        {showHelp && (
          <FormHelpInfo
            title={getLocalizedText(
              "insurance.address_matcher.unmatched.help.title",
            )}
            subtitle={getLocalizedText(
              "insurance.address_matcher.unmatched.help.info",
            )}
            displayType={EHelpInfoDisplayType.Warning}
            showCloseButton={false}
          />
        )}

        <FormMultistep
          formId={formId}
          // @ts-ignore state match
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            leadId,
          )}
          // @ts-ignore state match
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            leadId,
          )}
          submitActionCreator={submitActionCreator({ lead, publicId })}
          customSuccessAction={handleSuccess}
          submitTitle={getLocalizedText("system.verify")}
        />

        {showLoading && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <ActivityIndicator />
          </>
        )}
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        {renderLead() || renderIsFetching() || renderError() || renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default AddressMatcher;
