import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./i18n.reducer";
var PERSIST_CONFIG = {
    key: "i18n",
    storage: storage,
    whitelist: ["locale"],
};
// eslint-disable-next-line import/no-default-export
export default persistReducer(PERSIST_CONFIG, reducer);
