var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { TouchableOpacity, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
import ListItem from "../ListItem";
import RadioCheck from "../RadioCheck";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(TouchableOpacity)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex-direction: row;\n  align-items: center;\n"])));
export var SelectListItem = styled(ListItem)(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var RadioCheckStyled = styled(RadioCheck)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: ", "px;\n  margin-left: ", "px;\n"], ["\n  margin-right: ", "px;\n  margin-left: ", "px;\n"])), function (props) { return (props.showSelectionLeft ? 16 : 0); }, function (props) { return (props.showSelectionLeft ? 0 : 16); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
