import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { removeNullValuesFromObject } from "@rentiohq/shared-frontend/dist/utils/object.utils";
import { EField, IExtraData } from "./schema.addressMatcher.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { lead } = extraData;

  return removeNullValuesFromObject({
    [EField.PropertyStreet]: lead.propertyStreet,
    [EField.PropertyNumber]: lead.propertyNumber,
    [EField.PropertyBox]: lead.propertyBox,
    [EField.PropertyZipCode]: lead.propertyZipCode,
    [EField.PropertyCity]: lead.propertyCity,
  });
};
