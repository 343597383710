import { getQueryHash } from "../utils/hash.utils";
import { generateActions } from "./generator.actions";
export var generateUtils = function (params) {
    var actions = generateActions(params);
    var getDetailKey = function (_a) {
        var id = _a.id, customPath = _a.customPath;
        if (id) {
            return "".concat(id);
        }
        if (customPath) {
            return customPath;
        }
        return;
    };
    var getListKey = function (payload) {
        return getQueryHash(payload.query, payload.customPath);
    };
    var getCountKey = function (payload) { return getListKey(payload); };
    var getPagedKey = function (payload) { return getListKey(payload); };
    var getInfiniteLoadKey = function (payload) { return getListKey(payload); };
    var getAllWhereKey = function (payload) { return getListKey(payload); };
    return {
        getDetailKey: getDetailKey,
        getCountKey: getCountKey,
        getPagedKey: getPagedKey,
        getInfiniteLoadKey: getInfiniteLoadKey,
        getAllWhereKey: getAllWhereKey,
    };
};
