var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Text, TouchableOpacity, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
var wrap = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n  padding-left: 16px;\n  padding-right: 16px;\n"], ["\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n  padding-left: 16px;\n  padding-right: 16px;\n"])));
export var Wrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), wrap);
export var WrapTouchable = styled(TouchableOpacity)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), wrap);
export var Title = styled(Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 15px;\n  color: ", ";\n"], ["\n  font-size: 15px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
export var InfoIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_INFO_FORM_FIELD,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 13px;\n  height: 13px;\n  margin-left: 6px;\n  margin-top: 3px;\n"], ["\n  width: 13px;\n  height: 13px;\n  margin-left: 6px;\n  margin-top: 3px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
