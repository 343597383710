export var EPropertyMemberTypes;
(function (EPropertyMemberTypes) {
    EPropertyMemberTypes["Admin"] = "ADMIN";
    EPropertyMemberTypes["Owner"] = "OWNER";
    EPropertyMemberTypes["Broker"] = "BROKER";
    EPropertyMemberTypes["IndexationManager"] = "INDEXATION_MANAGER";
    EPropertyMemberTypes["FinancialManager"] = "FINANCIAL_MANAGER";
    EPropertyMemberTypes["TechnicalManager"] = "TECHNICAL_MANAGER";
    EPropertyMemberTypes["AdministrativeManager"] = "ADMINISTRATIVE_MANAGER";
    EPropertyMemberTypes["ContractMember"] = "CONTRACT_MEMBER";
    EPropertyMemberTypes["TaskMember"] = "TASK_MEMBER";
    EPropertyMemberTypes["Tenant"] = "TENANT";
    EPropertyMemberTypes["Parent"] = "PARENT";
    // Should not be used in frontend
    EPropertyMemberTypes["Signer"] = "SIGNER";
})(EPropertyMemberTypes || (EPropertyMemberTypes = {}));
export var EPropertyMemberFromString = new Map([
    ["ADMIN", EPropertyMemberTypes.Admin],
    ["OWNER", EPropertyMemberTypes.Owner],
    ["BROKER", EPropertyMemberTypes.Broker],
    ["INDEXATION_MANAGER", EPropertyMemberTypes.IndexationManager],
    ["FINANCIAL_MANAGER", EPropertyMemberTypes.FinancialManager],
    ["TECHNICAL_MANAGER", EPropertyMemberTypes.TechnicalManager],
    ["ADMINISTRATIVE_MANAGER", EPropertyMemberTypes.AdministrativeManager],
    ["TASK_MEMBER", EPropertyMemberTypes.TaskMember],
    ["CONTRACT_MEMBER", EPropertyMemberTypes.ContractMember],
    ["TENANT", EPropertyMemberTypes.Tenant],
    ["PARENT", EPropertyMemberTypes.Parent],
]);
export var EPropertyAdjoined;
(function (EPropertyAdjoined) {
    EPropertyAdjoined["Freestanding"] = "freestanding";
    // cSpell:ignore oneside
    EPropertyAdjoined["OneSide"] = "oneside";
    // cSpell:ignore bothsides
    EPropertyAdjoined["BothSides"] = "bothsides";
    EPropertyAdjoined["Unknown"] = "unknown";
})(EPropertyAdjoined || (EPropertyAdjoined = {}));
export var EPropertyForRentStatus;
(function (EPropertyForRentStatus) {
    EPropertyForRentStatus["None"] = "none";
    EPropertyForRentStatus["ForRent"] = "forRent";
    EPropertyForRentStatus["InOption"] = "inOption";
})(EPropertyForRentStatus || (EPropertyForRentStatus = {}));
export var EPropertyEPCLabel;
(function (EPropertyEPCLabel) {
    EPropertyEPCLabel["Unknown"] = "unknown";
    EPropertyEPCLabel["NoEPCAvailable"] = "NA";
    EPropertyEPCLabel["APlus"] = "A+";
    EPropertyEPCLabel["A"] = "A";
    EPropertyEPCLabel["B"] = "B";
    EPropertyEPCLabel["C"] = "C";
    EPropertyEPCLabel["D"] = "D";
    EPropertyEPCLabel["E"] = "E";
    EPropertyEPCLabel["F"] = "F";
    EPropertyEPCLabel["G"] = "G";
})(EPropertyEPCLabel || (EPropertyEPCLabel = {}));
export var EElectricityType;
(function (EElectricityType) {
    EElectricityType["Single"] = "single";
    EElectricityType["Double"] = "double";
    EElectricityType["Unknown"] = "unknown";
})(EElectricityType || (EElectricityType = {}));
export var EElectricityMeterType;
(function (EElectricityMeterType) {
    EElectricityMeterType["Standard"] = "standard";
    EElectricityMeterType["Opening"] = "opening";
    EElectricityMeterType["Budget"] = "budget";
    EElectricityMeterType["Empty"] = "empty";
})(EElectricityMeterType || (EElectricityMeterType = {}));
export var ENaturalGasAvailable;
(function (ENaturalGasAvailable) {
    ENaturalGasAvailable["Available"] = "available";
    ENaturalGasAvailable["Unavailable"] = "unavailable";
    ENaturalGasAvailable["Unknown"] = "unknown";
})(ENaturalGasAvailable || (ENaturalGasAvailable = {}));
export var ENaturalGasMeterType;
(function (ENaturalGasMeterType) {
    ENaturalGasMeterType["Standard"] = "standard";
    ENaturalGasMeterType["Opening"] = "opening";
    ENaturalGasMeterType["Budget"] = "budget";
    ENaturalGasMeterType["Empty"] = "empty";
})(ENaturalGasMeterType || (ENaturalGasMeterType = {}));
export var EPropertyTypeId;
(function (EPropertyTypeId) {
    EPropertyTypeId[EPropertyTypeId["Group"] = 1] = "Group";
    EPropertyTypeId[EPropertyTypeId["House"] = 2] = "House";
    EPropertyTypeId[EPropertyTypeId["Apartment"] = 3] = "Apartment";
    EPropertyTypeId[EPropertyTypeId["StudentResidence"] = 4] = "StudentResidence";
    EPropertyTypeId[EPropertyTypeId["Commercial"] = 5] = "Commercial";
    EPropertyTypeId[EPropertyTypeId["ParkingLot"] = 6] = "ParkingLot";
    EPropertyTypeId[EPropertyTypeId["Garage"] = 7] = "Garage";
    // Other = 8,
    EPropertyTypeId[EPropertyTypeId["Office"] = 9] = "Office";
    EPropertyTypeId[EPropertyTypeId["Warehouse"] = 10] = "Warehouse";
    EPropertyTypeId[EPropertyTypeId["Shed"] = 11] = "Shed";
    EPropertyTypeId[EPropertyTypeId["Storage"] = 12] = "Storage";
})(EPropertyTypeId || (EPropertyTypeId = {}));
export var EPropertyTypes;
(function (EPropertyTypes) {
    EPropertyTypes["Group"] = "group";
    EPropertyTypes["Building"] = "building";
    EPropertyTypes["House"] = "home";
    EPropertyTypes["Apartment"] = "apartment";
    EPropertyTypes["StudentResidence"] = "student_residence";
    EPropertyTypes["Commercial"] = "commercial";
    EPropertyTypes["ParkingLot"] = "parking_lot";
    EPropertyTypes["Garage"] = "garage";
    EPropertyTypes["Office"] = "office";
    EPropertyTypes["Warehouse"] = "warehouse";
    EPropertyTypes["Shed"] = "shed";
    EPropertyTypes["Storage"] = "storage";
    EPropertyTypes["Other"] = "other";
})(EPropertyTypes || (EPropertyTypes = {}));
