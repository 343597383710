var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import FormInputBase from "../FormInputBase";
import Picker from "./components/Picker/Picker";
var FormDropdownSelect = /** @class */ (function (_super) {
    __extends(FormDropdownSelect, _super);
    function FormDropdownSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handleChangeValue = function (value) {
            if (value === -1) {
                _this.props.onSelect(undefined);
                return;
            }
            _this.props.onSelect(value);
        };
        return _this;
    }
    FormDropdownSelect.prototype.render = function () {
        var _a = this.props, title = _a.title, info = _a.info, selected = _a.selected, options = _a.options, error = _a.error, optional = _a.optional, disabled = _a.disabled, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo;
        var items = options.map(function (_a) {
            var value = _a.value, id = _a.id;
            return ({
                label: value,
                value: id,
            });
        });
        return (_jsx(FormInputBase, { title: title, info: info, error: error, optional: optional, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, disabled: disabled, children: _jsx(Picker, { title: title, 
                // Item value has to be a string
                // @ts-ignore
                items: items, selectedValue: selected, onChangeValue: this.handleChangeValue }) }));
    };
    return FormDropdownSelect;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormDropdownSelect;
