import ButtonPrimary, {
  EButtonSize,
} from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import Spacer, {
  ESpacerDirection,
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from "utils/styles.utils";
import * as S from "./SubmitButtonGroup.styles";

interface IProps {
  titleNext: string;
  onPressBack?: () => void;
  onPressNext: () => void;
  isLoadingNext?: boolean;
}

const SubmitButtonGroup: React.FC<IProps> = (props: IProps) => {
  const { titleNext, onPressBack, onPressNext, isLoadingNext = false } = props;

  // Render
  const renderDesktop = () => (
    <>
      {onPressBack && (
        <>
          <ButtonPrimary
            title={getLocalizedText("system.back")}
            onPress={onPressBack}
            buttonSize={EButtonSize.Medium}
            backgroundColor={"lightgray"}
            tintColor={"gray"}
          />

          <Spacer
            direction={ESpacerDirection.Horizontal}
            weight={ESpacerWeight.W16}
          />
        </>
      )}

      <ButtonPrimary
        title={titleNext}
        onPress={onPressNext}
        buttonSize={EButtonSize.Medium}
        isLoading={isLoadingNext}
        isDisabled={isLoadingNext}
      />
    </>
  );

  const renderMobile = () => (
    <ButtonPrimary
      title={titleNext}
      onPress={onPressNext}
      buttonSize={EButtonSize.Full}
      isLoading={isLoadingNext}
      isDisabled={isLoadingNext}
    />
  );

  return (
    <>
      <ShowOnlyOnDesktop>
        <S.Wrap>{renderDesktop()}</S.Wrap>
      </ShowOnlyOnDesktop>
      <ShowOnlyOnMobile>
        <S.Wrap>{renderMobile()}</S.Wrap>
      </ShowOnlyOnMobile>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SubmitButtonGroup;
