import agreementSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.saga";
import leadSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.saga";
import paymentSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/payment/payment.saga";
import quoteSaga from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.saga";
import { all, fork } from "redux-saga/effects";

// eslint-disable-next-line import/no-default-export
export default function* sagas() {
  yield all([
    fork(agreementSaga),
    fork(leadSaga),
    fork(paymentSaga),
    fork(quoteSaga),
  ]);
}
