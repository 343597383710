var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Dimensions } from "react-native";
import { css } from "../components/theme/styled-components.native";
// cspell:ignore atrule
// Use dimension API instead of media query to silence react-native-web "atrule" warning
export var desktopStyles = function (style) {
    return Dimensions.get("window").width > 768
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), style) : "";
};
var templateObject_1;
