import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EPropertyAdjoined,
  EPropertyTypeId,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.propertyInfo.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    const isHouse = extraData.lead.propertyType === EPropertyTypeId.House;
    const isApartment =
      extraData.lead.propertyType === EPropertyTypeId.Apartment;

    return {
      required: [EField.ContractStartDate],
      properties: {
        [EField.Info]: {
          default: undefined,
        },

        [EField.ContractStartDate]: {
          default: getValue(
            EField.ContractStartDate,
            initialValues,
            defaultValues,
          ),
        },

        [EField.BuildingSurfaceArea]: {
          default: getValue(
            EField.BuildingSurfaceArea,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isApartment,
          requiredIf: () => isApartment,
        },

        // [EField.BuildingFloor]: {
        //   default: getValue(EField.BuildingFloor, initialValues, defaultValues),
        //   // @ts-ignore
        //   showIf: () => isApartment,
        // },

        [EField.BuildingLayers]: {
          default: getValue(
            EField.BuildingLayers,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isApartment,
          requiredIf: () => isApartment,
        },

        [EField.BuildingAdjoined]: {
          default: getValue(
            EField.BuildingAdjoined,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: () => isHouse,
          requiredIf: () => isHouse,
          // @ts-ignore
          options: [
            EPropertyAdjoined.Freestanding,
            EPropertyAdjoined.OneSide,
            EPropertyAdjoined.BothSides,
            // EPropertyAdjoined.Unknown,
            // EPropertyAdjoined.NotApplicable,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `property.adjoined.option.${id}.label`.toLowerCase(),
            ),
          })),
        },
      },
    };
  };

export const uiSchema = (
  initialValues: { [key: string]: any },
  _: IExtraData,
): IUiSchema<EField> => ({
  [EField.Info]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("form.request_insurance.title"),
  },

  [EField.ContractStartDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("form.request_insurance.field.contract_start_date"),
    isDisabled: () => true,
  },

  [EField.BuildingSurfaceArea]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText(
      "form.request_insurance.field.building_surface_area",
    ),
    placeholder: "0",
    suffixText: "m²",
    isFloat: false,
    isDisabled: () => !!initialValues[EField.BuildingSurfaceArea],
  },

  // [EField.BuildingFloor]: {
  //   "ui:field": ERentioFormField.Number,
  //   title: getLocalizedText("form.request_insurance.field.building_floor"),
  //   placeholder: "0",
  //   isDisabled: () => !!initialValues[EField.BuildingFloor],
  // },

  [EField.BuildingLayers]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("form.request_insurance.field.building_layers"),
    placeholder: "0",
    isFloat: false,
    isDisabled: () => !!initialValues[EField.BuildingLayers],
  },

  [EField.BuildingAdjoined]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("form.request_insurance.field.building_adjoined"),
    // isDisabled: () =>
    //   !!initialValues[EField.BuildingAdjoined] &&
    //   initialValues[EField.BuildingAdjoined] !== EPropertyAdjoined.Unknown,
  },
});

export const getValidate =
  (extraData: IExtraData) => (formData: any, errors: FormValidation) => {
    const isApartment =
      extraData.lead.propertyType === EPropertyTypeId.Apartment;

    if (isApartment) {
      const buildingSurfaceArea = formData[EField.BuildingSurfaceArea] || 0;
      if (buildingSurfaceArea < 10) {
        errors[EField.BuildingSurfaceArea].addError(
          getLocalizedText("system.form.error.number.min", { value: "10" }),
        );
      }

      const buildingLayers = formData[EField.BuildingLayers] || 0;
      if (buildingLayers < 1) {
        errors[EField.BuildingLayers].addError(
          getLocalizedText("system.form.error.number.min", { value: "1" }),
        );
      }
    }

    return errors;
  };
