import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { transparentize } from "polished";

export const Wrap = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const InnerWrap = styled.div`
  padding: 40px 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 30px 24px;
  }
`;

export const ExtraInfoBanner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color: ${props => transparentize(0.9, props.theme.colors.global5)};
  border-left: 2px solid ${props => props.theme.colors.global5};
`;

export const ExtraInfoIcon = styled.img`
  margin-right: 24px;
  width: 60px;
  height: 60px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    margin-right: 12px;
    width: 40px;
    height: 40px;
  }
`;

export const ExtraInfoWrap = styled.div`
  flex: 1;
`;

export const ExtraInfoTitle = styled.div`
  margin-bottom: 4px;
  color: ${props => props.theme.colors.global1};
  font-size: 14px;
  a {
    color: ${props => props.theme.colors.global5};
    text-decoration: none;
    font-size: inherit;
  }
`;

export const ExtraInfoBody = styled.div`
  margin-top: 12px;
  * {
    font-size: 13px;
    color: ${props => props.theme.colors.global1};
  }
`;
