import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const Wrap = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const InnerWrap = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
`;

export const IconWrap = styled.div`
  width: 46px;
  height: 46px;
`;

export const BackIcon = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

export const Logo = styled.img`
  width: 100px;
  height: 47px;
`;

// export const LogoBaloise = styled.img`
//   width: 75px;
//   height: 31px;
// `;

// export const LanguageSwitcherWrap = styled.div`
//   display: inline-flex;
//   align-items: center;
//   color: ${props => props.theme.colors.global2};
//   font-weight: bold;
//   font-size: 13px;
//   @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
//     display: none;
//   }
// `;

// export const LanguageButton = styled.button<{ isActive: boolean }>`
//   margin: 0;
//   padding: 0 4px;
//   color: ${props => (props.isActive ? props.theme.colors.global5 : "inherit")};
// `;

export const Border = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.colors.global4};
`;
