import { REDUCER_KEY_PAYMENT } from "./payment.reducer";
export var getPayment = function (state, paymentId) {
    return state.payment.payments[paymentId];
};
export var isFetchingPayment = function (state, paymentId) { var _a; return ((_a = state.payment.paymentsById[paymentId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchPaymentError = function (state, paymentId) { var _a; return (_a = state.payment.paymentsById[paymentId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getActivePaymentByQuoteId = function (state, quoteId) {
    var _a;
    var paymentId = (_a = state.payment.paymentsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.activePaymentId;
    if (!paymentId) {
        return;
    }
    return state.payment.payments[paymentId];
};
export var isCreatingPayment = function (state, quoteId) { var _a; return ((_a = state.payment.paymentsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.isCreating) || false; };
export var createPaymentError = function (state, quoteId) { var _a; return (_a = state.payment.paymentsByQuoteId[quoteId]) === null || _a === void 0 ? void 0 : _a.createError; };
