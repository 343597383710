var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Platform, View } from "react-native";
import styled from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: ", ";\n"], ["\n  width: 100%;\n  height: ", ";\n"])), Platform.OS === "web" ? "100vh" : "100%");
export var Wrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  height: 100%;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n"])));
export var ActivityIndicatorWrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 80px;\n  height: 80px;\n  background-color: ", ";\n  border-radius: 12px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 80px;\n  height: 80px;\n  background-color: ", ";\n  border-radius: 12px;\n  align-items: center;\n  justify-content: center;\n"])), function (props) {
    return props.transparent ? "transparent" : props.theme.colors.global4;
});
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
    // TODO:
    size: "large",
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
