import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as quoteActions from "./quote.actions";
import * as quoteSelectors from "./quote.selectors";
export var useGetQuote = function (params) {
    var leadId = params.leadId, quoteId = params.quoteId, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var quote = useSelector(function (state) {
        return quoteSelectors.getQuote(state, quoteId || "-1");
    });
    var isFetching = useSelector(function (state) {
        return quoteSelectors.isFetchingQuote(state, quoteId || "-1");
    });
    var fetchError = useSelector(function (state) {
        return quoteSelectors.fetchQuoteError(state, quoteId || "-1");
    });
    // Lifecycle
    React.useEffect(function () {
        if (isFetching) {
            return;
        }
        if (!leadId || !quoteId) {
            return;
        }
        dispatch(quoteActions.getQuote.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            publicId: publicId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadId, publicId, quoteId]);
    // Actions
    var fetchQuote = function () {
        if (!leadId || !quoteId) {
            return;
        }
        dispatch(quoteActions.getQuote.actions.start({
            leadId: leadId,
            quoteId: quoteId,
            publicId: publicId,
        }));
    };
    return { quote: quote, isFetching: isFetching, fetchError: fetchError, fetchQuote: fetchQuote };
};
// Initialization flow:
// Fetches optional initialQuoteId & sets fetched quote as activeQuote
// OR
// Creates blank new quote & sets created quote as activeQuote
export var useActiveQuote = function (params) {
    var leadId = params.leadId, initialQuoteId = params.initialQuoteId, publicId = params.publicId;
    // Redux
    var dispatch = useDispatch();
    var isFetchingInitialQuote = useSelector(function (state) {
        return quoteSelectors.isFetchingQuote(state, initialQuoteId || "-1");
    });
    var activeQuote = useSelector(function (state) {
        return leadId ? quoteSelectors.getActiveQuoteByLeadId(state, leadId) : undefined;
    });
    var isCreating = useSelector(function (state) {
        return leadId ? quoteSelectors.isCreatingQuote(state, leadId) : false;
    });
    var createError = useSelector(function (state) {
        return leadId ? quoteSelectors.createQuoteError(state, leadId) : undefined;
    });
    // Lifecycle
    React.useEffect(function () {
        if (!leadId) {
            return;
        }
        // Get initial quote
        if (initialQuoteId) {
            if (isFetchingInitialQuote) {
                return;
            }
            dispatch(quoteActions.getQuote.actions.start({
                leadId: leadId,
                quoteId: initialQuoteId,
                publicId: publicId,
            }));
            return;
        }
        // Create new quote
        dispatch(quoteActions.createQuote.actions.start({
            leadId: leadId,
            createdQuote: {
                optionContentPremium: false,
                optionTheftPremium: false,
                optionMotorVehiclesPremium: false,
                optionLegalAssistancePremium: false,
            },
            publicId: publicId,
        }));
    }, [leadId, publicId, initialQuoteId]);
    // Actions
    var createQuote = function (createdQuote) {
        if (!leadId) {
            return;
        }
        dispatch(quoteActions.createQuote.actions.start({
            leadId: leadId,
            createdQuote: createdQuote,
            publicId: publicId,
        }));
    };
    return {
        activeQuote: activeQuote,
        isCreating: isCreating,
        createError: createError,
        createQuote: createQuote,
    };
};
