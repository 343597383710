var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from "polished";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
import ButtonBasic from "../ButtonBasic";
export var EHelpInfoDisplayType;
(function (EHelpInfoDisplayType) {
    EHelpInfoDisplayType["Info"] = "info";
    EHelpInfoDisplayType["Success"] = "success";
    EHelpInfoDisplayType["Warning"] = "warning";
    EHelpInfoDisplayType["Error"] = "error";
})(EHelpInfoDisplayType || (EHelpInfoDisplayType = {}));
export var StoryWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 320px;\n"], ["\n  width: 320px;\n"])));
export var Wrap = styled(View)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  border-left-color: ", ";\n  border-left-width: 3px;\n  background-color: ", ";\n"], ["\n  ", ";\n  border-left-color: ", ";\n  border-left-width: 3px;\n  background-color: ", ";\n"])), Platform.select({
    android: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin: 6px 8px;\n    "], ["\n      margin: 6px 8px;\n    "]))),
    ios: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin: 6px 8px;\n    "], ["\n      margin: 6px 8px;\n    "]))),
    web: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin: 6px 0px;\n    "], ["\n      margin: 6px 0px;\n    "]))),
}), function (props) {
    switch (props.displayType) {
        case EHelpInfoDisplayType.Success:
            return props.theme.colors.successText;
        case EHelpInfoDisplayType.Warning:
            return props.theme.colors.warningText;
        case EHelpInfoDisplayType.Error:
            return props.theme.colors.errorText;
        case EHelpInfoDisplayType.Info:
        default:
            return props.theme.colors.infoText;
    }
}, function (props) {
    switch (props.displayType) {
        case EHelpInfoDisplayType.Success:
            return transparentize(0.6, props.theme.colors.success);
        case EHelpInfoDisplayType.Warning:
            return transparentize(0.9, props.theme.colors.warning);
        case EHelpInfoDisplayType.Error:
            return transparentize(0.6, props.theme.colors.error);
        case EHelpInfoDisplayType.Info:
        default:
            return transparentize(0.6, props.theme.colors.info);
    }
});
export var InnerWrap = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 16px;\n  justify-content: center;\n"], ["\n  padding: 16px;\n  justify-content: center;\n"])));
export var InnerWrapSubtitle = styled(View)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 16px;\n  margin: 0px 8px;\n  flex-direction: row;\n  justify-content: space-between;\n"], ["\n  padding: 16px;\n  margin: 0px 8px;\n  flex-direction: row;\n  justify-content: space-between;\n"])));
export var HeaderWrap = styled(View)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex-direction: row;\n  flex: 1 0 auto;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n"], ["\n  flex-direction: row;\n  flex: 1 0 auto;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n"])));
export var Icon = styled(Image)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  margin-right: 12px;\n"], ["\n  width: 24px;\n  height: 24px;\n  margin-right: 12px;\n"])));
export var Title = styled(Text)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex: 1;\n  font-weight: bold;\n  font-family: ", ";\n  color: ", ";\n"], ["\n  flex: 1;\n  font-weight: bold;\n  font-family: ", ";\n  color: ", ";\n"])), function (props) { return props.theme.fonts.primary; }, function (props) {
    switch (props.displayType) {
        case EHelpInfoDisplayType.Success:
            return props.theme.colors.successText;
        case EHelpInfoDisplayType.Warning:
            return props.theme.colors.warningText;
        case EHelpInfoDisplayType.Error:
            return props.theme.colors.errorText;
        case EHelpInfoDisplayType.Info:
        default:
            return props.theme.colors.neutral1;
    }
});
export var Info = styled(Text)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  line-height: 19px;\n  font-weight: 400;\n  font-size: 14px;\n  color: ", ";\n"], ["\n  line-height: 19px;\n  font-weight: 400;\n  font-size: 14px;\n  color: ", ";\n"])), function (props) {
    switch (props.displayType) {
        case EHelpInfoDisplayType.Success:
            return props.theme.colors.successText;
        case EHelpInfoDisplayType.Warning:
            return props.theme.colors.warningText;
        case EHelpInfoDisplayType.Error:
            return props.theme.colors.errorText;
        case EHelpInfoDisplayType.Info:
        default:
            return props.theme.colors.neutral1;
    }
});
export var HelpButton = styled(ButtonBasic).attrs(function (props) {
    var color;
    switch (props.displayType) {
        case EHelpInfoDisplayType.Success:
            color = props.theme.colors.successText;
            break;
        case EHelpInfoDisplayType.Warning:
            color = props.theme.colors.warningText;
            break;
        case EHelpInfoDisplayType.Error:
            color = props.theme.colors.errorText;
            break;
        case EHelpInfoDisplayType.Info:
            color = props.theme.colors.infoText;
            break;
        default:
            color = props.theme.colors.neutral1;
    }
    return {
        titleProps: {
            style: {
                color: color,
            },
        },
    };
})(templateObject_12 || (templateObject_12 = __makeTemplateObject([""], [""])));
export var CloseButtonWrap = styled(TouchableOpacity)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin-left: 6px;\n"], ["\n  margin-left: 6px;\n"])));
export var CloseButton = styled(Image).attrs(function () { return ({
    source: images.ICO_CLOSE_DARK,
}); })(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n"], ["\n  width: 20px;\n  height: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
