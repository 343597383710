import queryString from "query-string";
import { removeUndefinedValuesFromObject } from "./object.utils";
export var appendQueryParams = function (params) {
    var path = params.path, queryParams = params.queryParams;
    var queryParamsParsed = removeUndefinedValuesFromObject(queryParams);
    var result = queryString.parseUrl(path);
    Object.keys(queryParamsParsed).forEach(function (queryParamKey) {
        var value = queryParamsParsed[queryParamKey];
        if (value !== undefined) {
            result.query[queryParamKey] = "".concat(queryParamsParsed[queryParamKey]);
        }
    });
    return queryString.stringifyUrl(result);
};
