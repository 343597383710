import Page from "components/Page";
import Home from "modules/general/routes/Home";
import NotFound from "modules/general/routes/NotFound";
import { getRequestScene } from "modules/request/Request.scene";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

class AppRouter extends React.Component {
  // Render
  public render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Page />}>
            <Route path="/" element={<Home />} />

            {getRequestScene()}

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default AppRouter;
