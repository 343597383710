import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { htmlTextAsReactText } from "@rentiohq/shared-frontend/dist/utils/text/text.utils";
import React from "react";
import Truncate from "react-truncate";
import * as S from "./InfoCircle.styles";

interface IProps {
  circleSize?: number;
  isInverted?: boolean;
  icon: string;
  title: string;
  subtitle?: string;
  extraInfo?: string;
}

const InfoCircle: React.FC<IProps> = (props: IProps) => {
  const {
    circleSize = 100,
    isInverted = false,
    icon,
    title,
    subtitle,
    extraInfo,
  } = props;

  const handlePress = () => {
    confirm({
      title,
      info: extraInfo ? htmlTextAsReactText(extraInfo) : "",
      primaryActions: [
        {
          title: getLocalizedText("system.ok"),
          onPress: () => {},
        },
      ],
      onClose: () => {},
    });
  };

  return (
    <S.Wrap
      circleSize={circleSize}
      onClick={extraInfo ? handlePress : undefined}
    >
      <S.Circle circleSize={circleSize} isInverted={isInverted}>
        <S.CircleIcon circleSize={circleSize} src={icon} />
      </S.Circle>
      <S.Title isInverted={isInverted}>{`${title}${
        !!extraInfo ? " ›" : ""
      }`}</S.Title>
      {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
      {extraInfo && (
        <S.ExtraInfoWrap>
          <Truncate
            width={circleSize * 2}
            lines={3}
            trimWhitespace={true}
            style={{ color: "white" }}
            ellipsis={
              <span>
                {"... "}
                <span style={{ fontWeight: "bold", fontSize: 14 }}>
                  {getLocalizedText("system.read_more")}
                </span>
              </span>
            }
          >
            {htmlTextAsReactText(extraInfo)}
          </Truncate>
        </S.ExtraInfoWrap>
      )}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default InfoCircle;
