import { FullScreenErrorState } from "@rentiohq/shared-frontend/dist/components/components/FullScreenState";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./NotFound.styles";

interface IProps {}

const NotFound: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();

  // Event handlers
  const handlePressBack = () => {
    navigate("/");
  };

  // Render
  return (
    <S.Wrap>
      <S.InnerWrap>
        <FullScreenErrorState
          title={getLocalizedText("system.error.404")}
          actionTitle={getLocalizedText("system.back")}
          onPressAction={handlePressBack}
        />
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default NotFound;
