export var EValueType;
(function (EValueType) {
    EValueType["Locale"] = "locale";
    EValueType["UserId"] = "user_id";
    EValueType["UserEmail"] = "user_email";
})(EValueType || (EValueType = {}));
export var EEventType;
(function (EEventType) {
    EEventType["General"] = "general";
    EEventType["ScreenView"] = "screen_view";
    EEventType["UserInteraction"] = "user_interaction";
    EEventType["Network"] = "network";
})(EEventType || (EEventType = {}));
export var EErrorType;
(function (EErrorType) {
    EErrorType["General"] = "general";
    EErrorType["Network"] = "network";
})(EErrorType || (EErrorType = {}));
