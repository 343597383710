var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { transparentize } from "polished";
import { Image, Text, TouchableOpacity, View, } from "react-native";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 320px;\n"], ["\n  width: 320px;\n"])));
var wrap = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 48px;\n  flex-direction: row;\n  padding-left: 16px;\n  padding-right: 16px;\n"], ["\n  width: 100%;\n  min-height: 48px;\n  flex-direction: row;\n  padding-left: 16px;\n  padding-right: 16px;\n"])));
export var Wrap = styled(View)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"], ["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"])), wrap, function (props) {
    if (props.isActive) {
        return transparentize(0.9, props.theme.colors.global5);
    }
    if (props.isArchived) {
        return transparentize(0.3, props.theme.colors.error);
    }
    return "transparent";
}, function (props) { return (props.disabled ? 0.5 : 1); });
export var WrapTouchable = styled(TouchableOpacity)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"], ["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"])), wrap, function (props) {
    if (props.isActive) {
        return transparentize(0.9, props.theme.colors.global5);
    }
    if (props.isArchived) {
        return transparentize(0.3, props.theme.colors.error);
    }
    return "transparent";
}, function (props) { return (props.disabled ? 0.5 : 1); });
export var WrapTouchableLink = styled(TouchableOpacity)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"], ["\n  ", ";\n  background-color: ", ";\n  opacity: ", ";\n"])), wrap, function (props) {
    return props.isActiveLink
        ? transparentize(0.9, props.theme.colors.global5)
        : "transparent";
}, function (props) { return (props.disabled ? 0.5 : 1); });
export var endWrap = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40px;\n  align-items: center;\n  justify-content: center;\n  padding-top: 4px;\n  padding-bottom: 4px;\n"], ["\n  width: 40px;\n  align-items: center;\n  justify-content: center;\n  padding-top: 4px;\n  padding-bottom: 4px;\n"])));
export var LeftWrap = styled(View)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  ", "\n  margin-right: 12px;\n"], ["\n  ", "\n  margin-right: 12px;\n"])), endWrap);
export var CenterWrap = styled(View)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex: 1;\n  justify-content: center;\n  padding-top: 8px;\n  padding-bottom: 8px;\n"], ["\n  flex: 1;\n  justify-content: center;\n  padding-top: 8px;\n  padding-bottom: 8px;\n"])));
export var RightWrap = styled(View)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), endWrap);
export var MetaInfo = styled(Text)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 11px;\n  letter-spacing: -0.07px;\n  color: ", ";\n  margin-bottom: 4px;\n"], ["\n  font-size: 11px;\n  letter-spacing: -0.07px;\n  color: ", ";\n  margin-bottom: 4px;\n"])), function (props) { return props.theme.colors.global2; });
export var TitleWrap = styled(View)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n"], ["\n  flex-direction: row;\n  align-items: center;\n"])));
export var Title = styled(Text)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: 16px;\n  color: ", ";\n"], ["\n  font-size: 16px;\n  color: ", ";\n"])), function (props) {
    return props.showHeader
        ? props.theme.colors.neutral1
        : props.theme.colors.global2;
});
export var Subtitle = styled(Text)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: 12px;\n  letter-spacing: -0.07px;\n  color: ", ";\n  margin-top: 4px;\n"], ["\n  font-size: 12px;\n  letter-spacing: -0.07px;\n  color: ", ";\n  margin-top: 4px;\n"])), function (props) { return props.theme.colors.global2; });
export var ExtraSubtitle = styled(Text)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-size: 11px;\n  color: ", ";\n  margin-top: 4px;\n"], ["\n  font-size: 11px;\n  color: ", ";\n  margin-top: 4px;\n"])), function (props) { return props.theme.colors.global2; });
export var Icon = styled(Image)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  border-radius: ", ";\n"], ["\n  width: 32px;\n  height: 32px;\n  border-radius: ", ";\n"])), function (props) { return (props.isRounded ? "16px" : "0px"); });
export var IconDisclosureWrap = styled(View)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n"], ["\n  align-items: center;\n  justify-content: center;\n"])));
export var IconDisclosure = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
}); })(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  width: 24px;\n  height: 40px;\n"], ["\n  width: 24px;\n  height: 40px;\n"])));
export var DisclosureInfoWrap = styled(View)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin-right: ", ";\n  justify-content: center;\n"], ["\n  margin-right: ", ";\n  justify-content: center;\n"])), function (props) { return (props.withExtraRightMargin ? "4px" : 0); });
export var DisclosureInfo = styled(Text)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 13px;\n"], ["\n  color: ", ";\n  font-size: 13px;\n"])), function (props) {
    return props.hasTouchableStyle
        ? props.theme.colors.global5
        : props.theme.colors.global2;
});
export var IonTextWrap = styled(View)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 40px;\n  border-radius: 20px;\n  justify-content: center;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  min-height: 40px;\n  border-radius: 20px;\n  justify-content: center;\n  background-color: ", ";\n"])), function (props) {
    return props.isCompany ? props.theme.colors.global4 : props.theme.colors.global3;
});
export var IconText = styled(Text)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  font-weight: bold;\n  font-family: ", ";\n  letter-spacing: -0.13px;\n  font-size: 12px;\n"], ["\n  color: ", ";\n  text-align: center;\n  font-weight: bold;\n  font-family: ", ";\n  letter-spacing: -0.13px;\n  font-size: 12px;\n"])), function (props) { return props.theme.colors.global1; }, function (props) { return props.theme.fonts.primary; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
