import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import assets from "assets";

export const Wrap = styled.div`
  width: 100%;
  position: relative;
  padding-top: 24px;
`;

export const IconWrap = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -36px;
  background-color: ${props => props.theme.colors.neutral2};
`;

export const Icon = styled.img.attrs({
  src: assets.ICO_INSURANCE_BRAND_COLOR,
})`
  margin: 0 8px;
  width: 50px;
  height: 50px;
`;

export const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 95px;
  padding: 32px 16px 16px;
  border: 2px solid ${props => props.theme.colors.global5};
  border-radius: 5px;
  text-align: center;
`;

export const Pulse = styled.span<{ shouldPulse: boolean }>`
  @keyframes pulse {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.45;
    }
  }

  animation: ${props => (props.shouldPulse ? "pulse" : "none")} 0.3s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-timing-function: ease;
`;

export const Title = styled.span<{ asError?: boolean }>`
  display: block;
  font-size: 18px;
  color: ${props =>
    props.asError ? props.theme.colors.errorText : props.theme.colors.neutral1};
`;

export const PricePrimary = styled.span`
  display: block;
  margin-top: 16px;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.global5};
`;

export const PriceSecondary = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 14px;
  font-weight: light;
  color: ${props => props.theme.colors.neutral1};
`;

export const FooterInfo = styled.span`
  display: block;
  margin-top: 12px;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.colors.global2};
`;

export const Address = styled.span`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.global2};
  padding-bottom: 8px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    text-align: center;
    font-size: 13px;
  }
`;

export const InfoItemWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

export const InfoItemTitle = styled.span<{ isTotal?: boolean }>`
  display: block;
  font-size: ${props => (props.isTotal ? "16px" : "14px")};
  color: ${props =>
    props.isTotal ? props.theme.colors.neutral1 : props.theme.colors.global1};
  font-weight: ${props => (props.isTotal ? "bold" : "regular")};
  text-align: left;
`;

export const InfoItemTitleSecondary = styled.span`
  color: ${props => props.theme.colors.global2};
  font-weight: 500;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    width: 100%;
    display: block;
  }
`;

export const InfoItemPrice = styled.span<{ isTotal?: boolean }>`
  display: block;
  white-space: nowrap;
  font-size: ${props => (props.isTotal ? "18px" : "14px")};
  font-weight: bold;
  color: ${props =>
    props.isTotal ? props.theme.colors.neutral1 : props.theme.colors.global1};
  font-family: "Courier New", Courier, monospace;
  text-align: right;
  flex: 1;
`;
