import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const Wrap = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 30px 24px;
  }
`;

export const NextStepsInfo = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.global1};
  max-width: 500px;
`;

export const ConfirmAllWrap = styled.div`
  width: 100%;
  align-items: flex-start;
  display: flex;
`;
