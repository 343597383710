var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import FormInputCaptcha from "../FormInputCaptcha";
var FormInputCaptchaJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormInputCaptchaJsonFormSchema, _super);
    function FormInputCaptchaJsonFormSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Render
    FormInputCaptchaJsonFormSchema.prototype.render = function () {
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        var handleChangeValue = function (input) {
            onChange(input);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, input);
                }
            }, 0);
        };
        return (_jsx(FormInputCaptcha, __assign({}, customUiSchema, { onChangeValue: handleChangeValue, error: rawErrors[0] })));
    };
    return FormInputCaptchaJsonFormSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInputCaptchaJsonFormSchema;
