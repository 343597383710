/* eslint-disable @typescript-eslint/no-var-requires */
const ICO_BACK = require("./images/ico_back.svg");
const ICO_CAR = require("./images/ico_car.svg");
const ICO_CHECK_CHECKED = require("./images/ico_check_checked.svg");
const ICO_CHECK_UNCHECKED = require("./images/ico_check_unchecked.svg");
const ICO_CIRCLE = require("./images/ico_circle.svg");
const ICO_CLOSE_ERROR = require("./images/ico_close_error.svg");
const ICO_CLOSE_INFO = require("./images/ico_close_info.svg");
const ICO_CLOSE_SUCCESS = require("./images/ico_close_success.svg");
const ICO_DROPDOWN = require("./images/ico_dropdown.svg");
const ICO_EDIT = require("./images/ico_edit.svg");
const ICO_ELECTRICITY = require("./images/ico_electricity.svg");
const ICO_FILE_PDF = require("./images/ico_file_pdf.svg");
const ICO_HELP_BRAND_COLOR = require("./images/ico_help_brand_color.svg");
const ICO_HELP_WHITE = require("./images/ico_help_white.svg");
const ICO_HOUSE_FIRE = require("./images/ico_house_fire.svg");
const ICO_HOUSEHOLD_EFFECTS = require("./images/ico_household_effects.svg");
const ICO_INSURANCE_BRAND_COLOR = require("./images/ico_insurance_brand_color.svg");
const ICO_LAW = require("./images/ico_law.svg");
const ICO_LOGO = require("./images/ico_logo.svg");
const ICO_LOGO_BALOISE = require("./images/ico_logo_baloise.svg");
const ICO_MAIL = require("./images/ico_mail.svg");
const ICO_OIL = require("./images/ico_oil.svg");
const ICO_PHONE = require("./images/ico_phone.svg");
const ICO_PROPERTY_APARTMENT = require("./images/ico_property_apartment.svg");
const ICO_PROPERTY_HOUSE = require("./images/ico_property_house.svg");
const ICO_SANITARY = require("./images/ico_sanitary.svg");
const ICO_STORM = require("./images/ico_storm.svg");
const ICO_THEFT = require("./images/ico_theft.svg");
const ICO_WATER = require("./images/ico_water.svg");
const ICO_WINDOWS_BRAND_COLOR = require("./images/ico_windows_brand_color.svg");
const ICO_WINDOWS_WHITE = require("./images/ico_windows_white.svg");
const IMG_FINISHED = require("./images/img_finished.svg");
const IMG_MAILED = require("./images/img_mailed.svg");
const IMG_SPLASH = require("./images/img_splash.png");

// eslint-disable-next-line import/no-default-export
export default {
  ICO_BACK,
  ICO_CAR,
  ICO_CHECK_CHECKED,
  ICO_CHECK_UNCHECKED,
  ICO_CIRCLE,
  ICO_CLOSE_ERROR,
  ICO_CLOSE_INFO,
  ICO_CLOSE_SUCCESS,
  ICO_DROPDOWN,
  ICO_EDIT,
  ICO_ELECTRICITY,
  ICO_FILE_PDF,
  ICO_HELP_BRAND_COLOR,
  ICO_HELP_WHITE,
  ICO_HOUSE_FIRE,
  ICO_HOUSEHOLD_EFFECTS,
  ICO_INSURANCE_BRAND_COLOR,
  ICO_LAW,
  ICO_LOGO,
  ICO_LOGO_BALOISE,
  ICO_MAIL,
  ICO_OIL,
  ICO_PHONE,
  ICO_PROPERTY_APARTMENT,
  ICO_PROPERTY_HOUSE,
  ICO_SANITARY,
  ICO_STORM,
  ICO_THEFT,
  ICO_WATER,
  ICO_WINDOWS_BRAND_COLOR,
  ICO_WINDOWS_WHITE,
  IMG_FINISHED,
  IMG_MAILED,
  IMG_SPLASH,
};
