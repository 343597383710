var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Platform } from "react-native";
import ReactNativeConfig from "react-native-config";
import { CONFIG_DEMO } from "./app.config.demo";
import { CONFIG_DEV } from "./app.config.dev";
import { CONFIG_LOCAL } from "./app.config.local";
import { CONFIG_PROD } from "./app.config.prod";
import { CONFIG_STAG } from "./app.config.stag";
import { EEnvironment } from "./app.config.types";
// For iOS and Android we need to use the custom config from react-native-config
var ENV_VARIABLES = Platform.OS === "ios" || Platform.OS === "android"
    ? ReactNativeConfig
    : process.env;
var PRODUCT = ENV_VARIABLES.REACT_APP_PRODUCT;
var ENVIRONMENT = ENV_VARIABLES.REACT_APP_ENV || EEnvironment.Prod;
export var CONFIG = (function () {
    if (!PRODUCT) {
        throw new Error("No product defined");
    }
    if (ENV_VARIABLES.REACT_APP_ENV_LOCAL) {
        return __assign(__assign({ PRODUCT: PRODUCT }, CONFIG_PROD), CONFIG_LOCAL);
    }
    if (ENVIRONMENT === EEnvironment.Dev) {
        return __assign(__assign({ PRODUCT: PRODUCT }, CONFIG_PROD), CONFIG_DEV);
    }
    if (ENVIRONMENT === EEnvironment.Stag) {
        return __assign(__assign({ PRODUCT: PRODUCT }, CONFIG_PROD), CONFIG_STAG);
    }
    if (ENVIRONMENT === EEnvironment.Demo) {
        return __assign(__assign({ PRODUCT: PRODUCT }, CONFIG_PROD), CONFIG_DEMO);
    }
    return __assign({ PRODUCT: PRODUCT }, CONFIG_PROD);
})();
