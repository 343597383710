import * as leadActions from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.actions";
import * as leadSelectors from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.selectors";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { IRootState } from "redux/reducers";
import { EField, IExtraData } from "./schema.addressMatcher.types";

export const isPerformingSubmitActionSelector =
  (leadId: string) => (state: IRootState) =>
    leadSelectors.isUpdatingLead(state, leadId);

export const performingSubmitActionErrorSelector =
  (leadId: string) => (state: IRootState) =>
    leadSelectors.updateLeadError(state, leadId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { lead, publicId } = extraData;

    const updatedLead: Partial<ILead> = {
      propertyStreet: formData[EField.PropertyStreet] || null,
      propertyNumber: formData[EField.PropertyNumber] || null,
      propertyBox: formData[EField.PropertyBox] || null,
      propertyZipCode: formData[EField.PropertyZipCode] || null,
      propertyCity: formData[EField.PropertyCity] || null,
    };

    const locale = getLocale();
    if (locale !== ELocale.INTERNAL) {
      updatedLead.locale = locale;
    }

    return leadActions.updateLead.actions.start({
      leadId: lead.id,
      updatedLead,
      publicId,
    });
  };
