import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { IAddress } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress as formatAddressBase } from "@rentiohq/shared-frontend/dist/utils/address.utils";

export const formatAddress = (lead: ILead) => {
  const address: IAddress = {
    street: lead.propertyStreet,
    number: lead.propertyNumber,
    box: lead.propertyBox,
    zip: lead.propertyZipCode,
    city: lead.propertyCity,
  };
  return formatAddressBase(address);
};
