import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.addressMatcher.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [
        EField.PropertyStreet,
        EField.PropertyNumber,
        EField.PropertyZipCode,
        EField.PropertyCity,
      ],
      properties: {
        [EField.Info]: {
          default: undefined,
        },

        [EField.PropertyStreet]: {
          default: getValue(
            EField.PropertyStreet,
            initialValues,
            defaultValues,
          ),
        },

        [EField.PropertyNumber]: {
          default: getValue(
            EField.PropertyNumber,
            initialValues,
            defaultValues,
          ),
        },

        [EField.PropertyBox]: {
          default: getValue(EField.PropertyBox, initialValues, defaultValues),
        },

        [EField.PropertyZipCode]: {
          default: getValue(
            EField.PropertyZipCode,
            initialValues,
            defaultValues,
          ),
        },

        [EField.PropertyCity]: {
          default: getValue(EField.PropertyCity, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (
  initialValues: { [key: string]: any },
  _: IExtraData,
): IUiSchema<EField> => ({
  [EField.Info]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: getLocalizedText("form.insurance.address_matcher.title"),
  },

  [EField.PropertyStreet]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("address.street.label"),
  },

  [EField.PropertyNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("address.number.label"),
  },

  [EField.PropertyBox]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("address.box.label"),
    optional: true,
  },

  [EField.PropertyZipCode]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("address.zip.label"),
  },

  [EField.PropertyCity]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("address.city.label"),
  },
});
