export var ELeadStatus;
(function (ELeadStatus) {
    ELeadStatus["New"] = "new";
    ELeadStatus["Pending"] = "pending";
    ELeadStatus["Signed"] = "signed";
    ELeadStatus["Paid"] = "paid";
    ELeadStatus["Paying"] = "paying";
    ELeadStatus["SentToBaloise"] = "sentToBaloise";
    ELeadStatus["ProcessedByBaloise"] = "processedByBaloise";
    ELeadStatus["Expired"] = "expired";
    ELeadStatus["TechnicalError"] = "technicalError";
})(ELeadStatus || (ELeadStatus = {}));
