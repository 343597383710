import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";

export interface IExtraData {
  lead: ILead;
  publicId: string;
}

export enum EField {
  Info1 = "_info1",
  ContactDamagePastYears = "ContactDamagePastYears",
  ContactDamagePastAmount = "ContactDamagePastAmount",
  ContactDamagePastTermination = "ContactDamagePastTermination",
  Info2 = "_info2",
  ContactFirstname = "contactFirstname",
  ContactLastname = "contactLastname",
  ContactEmail = "contactEmail",
  ContactMobileNumber = "contactMobileNumber",
  ContactDateOfBirth = "contactDateOfBirth",
  ContactPassportNumber = "contactPassportNumber",
  ErrorFooter = "errorFooter",
}
