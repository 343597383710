import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import { getQuoteBasePrice } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.utils";
import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { round } from "lodash";
import React from "react";
import { ActivityIndicator } from "react-native";
import { formatAddress } from "utils/lead.utils";
import * as S from "./QuoteInfoBanner.styles";

interface IProps {
  lead?: ILead;
  quote?: IQuote;
  quoteError?: Error;
  isCreatingQuote?: boolean;
  showDetailedInfo?: boolean;
  showMonthlyPrice?: boolean;
}

const QuoteInfoBanner: React.FC<IProps> = (props: IProps) => {
  const {
    lead,
    quote,
    quoteError,
    isCreatingQuote = false,
    showDetailedInfo = false,
    showMonthlyPrice = false,
  } = props;

  // Render
  const renderBasicInfo = () => {
    if (!isCreatingQuote && quoteError) {
      return (
        <>
          <S.Title asError={true}>
            {getLocalizedText("insurance.quote.error.title")}
          </S.Title>
          <S.FooterInfo>
            {getLocalizedText("insurance.quote.error.info")}
          </S.FooterInfo>
        </>
      );
    }

    if (!quote) {
      return <ActivityIndicator />;
    }

    // const basePrice = getQuoteBasePrice(quote);

    return (
      <>
        <S.Title>{getLocalizedText("insurance.base_full.title")}</S.Title>
        <S.Pulse shouldPulse={isCreatingQuote}>
          <S.PricePrimary>
            {getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.calculationValue),
            })}
          </S.PricePrimary>
        </S.Pulse>
        {showMonthlyPrice && (
          <S.PriceSecondary>
            {getLocalizedText("system.amount.monthly", {
              amount: formatCurrency(round(quote.calculationValue / 12, 2)),
            })}
          </S.PriceSecondary>
        )}
        {/* <S.FooterInfo>
          {`${getLocalizedText("insurance.base.title")}: ${getLocalizedText(
            "system.amount.yearly",
            {
              amount: formatCurrency(basePrice),
            },
          )}`}
        </S.FooterInfo> */}
      </>
    );
  };

  const renderDetailInfoRow = (params: {
    title: string;
    extraTitle?: string;
    price: string;
  }) => {
    const { title, extraTitle, price } = params;
    return (
      <S.InfoItemWrap>
        <S.InfoItemTitle>
          {title}
          {extraTitle && (
            <>
              {" "}
              <S.InfoItemTitleSecondary>
                {`(${extraTitle})`}
              </S.InfoItemTitleSecondary>
            </>
          )}
        </S.InfoItemTitle>
        <S.InfoItemPrice>{price}</S.InfoItemPrice>
      </S.InfoItemWrap>
    );
  };

  const renderDetailedInfo = () => {
    if (!lead || !quote) {
      return <ActivityIndicator />;
    }

    const basePrice = getQuoteBasePrice(quote);

    return (
      <>
        <S.Address>{formatAddress(lead).toUpperCase()}</S.Address>

        {renderDetailInfoRow({
          title: getLocalizedText("insurance.base.title"),
          price: formatCurrency(basePrice),
        })}

        <ListItemSeparator />

        {quote.optionContentPremium &&
          quote.optionContentPremiumValue &&
          quote.optionContentPremiumAmountOfContent && (
            <>
              {renderDetailInfoRow({
                title: getLocalizedText(
                  "insurance.options.premium.content.title",
                ),
                extraTitle: formatCurrency(
                  quote.optionContentPremiumAmountOfContent,
                  false,
                ),
                price: formatCurrency(quote.optionContentPremiumValue),
              })}

              <ListItemSeparator />
            </>
          )}

        {quote.optionTheftPremium && quote.optionTheftPremiumValue && (
          <>
            {renderDetailInfoRow({
              title: getLocalizedText("insurance.options.premium.theft.title"),
              price: formatCurrency(quote.optionTheftPremiumValue),
            })}

            <ListItemSeparator />
          </>
        )}

        {quote.optionMotorVehiclesPremium &&
          quote.optionMotorVehiclesPremiumValue &&
          quote.optionMotorVehiclesPremiumAmountOfMotorVehicles && (
            <>
              {renderDetailInfoRow({
                title: getLocalizedText(
                  "insurance.options.premium.motor_vehicles.title",
                ),
                extraTitle: `${getLocalizedText(
                  "insurance.options.premium.motor_vehicles.motor_vehicles_amount",
                )}: ${
                  quote.optionMotorVehiclesPremiumAmountOfMotorVehicles
                }`.toLowerCase(),
                price: formatCurrency(quote.optionMotorVehiclesPremiumValue),
              })}

              <ListItemSeparator />
            </>
          )}

        {quote.optionLegalAssistancePremium &&
          quote.optionLegalAssistancePremiumValue && (
            <>
              {renderDetailInfoRow({
                title: getLocalizedText(
                  "insurance.options.premium.legal_assistance.title",
                ),
                price: formatCurrency(quote.optionLegalAssistancePremiumValue),
              })}

              <ListItemSeparator />
            </>
          )}

        <S.InfoItemWrap>
          <S.InfoItemTitle isTotal={true}>
            {getLocalizedText("system.total")}
          </S.InfoItemTitle>
          <S.InfoItemPrice isTotal={true}>
            {getLocalizedText("system.amount.yearly", {
              amount: formatCurrency(quote.calculationValue),
            })}
          </S.InfoItemPrice>
        </S.InfoItemWrap>
      </>
    );
  };

  return (
    <S.Wrap>
      <S.IconWrap>
        <S.Icon />
      </S.IconWrap>
      <S.InnerWrap>
        {showDetailedInfo ? renderDetailedInfo() : renderBasicInfo()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteInfoBanner;
