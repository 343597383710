import { Platform } from "react-native";
import ASSETS_UNMAPPED from "./assets";
var assetAsSource = function (asset) {
    if (Platform.OS === "web") {
        return (asset === null || asset === void 0 ? void 0 : asset.default) || asset;
    }
    return asset;
};
// @ts-ignore
var assetsMapped = {};
Object.keys(ASSETS_UNMAPPED).forEach(function (keyAsString) {
    var key = keyAsString;
    var asset = ASSETS_UNMAPPED[key];
    assetsMapped[key] = assetAsSource(asset);
});
// eslint-disable-next-line import/no-default-export
export default assetsMapped;
