var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
var withOnChangeHandlerProvider = function (Component) {
    return /** @class */ (function (_super) {
        __extends(WithOnChangeHandlerProvider, _super);
        function WithOnChangeHandlerProvider() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            // Render
            _this.render = function () { return _jsx(Component, __assign({}, _this.props)); };
            // Event handlers
            _this.handleFieldChange = function (fieldName, fieldValue, mergedPartialFormData) {
                var _a = _this.props, schema = _a.schema, onChange = _a.onChange, formData = _a.formData;
                // @ts-ignore
                var handleChange = schema.handleChange;
                if (!handleChange) {
                    return;
                }
                var _b = handleChange(fieldName, fieldValue, formData, mergedPartialFormData), shouldSetNewValue = _b.shouldSetNewValue, newValue = _b.newValue;
                if (!shouldSetNewValue) {
                    return;
                }
                onChange(newValue);
            };
            return _this;
        }
        // Lifecycle methods
        WithOnChangeHandlerProvider.prototype.componentDidMount = function () {
            var _a = this.props.formContext, formContext = _a === void 0 ? {} : _a;
            if (formContext.registerOnFieldChangeHandler) {
                formContext.registerOnFieldChangeHandler(this.handleFieldChange);
            }
        };
        WithOnChangeHandlerProvider.prototype.componentWillUnmount = function () {
            var _a = this.props.formContext, formContext = _a === void 0 ? {} : _a;
            if (formContext.unregisterOnFieldChangeHandler) {
                formContext.unregisterOnFieldChangeHandler(this.handleFieldChange);
            }
        };
        return WithOnChangeHandlerProvider;
    }(React.Component));
};
// eslint-disable-next-line import/no-default-export
export default withOnChangeHandlerProvider;
