var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { TouchableOpacity } from "react-native";
import styled from "../../theme/styled-components.native";
import ListItemSelect from "../ListItemSelect";
export var Wrap = styled(TouchableOpacity)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
export var IbanListItem = styled(ListItemSelect)(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2;
