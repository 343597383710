var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, View } from "react-native";
import { Picker } from "react-native-picker-picker-fix";
import images from "../../../../assets";
import styled from "../../../../theme/styled-components.native";
export var Wrap = styled(View)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 16px;\n  padding-right: 15px;\n"], ["\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 16px;\n  padding-right: 15px;\n"])));
export var NativePickerWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.neutral2; });
export var PickerStyled = styled(Picker)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  background-color: transparent;\n  border: none;\n  padding-top: 16px;\n  padding-bottom: 16px;\n  font-size: 14px;\n  -webkit-appearance: none;\n  width: 100%;\n"], ["\n  color: ", ";\n  background-color: transparent;\n  border: none;\n  padding-top: 16px;\n  padding-bottom: 16px;\n  font-size: 14px;\n  -webkit-appearance: none;\n  width: 100%;\n"])), function (props) { return props.theme.colors.neutral1; });
export var DropdownIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_DISCLOSURE,
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  pointer-events: none;\n  position: absolute;\n  right: 10px;\n  top: 19px;\n  width: 12px;\n  height: 12px;\n  transform: rotate(90deg);\n"], ["\n  pointer-events: none;\n  position: absolute;\n  right: 10px;\n  top: 19px;\n  width: 12px;\n  height: 12px;\n  transform: rotate(90deg);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
