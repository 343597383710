var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getFieldInfo, getFieldTitle, getFieldValue, } from "../../../../redux/form/form.utils";
import FormInlineSelect from "../FormInlineSelect.container";
var FormInlineSelectJsonFromSchema = /** @class */ (function (_super) {
    __extends(FormInlineSelectJsonFromSchema, _super);
    function FormInlineSelectJsonFromSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        return _this;
    }
    // Render
    FormInlineSelectJsonFromSchema.prototype.render = function () {
        var _a = this.props, name = _a.name, schema = _a.schema, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c;
        var customUiSchema = uiSchema;
        // @ts-ignore
        var _d = schema.options, options = _d === void 0 ? [] : _d;
        var autoSubmit = customUiSchema.autoSubmit, isDisabled = customUiSchema.isDisabled, _e = customUiSchema.isMultiSelect, isMultiSelect = _e === void 0 ? false : _e;
        var disabled = isDisabled
            ? isDisabled(formContext.getAllFormData())
            : undefined;
        var handleSelect = function (newId) {
            var _a;
            var newValue = undefined;
            if (isMultiSelect) {
                if ((_a = formData === null || formData === void 0 ? void 0 : formData.includes) === null || _a === void 0 ? void 0 : _a.call(formData, newId)) {
                    newValue = __spreadArray([], formData, true).filter(function (value) { return value !== newId; });
                }
                else {
                    newValue = __spreadArray(__spreadArray([], (formData || []), true), [newId], false);
                }
            }
            else {
                newValue = newId;
            }
            onChange(newValue);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, newValue);
                }
            }, 0);
            if (autoSubmit && formContext.hasOwnProperty("onSubmitAttempt")) {
                setTimeout(function () {
                    formContext.onSubmitAttempt();
                }, 250);
            }
        };
        var mappedOptions = options.map(function (option) {
            var result = __assign({}, option);
            if (option.getValue) {
                result.value = option.getValue(formContext.getFormData());
            }
            if (option.getSubValue) {
                result.subValue = option.getSubValue(formContext.getFormData());
            }
            return result;
        });
        return (_jsx(FormInlineSelect, __assign({}, customUiSchema, { onPressExtraInfo: this.handlePressExtraInfo, title: getFieldTitle(customUiSchema, formContext), info: getFieldInfo(customUiSchema, formContext), emptyMessage: getFieldValue(customUiSchema, formContext, "emptyMessage"), formData: formContext.getAllFormData(), selected: formData, options: mappedOptions, onSelect: handleSelect, error: rawErrors[0], disabled: disabled })));
    };
    return FormInlineSelectJsonFromSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInlineSelectJsonFromSchema;
