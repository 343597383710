var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ActivityIndicator, Image, Platform, Text, TouchableOpacity, View, } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(View).attrs(function (props) { return ({
    pointerEvents: props.disabled ? "none" : "auto",
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  opacity: ", ";\n  ", "\n"], ["\n  width: 100%;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  opacity: ", ";\n  ", "\n"])), function (props) { return (props.disabled ? 0.5 : 1); }, Platform.select({
    web: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-top: 12px;\n      margin-bottom: 12px;\n    "], ["\n      margin-top: 12px;\n      margin-bottom: 12px;\n    "]))),
}));
export var HeaderWrap = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding-left: 16px;\n  padding-right: 16px;\n  flex-direction: row;\n  align-items: flex-end;\n  align-items: center;\n  ", "\n"], ["\n  padding-left: 16px;\n  padding-right: 16px;\n  flex-direction: row;\n  align-items: flex-end;\n  align-items: center;\n  ", "\n"])), Platform.select({
    web: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      padding-left: 0px;\n      padding-right: 0px;\n    "], ["\n      padding-left: 0px;\n      padding-right: 0px;\n    "]))),
}));
export var HeaderText = styled(Text)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 15px;\n  color: ", ";\n  ", "\n"], ["\n  font-size: 15px;\n  color: ", ";\n  ", "\n"])), function (props) {
    return props.hasError ? props.theme.colors.errorText : props.theme.colors.global5;
}, Platform.select({
    web: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      color: ", ";\n      font-size: 14px;\n      font-weight: 600;\n    "], ["\n      color: ", ";\n      font-size: 14px;\n      font-weight: 600;\n    "])), function (props) {
        // @ts-ignore
        return props.hasError
            ? props.theme.colors.errorText
            : props.theme.colors.global2;
    }),
}));
export var OptionalText = styled(Text)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 10px;\n  margin-top: 2px;\n  margin-left: 8px;\n  font-weight: bold;\n"], ["\n  color: ", ";\n  font-size: 10px;\n  margin-top: 2px;\n  margin-left: 8px;\n  font-weight: bold;\n"])), function (props) { return props.theme.colors.global2; });
export var InfoButton = styled(TouchableOpacity)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  padding-top: 2px;\n  padding-left: 8px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 24px;\n  height: 24px;\n  padding-top: 2px;\n  padding-left: 8px;\n  align-items: center;\n  justify-content: center;\n"])));
export var InfoIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_INFO_CIRCLE_GREEN,
}); })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n"], ["\n  width: 16px;\n  height: 16px;\n"])));
export var Separator = styled(View)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  ", "\n"], ["\n  width: 100%;\n  height: 1px;\n  background-color: ", ";\n  ", "\n"])), function (props) {
    return props.hasError ? props.theme.colors.errorText : props.theme.colors.global3;
}, Platform.select({
    web: css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      height: 0px;\n    "], ["\n      height: 0px;\n    "]))),
}));
// @ts-ignore
export var ErrorText = styled(Text)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  text-align: right;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"], ["\n  text-align: right;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"])), function (props) { return props.theme.colors.errorText; }, Platform.select({
    web: css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      padding-left: 0px;\n      padding-right: 0px;\n      text-align: left;\n    "], ["\n      padding-left: 0px;\n      padding-right: 0px;\n      text-align: left;\n    "]))),
}));
export var InfoText = styled(Text)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  text-align: left;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"], ["\n  text-align: left;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n  ", "\n"])), function (props) { return props.theme.colors.neutral1; }, Platform.select({
    web: css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n      padding-left: 0px;\n      padding-right: 0px;\n    "], ["\n      padding-left: 0px;\n      padding-right: 0px;\n    "]))),
}));
export var ButtonBasicWrap = styled(View)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  margin-top: 0px;\n"], ["\n  margin-top: 0px;\n"])));
export var ContentWrap = styled(View)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  align-items: center;\n  flex-direction: row;\n  ", "\n"], ["\n  align-items: center;\n  flex-direction: row;\n  ", "\n"])), function (props) {
    return props.showBorder
        ? css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n          border: 1px solid ", ";\n          border-radius: 4px;\n          margin-top: 8px;\n        "], ["\n          border: 1px solid ", ";\n          border-radius: 4px;\n          margin-top: 8px;\n        "])), function (props) { return props.theme.colors.global2; }) : [];
});
export var ChildrenWrap = styled(View)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
export var DisclosureIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_DISCLOSURE,
}); })(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n  margin-left: 4px;\n"], ["\n  width: 16px;\n  height: 16px;\n  margin-right: 16px;\n  margin-left: 4px;\n"])));
export var ActivityIndicatorStyled = styled(ActivityIndicator).attrs(function (props) { return ({
    color: props.theme.colors.global5,
}); })(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n"], ["\n  width: 100%;\n  height: 48px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
