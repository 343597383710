import FormDropdownSelect, {
  TId,
} from "@rentiohq/shared-frontend/dist/components/components/FormDropdownSelect/FormDropdownSelect";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import React from "react";
import QuoteOptionBase from "../QuoteOptionBase";
import * as S from "./QuoteOptionMotorVehiclesPremium.styles";

interface IProps {
  quote?: IQuote;
  isCreatingQuote?: boolean;
  onValueChange: (isActive: boolean) => void;
  onAmountOfMotorVehiclesChange: (motorVehiclesAmount: number) => void;
}

const QuoteOptionMotorVehiclesPremium: React.FC<IProps> = (props: IProps) => {
  const {
    quote,
    isCreatingQuote,
    onValueChange,
    onAmountOfMotorVehiclesChange,
  } = props;

  // State
  const [didSetAmount, setDidSetAmount] = React.useState(false);
  const [amount, setAmount] = React.useState(
    quote?.optionMotorVehiclesPremiumAmountOfMotorVehicles ||
      CONFIG.INSURANCE_PREMIUMS.MOTOR_VEHICLES_DEFAULT_AMOUNT_OF_MOTOR_VEHICLES,
  );

  // Lifecycle
  React.useEffect(() => {
    if (!quote) {
      return;
    }

    setAmount(
      quote.optionMotorVehiclesPremiumAmountOfMotorVehicles ||
        CONFIG.INSURANCE_PREMIUMS
          .MOTOR_VEHICLES_DEFAULT_AMOUNT_OF_MOTOR_VEHICLES,
    );
  }, [quote]);

  React.useEffect(() => {
    if (isCreatingQuote) {
      return;
    }

    setDidSetAmount(false);
  }, [isCreatingQuote]);

  // Event handlers
  const handleValueChange = (isActive: boolean) => {
    if (isActive && quote?.optionContentPremium === false) {
      confirm({
        title: getLocalizedText(
          "insurance.options.premium.motor_vehicles.confirm.enable_content.title",
        ),
        primaryActions: [
          {
            title: getLocalizedText(
              "insurance.options.premium.motor_vehicles.confirm.enable_content.cta.confirm",
            ),
            onPress: () => {
              onValueChange?.(isActive);
            },
          },
        ],
        secondaryAction: {
          title: getLocalizedText(
            "insurance.options.premium.motor_vehicles.confirm.enable_content.cta.dismiss",
          ),
        },
        onClose: () => {},
      });

      return false;
    }

    onValueChange(isActive);
  };

  const handleSelect = (newAmount?: TId) => {
    let parsedNewAmount =
      CONFIG.INSURANCE_PREMIUMS.MOTOR_VEHICLES_MIN_AMOUNT_OF_MOTOR_VEHICLES;
    if (typeof newAmount === "string") {
      parsedNewAmount = parseInt(newAmount);
    }

    setAmount(parsedNewAmount);
    onAmountOfMotorVehiclesChange(parsedNewAmount);

    setDidSetAmount(true);
  };

  // Render
  const renderExtraContent = () => {
    if (!quote) {
      return null;
    }

    if (
      !quote.optionMotorVehiclesPremium ||
      !quote.optionMotorVehiclesPremiumValue
    ) {
      return null;
    }

    const options: { id: number; value: string }[] = [];

    const optionsStartIndex =
      CONFIG.INSURANCE_PREMIUMS.MOTOR_VEHICLES_MIN_AMOUNT_OF_MOTOR_VEHICLES + 1;
    const optionsStopIndex =
      CONFIG.INSURANCE_PREMIUMS.MOTOR_VEHICLES_MAX_AMOUNT_OF_MOTOR_VEHICLES;

    for (let index = optionsStartIndex; index <= optionsStopIndex; index++) {
      options.push({ id: index, value: `${index}` });
    }

    return (
      <S.Wrap>
        <S.DropdownWrap>
          <FormDropdownSelect
            title={getLocalizedText(
              "insurance.options.premium.motor_vehicles.motor_vehicles_amount",
            )}
            selected={amount}
            options={options}
            onSelect={handleSelect}
            onPressExtraInfo={() => {}}
            placeholder={`${CONFIG.INSURANCE_PREMIUMS.MOTOR_VEHICLES_MIN_AMOUNT_OF_MOTOR_VEHICLES}`}
          />
          {didSetAmount && (
            <S.ActivityIndicatorDropdownWrap>
              <S.ActivityIndicatorDropdown />
            </S.ActivityIndicatorDropdownWrap>
          )}
        </S.DropdownWrap>
        <S.LegalInfo
          dangerouslySetInnerHTML={{
            __html: getLocalizedText(
              "insurance.options.premium.motor_vehicles.legal_info",
            ),
          }}
        />
      </S.Wrap>
    );
  };

  return (
    <QuoteOptionBase
      quote={quote}
      icon={assets.ICO_CAR}
      title={getLocalizedText("insurance.options.premium.motor_vehicles.title")}
      info={getLocalizedText("insurance.options.premium.motor_vehicles.info")}
      price={quote?.optionMotorVehiclesPremiumValue}
      isCreatingQuote={isCreatingQuote}
      isActive={quote?.optionMotorVehiclesPremium || false}
      onValueChange={handleValueChange}
      renderExtraContent={renderExtraContent}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteOptionMotorVehiclesPremium;
