var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as paymentActions from "./payment.actions";
export var REDUCER_KEY_PAYMENT = "payment";
var getDefaultState = function () { return ({
    payments: {},
    paymentsById: {},
    paymentsByQuoteId: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(paymentActions.getPayment.types.START, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, paymentId = _a.paymentId;
    return (__assign(__assign({}, state), { paymentsById: __assign(__assign({}, state.paymentsById), (_b = {}, _b[paymentId] = __assign(__assign({}, state.paymentsById[paymentId]), { leadId: leadId, quoteId: quoteId, isFetching: true, fetchError: undefined }), _b)) }));
});
reducer.on(paymentActions.getPayment.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var leadId = _a.leadId, quoteId = _a.quoteId, paymentId = _a.paymentId, payment = _a.payment;
    return (__assign(__assign({}, state), { payments: __assign(__assign({}, state.payments), (_b = {}, _b[paymentId] = payment, _b)), paymentsById: __assign(__assign({}, state.paymentsById), (_c = {}, _c[paymentId] = __assign(__assign({}, state.paymentsById[paymentId]), { leadId: leadId, quoteId: quoteId, isFetching: false, fetchError: undefined }), _c)), paymentsByQuoteId: __assign(__assign({}, state.paymentsByQuoteId), (_d = {}, _d[quoteId] = __assign(__assign({}, state.paymentsByQuoteId[quoteId]), { activePaymentId: paymentId, isCreating: false, createError: undefined }), _d)) }));
});
reducer.on(paymentActions.getPayment.types.FAILURE, function (state, _a) {
    var _b;
    var leadId = _a.leadId, quoteId = _a.quoteId, paymentId = _a.paymentId, error = _a.error;
    return (__assign(__assign({}, state), { paymentsById: __assign(__assign({}, state.paymentsById), (_b = {}, _b[paymentId] = __assign(__assign({}, state.paymentsById[paymentId]), { leadId: leadId, quoteId: quoteId, isFetching: false, fetchError: error }), _b)) }));
});
reducer.on(paymentActions.createPayment.types.START, function (state, _a) {
    var _b;
    var quoteId = _a.quoteId;
    return (__assign(__assign({}, state), { paymentsByQuoteId: __assign(__assign({}, state.paymentsByQuoteId), (_b = {}, _b[quoteId] = __assign(__assign({}, state.paymentsByQuoteId[quoteId]), { isCreating: true, createError: undefined }), _b)) }));
});
reducer.on(paymentActions.createPayment.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var quoteId = _a.quoteId, payment = _a.payment;
    return (__assign(__assign({}, state), { payments: __assign(__assign({}, state.payments), (_b = {}, _b[payment.id] = payment, _b)), paymentsByQuoteId: __assign(__assign({}, state.paymentsByQuoteId), (_c = {}, _c[quoteId] = __assign(__assign({}, state.paymentsByQuoteId[quoteId]), { activePaymentId: payment.id, isCreating: false, createError: undefined }), _c)) }));
});
reducer.on(paymentActions.createPayment.types.FAILURE, function (state, _a) {
    var _b;
    var quoteId = _a.quoteId, error = _a.error;
    return (__assign(__assign({}, state), { paymentsByQuoteId: __assign(__assign({}, state.paymentsByQuoteId), (_b = {}, _b[quoteId] = __assign(__assign({}, state.paymentsByQuoteId[quoteId]), { isCreating: false, createError: error }), _b)) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
