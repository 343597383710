import ButtonPrimary from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as leadApi from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.api";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { ELeadStatus } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import {
  isBefore,
  subDays,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import {
  getLocale,
  getLocalizedText,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import PropertyInfoBanner from "components/PropertyInfoBanner";
import { RentioInternalRenderer } from "components/RentioInternalRenderer";
import InfoCircle from "modules/general/components/InfoCircle";
import ErrorLead from "modules/request/components/ErrorLead";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import * as S from "./Home.styles";

const PAYMENT_STATUSES = [ELeadStatus.Signed, ELeadStatus.Paying];

const SUCCESS_STATUSES = [
  ELeadStatus.Paid,
  ELeadStatus.SentToBaloise,
  ELeadStatus.ProcessedByBaloise,
];

interface IProps {}

const Home: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();
  const { leadId, publicId, quoteId } = useInsuranceQueryParams();
  const { lead, isFetching, fetchError, storeUpdatedLead } = useGetLead({
    leadId,
    publicId,
  });
  const { quote } = useGetQuote({
    leadId,
    quoteId: quoteId || lead?.activeQuoteId,
    publicId,
  });

  // Event handlers
  const handleClickLead = async () => {
    let leadToHandle = lead;

    if (!leadToHandle) {
      showAlert({
        type: "info",
        message: getLocalizedText("insurance.home.cta.start.alert.impossible"),
      });

      return;
    }

    if (!leadId || !publicId) {
      return;
    }

    if (
      leadToHandle.status === ELeadStatus.New ||
      leadToHandle.status === ELeadStatus.TechnicalError
    ) {
      const locale = getLocale();
      if (locale !== ELocale.INTERNAL) {
        await leadApi.updateLead(leadId, { locale }, publicId);
      }

      const { data: startedLead } = await leadApi.startLead(leadId, publicId);

      storeUpdatedLead(startedLead);

      if (startedLead.status === ELeadStatus.New) {
        showAlert({
          type: "error",
          message: getLocalizedText("system.error"),
        });
        return;
      }

      leadToHandle = startedLead;
    }

    if (leadToHandle.status === ELeadStatus.TechnicalError) {
      navigate(
        appendQueryParams({
          path: "/request/address-matcher",
          queryParams: { leadId, publicId },
        }),
      );

      return;
    }

    if (
      [...PAYMENT_STATUSES, ...SUCCESS_STATUSES].includes(leadToHandle.status)
    ) {
      return;
    }

    // Active quote is 10 days or older => options
    const NOW = subDays(new Date(), 10);
    if (quote?.createdAt && isBefore(quote.createdAt, NOW)) {
      navigate(
        appendQueryParams({
          path: "/request/options",
          queryParams: {
            leadId,
            publicId,
          },
        }),
      );

      return;
    }

    // Is already configured => overview
    if (quoteId || leadToHandle.activeQuoteId) {
      navigate(
        appendQueryParams({
          path: "/request/overview",
          queryParams: {
            leadId,
            publicId,
            quoteId: quoteId || leadToHandle.activeQuoteId,
          },
        }),
      );

      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/property",
        queryParams: { leadId, publicId },
      }),
    );
  };

  // Render
  const renderLead = () => {
    if (!lead) {
      return;
    }

    return (
      <>
        <PropertyInfoBanner lead={lead} displayMobileViewSameAsDesktop={true} />
        <Spacer weight={ESpacerWeight.W24} />
        <ButtonPrimary
          title={getLocalizedText("insurance.home.cta.start")}
          onPress={handleClickLead}
        />
        <RentioInternalRenderer items={{ status: lead.status }} />
      </>
    );
  };

  const renderEmpty = () => {
    return (
      <>
        <ButtonPrimary
          title={getLocalizedText("insurance.home.cta.start")}
          onPress={handleClickLead}
        />
      </>
    );
  };

  const renderIsFetching = () => {
    if (!isFetching) {
      return;
    }

    return <ActivityIndicator />;
  };

  const renderError = () => {
    if (!fetchError) {
      return;
    }

    return <ErrorLead errorLead={fetchError} displayCompact={true} />;
  };

  return (
    <>
      {lead && SUCCESS_STATUSES.includes(lead.status) && (
        <Navigate
          to={appendQueryParams({
            path: "/request/success",
            queryParams: {
              leadId,
              publicId,
              quoteId: lead.activeQuoteId,
            },
          })}
        />
      )}

      {lead && PAYMENT_STATUSES.includes(lead.status) && (
        <Navigate
          to={appendQueryParams({
            path: "/request/payment",
            queryParams: {
              leadId,
              publicId,
              quoteId: lead.activeQuoteId,
            },
          })}
        />
      )}

      <S.HeaderWrap>
        <S.HeaderInnerWrap>
          <S.HeaderInfo>
            {!fetchError && (
              <S.HeaderInfoTitle>
                {getLocalizedText("insurance.home.title")}
              </S.HeaderInfoTitle>
            )}
            {renderLead() ||
              renderIsFetching() ||
              renderError() ||
              renderEmpty()}
          </S.HeaderInfo>
          <S.HeaderVisual src={assets.IMG_SPLASH} />
        </S.HeaderInnerWrap>
      </S.HeaderWrap>
      <S.SectionBackgroundWrap>
        <S.SectionWrap>
          <S.SectionInnerWrap>
            <S.SectionTitle isInverted={false}>
              {getLocalizedText("insurance.home.features.basic.title")}
            </S.SectionTitle>
            <S.CirclesWrap>
              {[
                {
                  icon: assets.ICO_HOUSE_FIRE,
                  key: "insurance.home.feature.fire",
                },
                {
                  icon: assets.ICO_STORM,
                  key: "insurance.home.feature.storm",
                },
                {
                  icon: assets.ICO_WATER,
                  key: "insurance.home.feature.water",
                },
                {
                  icon: assets.ICO_OIL,
                  key: "insurance.home.feature.oil",
                },
                {
                  icon: assets.ICO_ELECTRICITY,
                  key: "insurance.home.feature.electricity",
                },
                {
                  icon: assets.ICO_WINDOWS_BRAND_COLOR,
                  key: "insurance.home.feature.windows",
                },
              ].map(item => (
                <InfoCircle
                  key={item.key}
                  icon={item.icon}
                  title={getLocalizedText(item.key)}
                  extraInfo={getLocalizedText(`${item.key}.info`)}
                />
              ))}
            </S.CirclesWrap>
          </S.SectionInnerWrap>
        </S.SectionWrap>
      </S.SectionBackgroundWrap>

      <S.SectionWrap>
        <S.SectionInnerWrap>
          <S.SectionTitle isInverted={true}>
            <span
              dangerouslySetInnerHTML={{
                __html: getLocalizedText(
                  "insurance.home.features.extended.title",
                ),
              }}
            />
          </S.SectionTitle>
          <S.CirclesWrap>
            {[
              {
                icon: assets.ICO_SANITARY,
                key: "insurance.home.feature.extended.sanitary",
              },
              {
                icon: assets.ICO_WINDOWS_WHITE,
                key: "insurance.home.feature.extended.windows",
              },
            ].map(item => (
              <InfoCircle
                icon={item.icon}
                title={getLocalizedText(`${item.key}.title`)}
                subtitle={getLocalizedText(`${item.key}.subtitle`)}
                // extraInfo={getLocalizedText(`${item.key}.info`)}
                isInverted={true}
                circleSize={125}
              />
            ))}
          </S.CirclesWrap>
          <S.SectionInfo>
            <span
              dangerouslySetInnerHTML={{
                __html: getLocalizedText(
                  "insurance.home.features.extended.footer",
                  {
                    coverageUrl: getLocalizedText(
                      "insurance.footer.legal.coverage.url",
                    ),
                    termsUrl: getLocalizedText(
                      "insurance.footer.legal.terms.url",
                    ),
                    infoUrl: getLocalizedText(
                      "insurance.footer.legal.info.url",
                    ),
                  },
                ),
              }}
            />
          </S.SectionInfo>
        </S.SectionInnerWrap>
      </S.SectionWrap>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Home;
