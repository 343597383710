import NotFound from "modules/general/routes/NotFound";
import { Route } from "react-router-dom";
import AddressMatcher from "./routes/AddressMatcher";
import ConnectiveRedirect from "./routes/ConnectiveRedirect";
import ContactInfo from "./routes/ContactInfo";
import Mailed from "./routes/Mailed";
import Options from "./routes/Options";
import Overview from "./routes/Overview";
import Payment from "./routes/Payment";
import Pending from "./routes/Pending";
import Property from "./routes/Property";
import Signature from "./routes/Signature";
import Success from "./routes/Success";

export const getRequestScene = () => (
  <Route path="request">
    <Route path={"address-matcher"} element={<AddressMatcher />} />

    <Route path={"property"} element={<Property />} />
    <Route path={"options"} element={<Options />} />
    <Route path={"contact"} element={<ContactInfo />} />

    <Route path={"overview"} element={<Overview />} />

    <Route path={"mailed"} element={<Mailed />} />

    <Route path={"signature"} element={<Signature />} />
    <Route path={"payment"} element={<Payment />} />

    <Route path={"pending"} element={<Pending />} />

    <Route path={"success"} element={<Success />} />

    <Route
      path={"connective-redirect/:publicId/:leadId/:quoteId"}
      element={<ConnectiveRedirect />}
    />

    <Route path="*" element={<NotFound />} />
  </Route>
);
