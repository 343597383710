import { round } from "lodash";
import { add, bignumber, subtract } from "mathjs";
export var addWithDecimalPrecision = function (x, y) {
    return add(bignumber(x), bignumber(y));
};
export var subtractWithDecimalPrecision = function (x, y) {
    return subtract(bignumber(x), bignumber(y));
};
export var formatNumber = function (input, allowDecimalZeros, platform) {
    if (allowDecimalZeros === void 0) { allowDecimalZeros = true; }
    if (platform === void 0) { platform = "web"; }
    if (platform === "android") {
        // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
        return input.toLocaleString("nl").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    // TODO: Fix for Android (Hermes has no Intl support)
    // https://github.com/facebook/hermes/issues/23
    var formattedAmount = Intl.NumberFormat("nl-BE", {
        minimumFractionDigits: allowDecimalZeros ? 2 : 0,
        maximumFractionDigits: 12, // This value is trivially chosen to show all decimals used in Rentio
    }).format(input);
    return formattedAmount;
};
export var formatCurrency = function (input, allowDecimalZeros, currencySymbol) {
    if (allowDecimalZeros === void 0) { allowDecimalZeros = true; }
    if (currencySymbol === void 0) { currencySymbol = "€"; }
    var formattedNumber = formatNumber(input, allowDecimalZeros);
    if (currencySymbol.length === 0) {
        return formattedNumber;
    }
    return "".concat(currencySymbol, " ").concat(formattedNumber);
};
export var formatBytes = function (bytes, decimals) {
    if (decimals === void 0) { decimals = 2; }
    if (bytes === 0) {
        return "0 Bytes";
    }
    var k = 1000;
    var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var sizeIndex = Math.floor(Math.log(bytes) / Math.log(k));
    var size = parseFloat((bytes / Math.pow(k, sizeIndex)).toFixed(decimals));
    return "".concat(size, " ").concat(sizes[sizeIndex]);
};
export var getVat = function (amount, vatPercentage) {
    var amountWithVat = round(amount * (1 + vatPercentage), 2);
    return round(amountWithVat - amount, 2);
};
export var getAmountWithVat = function (amount, vatPercentage) {
    return round(amount * (1 + vatPercentage), 2);
};
export var getAmountWithoutVat = function (amount, vatPercentage) {
    return round(amount / (1 + vatPercentage), 2);
};
