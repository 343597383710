var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Form from "../Form";
var FormMultistep = /** @class */ (function (_super) {
    __extends(FormMultistep, _super);
    function FormMultistep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePress = function (event) {
            var _a = _this.props, didAccessFromSummary = _a.didAccessFromSummary, withSummary = _a.withSummary;
            if (didAccessFromSummary) {
                return _this.handlePressUpdate(event);
            }
            if (_this.hasNextStep(event.formData)) {
                return _this.handlePressNext(event);
            }
            if (withSummary) {
                return _this.handlePressViewSummary(event);
            }
            return _this.handlePressSubmit(event);
        };
        // INFO: Only used when fields are added or removed in order to prevent state of new schema being lost
        _this.handleSchemaChange = function (_a) {
            var formData = _a.formData;
            _this.storePartialFormData(formData);
        };
        _this.handleChange = function (_a) {
            var formData = _a.formData;
            if (_this.props.onChange)
                _this.props.onChange(formData);
        };
        _this.handlePressUpdate = function (_a) {
            var formData = _a.formData;
            _this.storePartialFormData(formData, _this.stepsToClearOnSubmitFromSummary());
            _this.props.onPressUpdate();
        };
        _this.handlePressNext = function (_a) {
            var formData = _a.formData;
            _this.storePartialFormData(formData);
            _this.props.onPressNext(_this.getNextStepIndex(formData));
        };
        _this.handlePressViewSummary = function (_a) {
            var formData = _a.formData;
            _this.storePartialFormData(formData);
            _this.props.onPressViewSummary();
        };
        _this.handlePressSubmit = function (_a) {
            var _b, _c;
            var formData = _a.formData;
            (_c = (_b = _this.props).onPrePressSubmit) === null || _c === void 0 ? void 0 : _c.call(_b);
            _this.storePartialFormData(formData);
            // TODO: Action not yet handled after onPressSubmit fires other action
            setTimeout(function () {
                _this.props.onPressSubmit();
            }, 100);
        };
        _this.handleSelectMore = function (fieldName, fieldValue, formData, extraData) {
            _this.storePartialFormData(formData);
            var _a = _this.props, onSelectMore = _a.onSelectMore, formId = _a.formId, stepIndex = _a.stepIndex, schemas = _a.schemas;
            if (onSelectMore) {
                onSelectMore(formId, stepIndex, fieldName, fieldValue, (schemas || [])[stepIndex].uiSchema[fieldName], _this.props.mergedPartialFormData, extraData);
            }
        };
        _this.getFormData = function () { return _this.props.mergedPartialFormData; };
        // Data
        _this.storePartialFormData = function (formData, stepsToClearOnSubmitFromSummary) {
            var _a = _this.props, formId = _a.formId, stepIndex = _a.stepIndex;
            _this.props.storePartialFormData({
                formId: formId,
                stepIndex: stepIndex,
                formData: formData,
                stepsToClearOnSubmitFromSummary: stepsToClearOnSubmitFromSummary,
            });
        };
        // Helpers
        _this.hasNextStep = function (formData) {
            var nextStepIndex = _this.getNextStepIndex(formData);
            return nextStepIndex !== -1;
        };
        _this.getNextStepIndex = function (formData) {
            var _a = _this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, stepIndex = _a.stepIndex, mergedPartialFormDataPreviousSteps = _a.mergedPartialFormData;
            var mergedPartialFormData = __assign(__assign({}, mergedPartialFormDataPreviousSteps), formData);
            var remainingSchemas = schemas.slice(stepIndex + 1);
            var nextSchemaIndex = remainingSchemas.findIndex(function (_a) {
                var showIf = _a.showIf;
                if (!showIf) {
                    return true;
                }
                var showIfx = showIf(mergedPartialFormData);
                return showIfx;
            });
            if (nextSchemaIndex === -1) {
                return -1;
            }
            return stepIndex + nextSchemaIndex + 1;
        };
        _this.stepsToClearOnSubmitFromSummary = function () {
            var _a = _this.props, schemas = _a.schemas, stepIndex = _a.stepIndex;
            if (!schemas) {
                return;
            }
            var schemaFunction = schemas[stepIndex].schema;
            if (!schemaFunction) {
                return;
            }
            var schema = schemaFunction({});
            var stepsToClearOnSubmitFromSummary = undefined;
            // @ts-ignore
            if (schema.stepsToClearOnSubmitFromSummary) {
                stepsToClearOnSubmitFromSummary =
                    // @ts-ignore
                    schema.stepsToClearOnSubmitFromSummary(stepIndex);
            }
            return stepsToClearOnSubmitFromSummary;
        };
        return _this;
    }
    // Render
    FormMultistep.prototype.render = function () {
        var _a = this.props, stepIndex = _a.stepIndex, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, mergedPartialFormData = _a.mergedPartialFormData, partialFormData = _a.partialFormData, formProps = _a.formProps, onPressExtraInfo = _a.onPressExtraInfo, onError = _a.onError, onPressNext = _a.onPressNext, formId = _a.formId;
        if (!schemas || schemas.length === 0) {
            return null;
        }
        var firstSchemaIndex = schemas.findIndex(function (_a) {
            var showIf = _a.showIf;
            if (!showIf) {
                return true;
            }
            var showIfx = showIf(mergedPartialFormData);
            return showIfx;
        });
        if (stepIndex === 0 && firstSchemaIndex !== stepIndex) {
            onPressNext(firstSchemaIndex);
        }
        return (_jsx(Form, __assign({}, formProps, { formId: formId, stepIndex: stepIndex, schema: schemas[stepIndex].schema, validate: schemas[stepIndex].validate, mergedPartialFormData: mergedPartialFormData, partialFormData: partialFormData, uiSchema: schemas[stepIndex].uiSchema, onSubmit: this.handlePress, onChange: this.handleChange, onSchemaChange: this.handleSchemaChange, onSelectMore: this.handleSelectMore, onPressExtraInfo: onPressExtraInfo, getFormData: this.getFormData, onError: onError })));
    };
    return FormMultistep;
}(React.Component));
// eslint-disable-next-line import/no-default-export
export default FormMultistep;
