import { Platform } from "react-native";
import { ERentioFormField, ERentioFormFormat, } from "../../components/components/Form";
import { EContactCustomId } from "../../redux/contact/contact.types";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getMappedLanguages, getMappedNationalities, } from "../../utils/location/location.utils";
import { CONTACT_AGE_RANGE_MAX, CONTACT_AGE_RANGE_MIN, } from "./schema.addContact.constants";
import { EField } from "./schema.addContact.types";
import { getNavigationTitle } from "./schema.addContact.utils";
var isNewFinancialContact = function (_a) {
    var customContactId = _a.customContactId, contact = _a.contact;
    return function () {
        return customContactId === EContactCustomId.Financial && !contact;
    };
};
export var EIdCardOptions;
(function (EIdCardOptions) {
    EIdCardOptions["BelgianIdCardNumber"] = "belgian_id_card_number";
    EIdCardOptions["PassportNumber"] = "passport_number";
})(EIdCardOptions || (EIdCardOptions = {}));
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [
                EField.Firstname,
                EField.Lastname,
                EField.Email,
                EField.Phone,
                EField.Language,
                EField.DateOfBirth,
                EField.Nationality,
                EField.Address,
            ],
            properties: (_a = {},
                _a[EField.Header] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function () { return Platform.OS !== "web"; },
                },
                _a[EField.Language] = {
                    default: getValue(EField.Language, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedLanguages(),
                },
                _a[EField.Firstname] = {
                    default: getValue(EField.Firstname, initialValues, defaultValues),
                },
                _a[EField.Lastname] = {
                    default: getValue(EField.Lastname, initialValues, defaultValues),
                },
                _a[EField.Email] = {
                    default: getValue(EField.Email, initialValues, defaultValues),
                },
                _a[EField.Phone] = {
                    default: getValue(EField.Phone, initialValues, defaultValues),
                },
                _a[EField.Address] = {
                    default: getValue(EField.Address, initialValues, defaultValues),
                },
                _a[EField.DateOfBirth] = {
                    default: getValue(EField.DateOfBirth, initialValues, defaultValues),
                },
                _a[EField.Nationality] = {
                    default: getValue(EField.Nationality, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedNationalities(),
                },
                _a[EField.IdType] = {
                    default: getValue(EField.IdType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        EIdCardOptions.BelgianIdCardNumber,
                        EIdCardOptions.PassportNumber,
                    ].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.national_register_number.".concat(id)),
                    }); }),
                    showIf: function (formData) { return formData[EField.Nationality] !== "BE"; },
                },
                _a[EField.NationalRegisterNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] === "BE" ||
                            (formData[EField.Nationality] !== "BE" &&
                                formData[EField.IdType] === EIdCardOptions.BelgianIdCardNumber);
                    },
                    format: ERentioFormFormat.NationalRegisterNumber,
                    default: getValue(EField.NationalRegisterNumber, initialValues, defaultValues),
                },
                _a[EField.ForeignIdentityNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    default: getValue(EField.ForeignIdentityNumber, initialValues, defaultValues),
                },
                _a[EField.BIVNumber] = {
                    default: getValue(EField.BIVNumber, initialValues, defaultValues),
                },
                _a[EField.IdentityFrontDocument] = {
                    default: getValue(EField.IdentityFrontDocument, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: isNewFinancialContact(extraData),
                    // @ts-ignore
                    requiredIf: isNewFinancialContact(extraData),
                },
                _a[EField.IdentityBackDocument] = {
                    default: getValue(EField.IdentityBackDocument, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: isNewFinancialContact(extraData),
                    // @ts-ignore
                    requiredIf: isNewFinancialContact(extraData),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:trackingKey": "contact_contact"
        },
        _a[EField.Header] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText(extraData.contactId ? "contact.edit" : "contact.add"),
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.firstname"),
            placeholder: getLocalizedText("system.firstname"),
            autoFocus: true,
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.lastname"),
            placeholder: getLocalizedText("system.lastname"),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: getLocalizedText("system.email"),
            placeholder: getLocalizedText("system.email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: getLocalizedText("system.phone"),
            placeholder: getLocalizedText("system.phone"),
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.address"),
            placeholder: getLocalizedText("system.address"),
            optional: false,
        },
        _a[EField.Language] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.language"),
            placeholder: getLocalizedText("system.language"),
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("system.date_of_birth"),
            minDate: CONTACT_AGE_RANGE_MIN,
            minDateError: "date_picker.alert.too_old.description",
            maxDate: CONTACT_AGE_RANGE_MAX,
            maxDateError: "date_picker.alert.too_young.description",
            formatSummary: function (formData) { return (formData ? formatDate(formData) : undefined); },
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.nationality"),
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("system.identification"),
            //info: getLocalizedText("system.identification.infotext"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number.foreigner"),
        },
        _a[EField.BIVNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.biv_number"),
            optional: true,
        },
        _a[EField.IdentityFrontDocument] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            formatSummary: function (formData) {
                return formData === null || formData === void 0 ? void 0 : formData.map(function (doc) { return doc.name; }).join(", ");
            },
            accept: ["image/*"],
            multiple: false,
            title: getLocalizedText("contact.form.identity_documents.front_document.label"),
        },
        _a[EField.IdentityBackDocument] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            formatSummary: function (formData) {
                return formData === null || formData === void 0 ? void 0 : formData.map(function (doc) { return doc.name; }).join(", ");
            },
            accept: ["image/*"],
            multiple: false,
            title: getLocalizedText("contact.form.identity_documents.back_document.label"),
        },
        _a);
};
