import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import React from "react";
import QuoteOptionBase from "../QuoteOptionBase";

interface IProps {
  quote?: IQuote;
  isCreatingQuote?: boolean;
  onValueChange: (isActive: boolean) => void;
}

const QuoteOptionLegalAssistancePremium: React.FC<IProps> = (props: IProps) => {
  const { quote, isCreatingQuote, onValueChange } = props;

  return (
    <QuoteOptionBase
      quote={quote}
      icon={assets.ICO_LAW}
      title={getLocalizedText(
        "insurance.options.premium.legal_assistance.title",
      )}
      info={getLocalizedText("insurance.options.premium.legal_assistance.info")}
      price={quote?.optionLegalAssistancePremiumValue}
      isCreatingQuote={isCreatingQuote}
      isActive={quote?.optionLegalAssistancePremium || false}
      onValueChange={onValueChange}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteOptionLegalAssistancePremium;
