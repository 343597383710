import { REDUCER_KEY_QUOTE } from "./quote.reducer";
export var getQuote = function (state, quoteId) {
    return state.quote.quotes[quoteId];
};
export var isFetchingQuote = function (state, quoteId) { var _a; return ((_a = state.quote.quotesById[quoteId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchQuoteError = function (state, quoteId) { var _a; return (_a = state.quote.quotesById[quoteId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getActiveQuoteByLeadId = function (state, leadId) {
    var _a;
    var quoteId = (_a = state.quote.quotesByLeadId[leadId]) === null || _a === void 0 ? void 0 : _a.activeQuoteId;
    if (!quoteId) {
        return;
    }
    return state.quote.quotes[quoteId];
};
export var isCreatingQuote = function (state, leadId) { var _a; return ((_a = state.quote.quotesByLeadId[leadId]) === null || _a === void 0 ? void 0 : _a.isCreating) || false; };
export var createQuoteError = function (state, leadId) { var _a; return (_a = state.quote.quotesByLeadId[leadId]) === null || _a === void 0 ? void 0 : _a.createError; };
