import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import assets from "assets";
import React from "react";
import QuoteOptionBase from "../QuoteOptionBase";

interface IProps {
  quote?: IQuote;
  isCreatingQuote?: boolean;
  onValueChange: (isActive: boolean) => void;
}

const QuoteOptionTheftPremium: React.FC<IProps> = (props: IProps) => {
  const { quote, isCreatingQuote, onValueChange } = props;

  // Event handlers
  const handleValueChange = (isActive: boolean) => {
    if (isActive && quote?.optionContentPremium === false) {
      confirm({
        title: getLocalizedText(
          "insurance.options.premium.theft.confirm.enable_content.title",
        ),
        primaryActions: [
          {
            title: getLocalizedText(
              "insurance.options.premium.theft.confirm.enable_content.cta.confirm",
            ),
            onPress: () => {
              onValueChange?.(isActive);
            },
          },
        ],
        secondaryAction: {
          title: getLocalizedText(
            "insurance.options.premium.theft.confirm.enable_content.cta.dismiss",
          ),
        },
        onClose: () => {},
      });

      return false;
    }

    onValueChange(isActive);
  };

  // Render
  return (
    <QuoteOptionBase
      quote={quote}
      icon={assets.ICO_THEFT}
      title={getLocalizedText("insurance.options.premium.theft.title")}
      info={getLocalizedText("insurance.options.premium.theft.info")}
      price={quote?.optionTheftPremiumValue}
      isCreatingQuote={isCreatingQuote}
      isActive={quote?.optionTheftPremium || false}
      onValueChange={handleValueChange}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteOptionTheftPremium;
