import FormSectionHeader from "@rentiohq/shared-frontend/dist/components/components/FormSectionHeader";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as formActions from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import FormMultistep from "components/FormMultistep";
import InfoList from "components/InfoList";
import PropertyInfoBanner from "components/PropertyInfoBanner";
import { getSteps } from "modules/request/config/progressBar.config";
import React from "react";
import { ActivityIndicator } from "react-native";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import ProgressBar from "../../components/ProgressBar";
import schemas from "../../forms/propertyInfo";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "../../forms/propertyInfo/schema.propertyInfo.actions";
import * as S from "./Property.styles";

interface IProps {}

const Property: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();
  const { lead, isFetching, fetchError } = useGetLead({
    leadId,
    publicId,
  });

  // Redux
  const dispatch = useDispatch();

  // Form
  const [formId] = React.useState(generateFormId());
  const [isFormInitialized, setIsFormInitialized] = React.useState(false);

  // Lifecycle
  React.useEffect(() => {
    if (!lead || isFormInitialized) {
      return;
    }

    dispatch(
      formActions.createForm({
        formId,
        // @ts-ignore
        schemas: schemas({ lead, publicId }),
        withSummary: false,
      }),
    );

    setIsFormInitialized(true);
  }, [formId, isFormInitialized, lead, publicId]);

  // Event handlers
  const handleSuccess = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/options",
        queryParams: { leadId, quoteId, publicId },
      }),
    );
  };

  // Render
  if (!leadId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetching) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchError) {
      return;
    }

    return <ErrorLead errorLead={fetchError} />;
  };

  const renderLead = () => {
    if (!lead) {
      return;
    }

    if (!isFormInitialized) {
      return renderLoading();
    }

    return (
      <>
        <FormSectionHeader title={getLocalizedText("system.model.property")} />
        <PropertyInfoBanner lead={lead} displayMobileViewSameAsDesktop={true} />

        <Spacer weight={ESpacerWeight.W32} />

        <FormMultistep
          formId={formId}
          // @ts-ignore state match
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            leadId,
          )}
          // @ts-ignore state match
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            leadId,
          )}
          submitActionCreator={submitActionCreator({ lead, publicId })}
          customSuccessAction={handleSuccess}
          submitTitle={getLocalizedText("system.next")}
        />

        <Spacer weight={ESpacerWeight.W32} />

        <InfoList
          title={getLocalizedText(
            "form.request_insurance.info_not_allowed.title",
          )}
          info={getLocalizedText(
            "form.request_insurance.info_not_allowed.info",
          )}
        />
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        <ProgressBar steps={getSteps()} currentStep={0} />

        <Spacer weight={ESpacerWeight.W32} />

        {renderLead() || renderIsFetching() || renderError() || renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Property;
