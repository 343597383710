var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Text, View } from "react-native";
import { CONFIG } from "../../../config/app.config";
import { EProduct } from "../../../config/app.config.types";
import { desktopStyles } from "../../../utils/style.utils";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(View)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  margin: 16px 0;\n  padding: 0 16px;\n  ", "\n"], ["\n  width: 100%;\n  margin: 16px 0;\n  padding: 0 16px;\n  ", "\n"])), function () {
    return CONFIG.PRODUCT !== EProduct.App
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          margin-top: 24px;\n          padding: 0px;\n        "], ["\n          margin-top: 24px;\n          padding: 0px;\n        "]))) : [];
});
export var Line = styled(View)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  margin-top: 8px;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 1px;\n  margin-top: 8px;\n  background-color: ", ";\n"])), function (props) { return props.theme.colors.global3; });
export var Title = styled(Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 19px;\n  font-weight: bold;\n  color: ", ";\n  font-family: ", ";\n"], ["\n  font-size: 19px;\n  font-weight: bold;\n  color: ", ";\n  font-family: ", ";\n"])), function (props) { return props.theme.colors.neutral1; }, function (props) { return props.theme.fonts.primary; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
