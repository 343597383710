import styled, {
  css,
} from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { ILead } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import assets from "assets";

export const Wrap = styled.div<{ displayMobileViewSameAsDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    ${props =>
      props.displayMobileViewSameAsDesktop
        ? css``
        : css`
            flex-direction: column;
            text-align: center;
          `}
  }
`;

// @ts-ignore
export const PropertyLogo = styled.img.attrs((props: { lead: ILead }) => ({
  src:
    props.lead.propertyType === 3
      ? assets.ICO_PROPERTY_APARTMENT
      : assets.ICO_PROPERTY_HOUSE,
}))<{ lead: ILead }>`
  width: 60px;
  height: 60px;
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 3px;
  color: ${props => props.theme.colors.global1};
`;

export const Subtitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.colors.global2};
`;
