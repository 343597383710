export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Header2"] = "_header2";
    EField["InitialType"] = "initialType";
    EField["Company"] = "company";
    EField["VATNumber"] = "VATNumber";
    EField["Firstname"] = "firstname";
    EField["Lastname"] = "lastname";
    EField["Email"] = "email";
    EField["Phone"] = "phone";
    EField["PrefillAddress"] = "PrefillAddress";
    EField["Address"] = "address";
    EField["Language"] = "locale";
    EField["DateOfBirth"] = "dateOfBirth";
    EField["PlaceOfBirth"] = "placeOfBirth";
    EField["Nationality"] = "nationality";
    EField["IdType"] = "idType";
    EField["NationalRegisterNumber"] = "nationalRegisterNumber";
    EField["ForeignIdentityNumber"] = "foreignIdentityNumber";
    EField["BIVNumber"] = "bivNumber";
    EField["IdentityFrontDocument"] = "IDCardFrontside";
    EField["IdentityBackDocument"] = "IDCardBackside";
    EField["SendInvite"] = "sendInvite";
})(EField || (EField = {}));
