import {
  getDay,
  getMonth,
  getYear,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";

export const apiDateWithoutTimeToDate = (input: string) => new Date(input);

export const dateToApiDateWithoutTime = (input: Date) => {
  const year = getYear(input);
  const month = getMonth(input) + 1;
  const day = getDay(input);

  return `${year}-${month}-${day}`;
};
