import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 330px;
  margin: 0 auto;
`;

export const InnerWrap = styled.div`
  padding: 40px 24px;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    padding: 30px 24px;
  }
`;
