import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as formActions from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import FormMultistep from "components/FormMultistep";
import QuoteInfoBanner from "components/QuoteInfoBanner";
import { getSteps } from "modules/request/config/progressBar.config";
import React from "react";
import { ActivityIndicator } from "react-native";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import ProgressBar from "../../components/ProgressBar";
import schemas from "../../forms/contactInfo";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "../../forms/contactInfo/schema.contactInfo.actions";
import * as S from "./ContactInfo.styles";

interface IProps {}

const ContactInfo: React.FC<IProps> = (_: IProps) => {
  // Router
  const navigate = useNavigate();
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();
  const { lead, isFetching, fetchError } = useGetLead({
    leadId,
    publicId,
  });
  const { quote } = useGetQuote({
    leadId,
    publicId,
    quoteId,
  });

  // Redux
  const dispatch = useDispatch();

  // Form
  const [formId] = React.useState(generateFormId());
  const [isFormInitialized, setIsFormInitialized] = React.useState(false);

  // Lifecycle
  React.useEffect(() => {
    if (!lead || isFormInitialized) {
      return;
    }

    dispatch(
      formActions.createForm({
        formId,
        // @ts-ignore
        schemas: schemas({ lead, publicId }),
        withSummary: false,
      }),
    );

    setIsFormInitialized(true);
  }, [formId, isFormInitialized, lead, publicId]);

  // Event handlers
  const handlePressBack = () => {
    if (!leadId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/options",
        queryParams: {
          leadId,
          quoteId,
          publicId,
        },
      }),
    );
  };

  const handleSuccess = () => {
    if (!leadId || !quoteId || !publicId) {
      return;
    }

    navigate(
      appendQueryParams({
        path: "/request/overview",
        queryParams: { leadId, quoteId, publicId },
      }),
    );
  };

  // Render
  if (!leadId || !publicId) {
    return <Navigate to={"/"} />;
  }

  if (!quoteId) {
    return (
      <Navigate
        to={appendQueryParams({
          path: "/property/options",
          queryParams: {
            leadId,
            quoteId,
            publicId,
          },
        })}
      />
    );
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetching) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchError) {
      return;
    }

    return <ErrorLead errorLead={fetchError} />;
  };

  const renderLead = () => {
    if (!lead) {
      return;
    }

    if (!isFormInitialized) {
      return renderLoading();
    }

    return (
      <>
        <ProgressBar steps={getSteps()} currentStep={2} />

        <Spacer weight={ESpacerWeight.W32} />

        <QuoteInfoBanner lead={lead} quote={quote} />

        <Spacer weight={ESpacerWeight.W24} />

        <FormMultistep
          formId={formId}
          // @ts-ignore state match
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            leadId,
          )}
          // @ts-ignore state match
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            leadId,
          )}
          submitActionCreator={submitActionCreator({ lead, publicId })}
          customSuccessAction={handleSuccess}
          showBackButton={true}
          onPressBack={handlePressBack}
          submitTitle={getLocalizedText("system.next")}
        />
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        {renderLead() || renderIsFetching() || renderError() || renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default ContactInfo;
