var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import FormSegmentedControl from "../FormSegmentedControl";
var FormInlineSelectJsonFromSchema = /** @class */ (function (_super) {
    __extends(FormInlineSelectJsonFromSchema, _super);
    function FormInlineSelectJsonFromSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        return _this;
    }
    FormInlineSelectJsonFromSchema.prototype.render = function () {
        var _a = this.props, schema = _a.schema, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c, name = _a.name;
        var customUiSchema = uiSchema;
        // @ts-ignore
        var options = schema.options;
        var title = customUiSchema.title, extraInfoUrl = customUiSchema.extraInfoUrl, isDisabled = customUiSchema.isDisabled;
        var allFormData = formContext.getAllFormData();
        var handleSelect = function (newId) {
            onChange(newId);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, newId);
                }
            }, 0);
        };
        return (_jsx(FormSegmentedControl, { title: title, selected: formData, options: options, extraInfoUrl: extraInfoUrl, onPressExtraInfo: this.handlePressExtraInfo, onSelect: handleSelect, error: rawErrors[0], disabled: isDisabled ? isDisabled(allFormData) : false }));
    };
    return FormInlineSelectJsonFromSchema;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormInlineSelectJsonFromSchema;
