var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getFieldTitle } from "../../../redux/form/form.utils";
import * as S from "./FormSectionHeader.styles";
var FormSectionHeader = /** @class */ (function (_super) {
    __extends(FormSectionHeader, _super);
    function FormSectionHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormSectionHeader.prototype.render = function () {
        var _a = this.props, title = _a.title, withBorder = _a.withBorder;
        return (_jsxs(S.Wrap, { children: [_jsx(S.Title, { children: title }), withBorder && _jsx(S.Line, {})] }));
    };
    return FormSectionHeader;
}(React.PureComponent));
var FormSectionHeaderJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormSectionHeaderJsonFormSchema, _super);
    function FormSectionHeaderJsonFormSchema() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormSectionHeaderJsonFormSchema.prototype.render = function () {
        var _a = this.props, uiSchema = _a.uiSchema, _b = _a.formContext, formContext = _b === void 0 ? {} : _b;
        return (_jsx(FormSectionHeader, { title: getFieldTitle(uiSchema, formContext), withBorder: uiSchema.withBorder }));
    };
    return FormSectionHeaderJsonFormSchema;
}(React.PureComponent));
export { FormSectionHeaderJsonFormSchema };
// eslint-disable-next-line import/no-default-export
export default FormSectionHeader;
