import Form from "@rentiohq/shared-frontend/dist/components/components/Form";
import FormMultistepShared from "@rentiohq/shared-frontend/dist/components/components/FormMultistep";
import FullScreenActivityIndicator from "@rentiohq/shared-frontend/dist/components/components/FullScreenActivityIndicator";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import SubmitButtonGroup from "components/SubmitButtonGroup";
import { fields } from "config/form.config";
import React, { ReactNode } from "react";
import { ScrollView } from "react-native";
import * as S from "./FormMultistep.styles";
import { TProps } from "./FormMultistep.types";

interface IState {
  didPressSubmit: boolean;
  suppressError: boolean;
}

class FormMultistep extends React.PureComponent<TProps, IState> {
  private form: Form | null | undefined;

  constructor(props: TProps) {
    super(props);

    this.state = {
      didPressSubmit: false,
      suppressError: false,
    };
  }

  // Lifecycle methods
  public componentDidMount() {
    document.addEventListener("keydown", this.handleKeydown);
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  public UNSAFE_componentWillReceiveProps = ({
    isPerformingSubmitAction,
    performingSubmitActionResult,
    performingSubmitActionError,
  }: TProps) => {
    const { isPerformingSubmitAction: wasPerformingSubmitAction } = this.props;
    if (
      wasPerformingSubmitAction &&
      !isPerformingSubmitAction &&
      !performingSubmitActionError
    ) {
      this.props.successAction(performingSubmitActionResult);
      if (this.props.customSuccessAction) {
        this.props.customSuccessAction(performingSubmitActionResult);
      }
    }
  };

  // Render
  public render = () => {
    const { didPressSubmit } = this.state;
    const { isPerformingSubmitAction } = this.props;

    return (
      <S.Wrap>
        <FormMultistepShared
          formProps={{
            // @ts-ignore
            ref: ref => (this.form = ref),
            fields,
            renderControls: this.renderControls,
            renderContentWrapper: this.renderFormContentWrapper,
          }}
          formId={this.props.formId}
          stepIndex={this.getStepIndex()}
          onPressUpdate={() => {}}
          onPressNext={() => {}}
          onPressViewSummary={() => {}}
          onPressSubmit={this.handlePressSubmit}
          onSelectMore={this.props.onSelectMore}
          onPressExtraInfo={this.handlePressExtraInfo}
          didAccessFromSummary={false}
        />

        {didPressSubmit && isPerformingSubmitAction && (
          <FullScreenActivityIndicator />
        )}
      </S.Wrap>
    );
  };

  private renderFormContentWrapper = (renderContent: () => ReactNode) => (
    <ScrollView>
      <Spacer weight={ESpacerWeight.W08} />
      {renderContent()}
    </ScrollView>
  );

  private renderControls = (onSubmitAttempt: () => void) => {
    const {
      submitTitle,
      renderExtraLeadingControls,
      renderExtraTrailingControls,
      onPressBack,
      showBackButton,
    } = this.props;

    const title = submitTitle || getLocalizedText("system.save");

    return (
      <S.ButtonWrap>
        {renderExtraLeadingControls?.()}

        <SubmitButtonGroup
          onPressBack={showBackButton && onPressBack ? onPressBack : undefined}
          onPressNext={onSubmitAttempt}
          titleNext={title}
        />

        {renderExtraTrailingControls?.()}
      </S.ButtonWrap>
    );
  };

  // Event handlers
  private handleKeydown = (event: KeyboardEvent) => {
    if (event.which === 13 && this.form) {
      this.form.handleSubmitAttempt();
    }
  };

  private handlePressSubmit = () => {
    this.setState({ didPressSubmit: true, suppressError: false });

    const { submitAction, customSubmitAction, partialData } = this.props;

    if (customSubmitAction) {
      customSubmitAction(partialData);
      return;
    }

    submitAction(partialData);
  };

  private handlePressExtraInfo = (extraInfoUrl?: string) => {
    if (extraInfoUrl) {
      window.open(extraInfoUrl, "_blank");
    }
  };

  // Helpers
  private getStepIndex = () => {
    return 0;
  };
}

// eslint-disable-next-line import/no-default-export
export default FormMultistep;
