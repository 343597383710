import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import React from "react";
import * as S from "./ConfirmCheckbox.styles";

interface IProps {
  title: string;
  ctaTitle?: string;
  ctaLink?: string;
  isConfirmed: boolean;
  onConfirm: () => void;
}

const ConfirmCheckbox: React.FC<IProps> = (props: IProps) => {
  const { title, ctaTitle, ctaLink, isConfirmed, onConfirm } = props;

  // Event handlers
  const handlePressCta = () => {
    if (!ctaLink) {
      return;
    }

    window.open(ctaLink, "_blank");
  };

  // Render
  return (
    <S.Wrap isConfirmed={isConfirmed} onClick={onConfirm}>
      <S.InnerWrap>
        <S.Checkbox isConfirmed={isConfirmed} />
        <div>
          <S.Title>{title}</S.Title>
          {ctaTitle && (
            <ButtonBasic onPress={handlePressCta} title={ctaTitle} />
          )}
        </div>
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default ConfirmCheckbox;
