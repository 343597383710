import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import styledNative from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import "rc-slider/assets/index.css";
import { ActivityIndicator } from "react-native";

export const Wrap = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.theme.colors.global2};
`;

export const Badge = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 8px;
  border-radius: 5px;
  color: ${props => props.theme.colors.neutral2};
  background-color: ${props => props.theme.colors.global5};
`;

export const ActivityIndicatorTitle = styledNative(ActivityIndicator)`
height: 10px;
margin-left: 8px;
`;

export const SliderWrap = styled.div`
  padding: 0 4px;
`;

export const DotLabelsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const DotLabel = styled.label`
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.global5};
`;

export const LegalWrap = styled.div`
  margin-top: 16px;
`;

export const LegalInfo = styled.span`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: ${props => props.theme.colors.global2};
`;
