import React from "react";
import * as S from "./OverviewSection.styles";

interface IProps {
  title: string;
  items: {
    id: string;
    value?: string;
    valueAsError?: boolean;
    extraInfo?: string;
  }[];
  onClickEdit: () => void;
}

const InfoList: React.FC<IProps> = (props: IProps) => {
  const { title, items, onClickEdit } = props;
  return (
    <S.Wrap>
      <S.HeaderWrap>
        <S.Title>{title}</S.Title>
        <button onClick={onClickEdit}>
          <S.EditIcon />
        </button>
      </S.HeaderWrap>
      {items.map(item => {
        const title = [item.id];
        if (item.extraInfo) {
          title.push(item.extraInfo);
        }

        return (
          <S.InfoItem>
            <S.InfoItemTitle>{title.join(" | ")}</S.InfoItemTitle>
            <S.InfoItemValue asError={item.valueAsError || false}>
              {item.value || "-"}
            </S.InfoItemValue>
          </S.InfoItem>
        );
      })}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default InfoList;
