var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var Wrap = styled(View)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  ", "\n  ", "\n"], ["\n  width: 100%;\n  ", "\n  ", "\n"])), Platform.select({
    ios: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      margin-top: 24px;\n      margin-bottom: ", ";\n    "], ["\n      margin-top: 24px;\n      margin-bottom: ", ";\n    "])), function (props) { return (props.isFormField ? "24px" : "8px"); }),
    android: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin-top: 24px;\n      margin-bottom: ", ";\n    "], ["\n      margin-top: 24px;\n      margin-bottom: ", ";\n    "])), function (props) { return (props.isFormField ? "24px" : "8px"); }),
    web: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      margin-top: 10px;\n      margin-bottom: ", ";\n    "], ["\n      margin-top: 10px;\n      margin-bottom: ", ";\n    "])), function (props) { return (props.isFormField ? "0px" : "8px"); }),
}), function (props) {
    return props.disabled
        ? "\n    pointer-events: none;\n    opacity: .4;\n  "
        : "";
});
export var titleText = styled(Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  flex: 1;\n  padding: 16px;\n  color: ", ";\n"], ["\n  flex: 1;\n  padding: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global5; });
export var OptionsWrap = styled(View)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  ", "\n  padding-left: 6px;\n  padding-right: 6px;\n  padding-top: 6px;\n  padding-bottom: 6px;\n  flex-direction: row;\n  border-radius: 4px;\n  background-color: ", ";\n"], ["\n  ", "\n  padding-left: 6px;\n  padding-right: 6px;\n  padding-top: 6px;\n  padding-bottom: 6px;\n  flex-direction: row;\n  border-radius: 4px;\n  background-color: ", ";\n"])), Platform.select({
    ios: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      margin-left: 10px;\n      margin-right: 10px;\n    "], ["\n      margin-left: 10px;\n      margin-right: 10px;\n    "]))),
    android: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      margin-left: 10px;\n      margin-right: 10px;\n    "], ["\n      margin-left: 10px;\n      margin-right: 10px;\n    "]))),
}), function (props) {
    return props.hasError ? props.theme.colors.error : props.theme.colors.global4;
});
export var InfoButton = styled(TouchableOpacity)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  padding-left: 8px;\n  margin-bottom: 9px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 24px;\n  height: 24px;\n  padding-left: 8px;\n  margin-bottom: 9px;\n  align-items: center;\n  justify-content: center;\n"])));
export var InfoIcon = styled(Image).attrs(function () { return ({
    source: images.ICO_INFO_CIRCLE_GREEN,
}); })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n"], ["\n  width: 20px;\n  height: 20px;\n"])));
export var HeaderWrap = styled(View)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding-left: 12px;\n  padding-right: 12px;\n  flex-direction: row;\n  align-items: flex-end;\n  align-items: center;\n"], ["\n  padding-left: 12px;\n  padding-right: 12px;\n  flex-direction: row;\n  align-items: flex-end;\n  align-items: center;\n"])));
export var HeaderText = styled(Text)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-size: 15px;\n  margin-bottom: 12px;\n  color: ", ";\n"], ["\n  font-size: 15px;\n  margin-bottom: 12px;\n  color: ", ";\n"])), function (props) {
    return props.hasError ? props.theme.colors.errorText : props.theme.colors.global5;
});
export var OptionButton = styled(TouchableOpacity)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  border-radius: 4px;\n  background-color: ", ";\n  box-shadow: ", ";\n  elevation: ", ";\n"], ["\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  border-radius: 4px;\n  background-color: ", ";\n  box-shadow: ", ";\n  elevation: ", ";\n"])), function (props) {
    return props.selected ? props.theme.colors.neutral2 : "transparent";
}, function (props) {
    return props.selected ? "0px 0px 6px rgba(0, 0, 0, 0.2)" : "none";
}, function (props) { return (props.selected ? 5 : 0); });
export var OptionTitle = styled(Text)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  text-align: center;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  font-size: ", ";\n  color: ", ";\n  font-weight: ", ";\n"], ["\n  text-align: center;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  font-size: ", ";\n  color: ", ";\n  font-weight: ", ";\n"])), function (props) { return (props.isFormField ? "14px" : "11px"); }, function (props) {
    return props.selected ? props.theme.colors.global5 : props.theme.colors.neutral1;
}, function (props) { return (props.selected ? 600 : 400); });
// export const HeaderWrap = styled(View)`
//   padding-left: 16px;
//   padding-right: 16px;
// `;
// interface IWithError {
//   hasError: boolean;
// }
// export const HeaderText = styled(Text)<IWithError>`
//   font-size: 13px;
//   color: ${props =>
//     props.hasError ? props.theme.colors.errorText : props.theme.colors.global5};
// `;
// export const TextInputStyled = styled(TextInput)`
//   :focus {
//     outline: none;
//   }
//   padding: 16px;
//   font-size: 14px;
//   color: ${props => props.theme.colors.neutral1};
//   ${props =>
//     Platform.select({
//       web: `
//         placeholder-text-color: ${props.theme.colors.global4};
//         caret-color: ${props.theme.colors.neutral1};
//       `
//     })}
// `;
// export const Separator = styled(View)<IWithError>`
//   width: 100%;
//   height: 1px;
//   background-color: ${props =>
//     props.hasError ? props.theme.colors.errorText : props.theme.colors.global3};
// `;
export var ErrorText = styled(Text)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  text-align: right;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n"], ["\n  text-align: right;\n  padding-left: 16px;\n  padding-right: 16px;\n  margin-top: 4px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.errorText; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
