import { append } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
export var getAgreement = function (leadId, quoteId, agreementId, publicId) {
    return api.get(append("/leads/".concat(leadId, "/quotes/").concat(quoteId, "/agreements/").concat(agreementId), {
        publicId: publicId,
    }, false));
};
export var createAgreement = function (leadId, quoteId, publicId) {
    return api.post(append("/leads/".concat(leadId, "/quotes/").concat(quoteId, "/agreements"), { publicId: publicId }, false));
};
export var mailAgreement = function (leadId, quoteId, agreementId, publicId) {
    return api.post(append("/leads/".concat(leadId, "/quotes/").concat(quoteId, "/agreements/").concat(agreementId, "/send-email"), { publicId: publicId }, false));
};
