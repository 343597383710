import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";

export const Wrap = styled.div``;

export const CompactErrorTitle = styled.span`
  display: block;
  font-size: 18px;
  padding-bottom: 8px;
  font-weight: bold;
  color: ${props => props.theme.colors.errorText};
`;

export const ErrorStateWrap = styled.div`
  position: relative;
  width: 100%;
  height: 330px;
`;
