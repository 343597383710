var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Image, Text, TouchableOpacity, View, } from "react-native";
import { desktopStyles } from "../../../utils/style.utils";
import images from "../../assets";
import styled, { css } from "../../theme/styled-components.native";
export var StoryWrap = styled(View)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 320px;\n  ", "\n"], ["\n  width: 320px;\n  ", "\n"])), desktopStyles(css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n  "], ["\n    width: 100%;\n  "])))));
export var InfoWrap = styled(TouchableOpacity)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n"], ["\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n"])));
export var DateText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  padding: 16px;\n"], ["\n  flex: 1;\n  padding: 16px;\n"])));
export var Placeholder = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  padding: 16px;\n  /* Info: lightgray to match text input placeholder color */\n  color: lightgray;\n"], ["\n  flex: 1;\n  padding: 16px;\n  /* Info: lightgray to match text input placeholder color */\n  color: lightgray;\n"])));
export var ClearButton = styled(TouchableOpacity)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 48px;\n  height: 40px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 48px;\n  height: 40px;\n  align-items: center;\n  justify-content: center;\n"])));
export var ClearIcon = styled(Image).attrs(function () { return ({
    resizeMode: "contain",
    source: images.ICO_CLOSE_ROUND_DARK,
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n"], ["\n  width: 20px;\n  height: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
