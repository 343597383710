import SwitchToggle from "@rentiohq/shared-frontend/dist/components/components/SwitchToggle";
import { IQuote } from "@rentiohq/shared-frontend/dist/types/insurance.quote.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import React from "react";
import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from "utils/styles.utils";
import * as S from "./QuoteOptionBase.styles";

interface IProps {
  quote?: IQuote;
  icon?: string;
  title: string;
  info: string;
  price?: number;
  isCreatingQuote?: boolean;
  isActive: boolean;
  onValueChange?: (isActive: boolean) => boolean | void; // Optional success boolean;
  renderExtraContent?: () => React.ReactElement | null;
  showToggle?: boolean;
}

const QuoteOptionBase: React.FC<IProps> = (props: IProps) => {
  const {
    icon,
    title,
    info,
    price,
    isActive,
    isCreatingQuote,
    renderExtraContent,
    onValueChange,
    showToggle = true,
  } = props;

  // State
  const [didToggleValue, setDidToggleValue] = React.useState(false);

  // Lifecycle
  React.useEffect(() => {
    if (isCreatingQuote) {
      return;
    }

    setDidToggleValue(false);
  }, [isCreatingQuote]);

  // Event handlers
  const handleValueChange = () => {
    const success = onValueChange?.(!isActive);

    setDidToggleValue(true);

    if (success === false) {
      return;
    }
  };

  // Render
  return (
    <S.Wrap isActive={isActive}>
      <S.ContentWrap>
        <S.IconWrap>
          <S.Icon src={icon} />
        </S.IconWrap>
        <S.InfoWrap>
          <S.InfoHeaderWrap>
            <S.Title>{title}</S.Title>
            <S.SwitchAndLoadingWrap>
              {isCreatingQuote && didToggleValue && (
                <S.ActivityIndicatorWrap>
                  <S.ActivityIndicator />
                </S.ActivityIndicatorWrap>
              )}
              {showToggle && (
                <SwitchToggle
                  value={isActive}
                  onValueChange={handleValueChange}
                />
              )}
            </S.SwitchAndLoadingWrap>
          </S.InfoHeaderWrap>
          <S.ContentHeaderWrap>
            <S.Content>
              <span dangerouslySetInnerHTML={{ __html: info }} />
            </S.Content>
            <ShowOnlyOnDesktop>
              <S.PriceWrap>
                <S.Price>
                  {isActive &&
                    !!price &&
                    getLocalizedText("system.amount.yearly", {
                      amount: formatCurrency(price),
                    })}
                </S.Price>
              </S.PriceWrap>
            </ShowOnlyOnDesktop>
          </S.ContentHeaderWrap>
          <ShowOnlyOnDesktop>{renderExtraContent?.()}</ShowOnlyOnDesktop>
        </S.InfoWrap>
      </S.ContentWrap>
      <ShowOnlyOnMobile>{renderExtraContent?.()}</ShowOnlyOnMobile>
      {isActive && !!price && (
        <ShowOnlyOnMobile>
          <S.PriceWrap>
            <S.Price>
              {getLocalizedText("system.amount.yearly", {
                amount: formatCurrency(price),
              })}
            </S.Price>
          </S.PriceWrap>
        </ShowOnlyOnMobile>
      )}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default QuoteOptionBase;
