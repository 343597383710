import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";

export const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  @media ${CONFIG.RESPONSIVE.BREAKPOINT.MOBILE} {
    flex-direction: column-reverse;
  }
`;
