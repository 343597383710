var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { getLocalizedText } from "../../../utils/i18n/i18n.utils";
import ButtonPrimary from "../ButtonPrimary";
import FormInputBase from "../FormInputBase";
import IbanListItem from "../IbanListItem/IbanListItem";
import Spacer, { ESpacerWeight } from "../Spacer";
import * as S from "./FormIbanSelectInline.styles";
var FormIbanSelectInline = /** @class */ (function (_super) {
    __extends(FormIbanSelectInline, _super);
    function FormIbanSelectInline() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handleSelectIban = function (ibanId) { return function () {
            _this.props.onSelectIban(ibanId);
        }; };
        return _this;
    }
    // Lifecycle methods
    FormIbanSelectInline.prototype.componentWillMount = function () {
        this.props.getIbans();
    };
    // Render
    FormIbanSelectInline.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, addIbanCtaTitle = _a.addIbanCtaTitle, error = _a.error, optional = _a.optional, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo, isFetching = _a.isFetching, disabled = _a.disabled, ibans = _a.ibans, info = _a.info, selectedIbanId = _a.selectedIbanId, onPressAddIban = _a.onPressAddIban;
        var showExtraButton = (ibans && ibans.length > 0) || isFetching;
        return (_jsxs(FormInputBase, { title: title, info: info, error: error, optional: optional, extraInfoUrl: extraInfoUrl, onPressExtraInfo: onPressExtraInfo, extraButtonTitle: showExtraButton ? addIbanCtaTitle : undefined, onPressExtraButton: onPressAddIban, disabled: disabled, children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), (function () {
                    if (ibans && ibans.length > 0) {
                        return ibans.map(function (iban, index) { return (_jsxs(React.Fragment, { children: [index !== 0 && _jsx(S.Separator, {}), _jsx(IbanListItem, { iban: iban, selected: iban.id === selectedIbanId, onPress: _this.handleSelectIban(iban.id) })] }, index)); });
                    }
                    if (isFetching) {
                        return _jsx(S.ActivityIndicatorStyled, {});
                    }
                    return (_jsx(S.EmptyButtonWrap, { children: _jsx(ButtonPrimary, { title: getLocalizedText("iban.add"), onPress: onPressAddIban, isInverted: true }) }));
                })()] }));
    };
    return FormIbanSelectInline;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default FormIbanSelectInline;
