import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EPropertyAdjoined } from "@rentiohq/shared-frontend/dist/types/property.types";
import { removeNullValuesFromObject } from "@rentiohq/shared-frontend/dist/utils/object.utils";
import { EField, IExtraData } from "./schema.propertyInfo.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { lead } = extraData;

  const { propertySurfaceArea = 0, propertyNumberOfFloors = 0 } = lead;

  const propertySurfaceAreaMapped =
    propertySurfaceArea > 0 ? Math.ceil(propertySurfaceArea) : undefined;

  const propertyNumberOfFloorsMapped =
    propertyNumberOfFloors > 0 ? propertyNumberOfFloors : undefined;

  return removeNullValuesFromObject({
    // Contract
    [EField.ContractStartDate]: lead.contractStartDate,
    // Property
    [EField.BuildingSurfaceArea]: propertySurfaceAreaMapped,
    // [EField.BuildingFloor]: lead.propertyFloor,
    [EField.BuildingLayers]: propertyNumberOfFloorsMapped,
    [EField.BuildingAdjoined]: [
      EPropertyAdjoined.Freestanding,
      EPropertyAdjoined.OneSide,
      EPropertyAdjoined.BothSides,
    ].includes(lead.propertyAdjoined)
      ? lead.propertyAdjoined
      : undefined,
  });
};
