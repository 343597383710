import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import {
  useActiveAgreement,
  useMailAgreement,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.hooks";
import { useGetLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.hooks";
import { useGetQuote } from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.hooks";
import { ELeadStatus } from "@rentiohq/shared-frontend/dist/types/insurance.lead.types";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import ButtonMail from "components/ButtonMail";
import ConfirmCheckbox from "components/ConfirmCheckbox";
import QuoteInfoBanner from "components/QuoteInfoBanner";
import { getSteps } from "modules/request/config/progressBar.config";
import React from "react";
import { ActivityIndicator } from "react-native";
import { Navigate } from "react-router-dom";
import { SubmitButton } from "utils/styles.utils";
import { appendQueryParams, useInsuranceQueryParams } from "utils/url.utils";
import ErrorLead from "../../components/ErrorLead";
import ProgressBar from "../../components/ProgressBar";
import * as S from "./Signature.styles";

const CONFIRM_CHECKBOXES = 5;

interface IProps {}

const Signature: React.FC<IProps> = (_: IProps) => {
  // State
  const [isConfirmed, setIsConfirmed] = React.useState<{
    [index: number]: boolean | undefined;
  }>({});

  let isAllConfirmed = true;
  Array(CONFIRM_CHECKBOXES)
    .fill(0)
    .forEach((_, index) => {
      if (isConfirmed[index] === undefined || isConfirmed[index] === false) {
        isAllConfirmed = false;
      }
    });

  // Custom hooks
  const { leadId, quoteId, publicId } = useInsuranceQueryParams();

  const {
    lead,
    isFetching: isFetchingLead,
    fetchError: fetchLeadError,
  } = useGetLead({
    leadId,
    publicId,
  });

  const {
    quote,
    isFetching: isFetchingQuote,
    fetchError: fetchQuoteError,
  } = useGetQuote({
    leadId,
    quoteId,
    publicId,
  });

  const { activeAgreement, isFetching: isFetchingActiveAgreement } =
    useActiveAgreement({
      leadId,
      quote,
      publicId,
    });

  const { mailAgreementAndNavigate } = useMailAgreement({
    leadId,
    quote,
    publicId,
  });

  // Event handlers
  const handleConfirmAll = () => {
    const result: typeof isConfirmed = {};

    Array(CONFIRM_CHECKBOXES)
      .fill(0)
      .forEach((_, index) => {
        result[index] = true;
      });

    setIsConfirmed(result);
  };

  const handleConfirm = (index: number) => () => {
    setIsConfirmed({ ...isConfirmed, [index]: !(isConfirmed[index] || false) });
  };

  const handlePressSign = () => {
    if (!isAllConfirmed) {
      return;
    }

    if (!activeAgreement?.connectiveUrl) {
      return;
    }

    window.open(activeAgreement.connectiveUrl, "_self");
  };

  const handlePressMail = () => {
    mailAgreementAndNavigate();
  };

  // Render
  if (!leadId || !quoteId || !publicId) {
    return <Navigate to={"/"} />;
  }

  const renderLoading = () => <ActivityIndicator />;

  const renderIsFetching = () => {
    if (!isFetchingLead && !isFetchingQuote && !isFetchingActiveAgreement) {
      return;
    }

    return renderLoading();
  };

  const renderError = () => {
    if (!fetchLeadError && !fetchQuoteError) {
      return;
    }

    return (
      <ErrorLead errorLead={fetchLeadError} errorQuote={fetchQuoteError} />
    );
  };

  const renderContent = () => {
    if (!lead || !quote || !activeAgreement) {
      return;
    }

    switch (lead.status) {
      case ELeadStatus.Paid:
      case ELeadStatus.SentToBaloise:
      case ELeadStatus.ProcessedByBaloise:
        return (
          <Navigate
            to={appendQueryParams({
              path: "/request/success",
              queryParams: {
                leadId,
                quoteId,
                publicId,
              },
            })}
          />
        );

      case ELeadStatus.Signed:
        return (
          <Navigate
            to={appendQueryParams({
              path: "/request/payment",
              queryParams: {
                leadId,
                quoteId,
                publicId,
              },
            })}
          />
        );

      default:
        break;
    }

    return (
      <>
        <ProgressBar steps={getSteps()} currentStep={4} />

        <Spacer weight={ESpacerWeight.W32} />

        <QuoteInfoBanner lead={lead} quote={quote} showDetailedInfo={true} />

        <Spacer weight={ESpacerWeight.W32} />

        <S.ConfirmAllWrap>
          <ButtonBasic
            title={getLocalizedText("insurance.signature.cta.confirm.all")}
            onPress={handleConfirmAll}
          />
        </S.ConfirmAllWrap>

        <Spacer weight={ESpacerWeight.W16} />

        {Array(CONFIRM_CHECKBOXES)
          .fill(0)
          .map((_, index) => {
            const showCta = [
              EPropertyTypeId.House,
              EPropertyTypeId.Apartment,
            ].includes(index);
            return (
              <>
                <ConfirmCheckbox
                  title={getLocalizedText(
                    `insurance.signature.cta.confirm.${index + 1}`,
                  )}
                  ctaTitle={
                    showCta
                      ? getLocalizedText(
                          `insurance.signature.cta.confirm.${
                            index + 1
                          }.cta.title`,
                        )
                      : undefined
                  }
                  ctaLink={
                    showCta
                      ? getLocalizedText(
                          `insurance.signature.cta.confirm.${
                            index + 1
                          }.cta.link`,
                        )
                      : undefined
                  }
                  isConfirmed={isConfirmed[index] === true}
                  onConfirm={handleConfirm(index)}
                />

                <Spacer weight={ESpacerWeight.W08} />
              </>
            );
          })}

        <Spacer weight={ESpacerWeight.W16} />

        {isConfirmed && (
          <>
            <S.NextStepsInfo>
              {getLocalizedText("insurance.signature.next_steps")}
            </S.NextStepsInfo>

            <Spacer weight={ESpacerWeight.W32} />
          </>
        )}

        <SubmitButton
          title={getLocalizedText("insurance.signature.cta.sign")}
          onPress={handlePressSign}
          isDisabled={!isAllConfirmed}
        />

        <Spacer weight={ESpacerWeight.W16} />

        <ButtonMail
          title={getLocalizedText("insurance.quote.cta.mail")}
          onPress={handlePressMail}
        />
      </>
    );
  };

  return (
    <S.Wrap>
      <S.InnerWrap>
        {renderContent() ||
          renderIsFetching() ||
          renderError() ||
          renderLoading()}
      </S.InnerWrap>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Signature;
