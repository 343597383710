import formReducer, {
  IFormState,
  REDUCER_KEY_FORM,
} from "@rentiohq/shared-frontend/dist/redux/form/form.reducer";
import {
  II18nState,
  REDUCER_KEY_I18N,
} from "@rentiohq/shared-frontend/dist/redux/i18n/i18n.reducer";
import persistI18nReducer from "@rentiohq/shared-frontend/dist/redux/i18n/i18n.reducerPersist";
import agreementReducer, {
  IAgreementState,
  REDUCER_KEY_AGREEMENT,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/agreement/agreement.reducer";
import leadReducer, {
  ILeadState,
  REDUCER_KEY_LEAD,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.reducer";
import paymentReducer, {
  IPaymentState,
  REDUCER_KEY_PAYMENT,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/payment/payment.reducer";
import quoteReducer, {
  IQuoteState,
  REDUCER_KEY_QUOTE,
} from "@rentiohq/shared-frontend/dist/reduxInsurance/quote/quote.reducer";
import { combineReducers } from "redux";

export interface IRootState {
  [REDUCER_KEY_AGREEMENT]: IAgreementState;
  [REDUCER_KEY_FORM]: IFormState;
  [REDUCER_KEY_I18N]: II18nState;
  [REDUCER_KEY_LEAD]: ILeadState;
  [REDUCER_KEY_PAYMENT]: IPaymentState;
  [REDUCER_KEY_QUOTE]: IQuoteState;
}

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  agreement: agreementReducer,
  form: formReducer,
  i18n: persistI18nReducer,
  lead: leadReducer,
  payment: paymentReducer,
  quote: quoteReducer,
});
