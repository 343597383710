import { REDUCER_KEY_LEAD } from "./lead.reducer";
export var getLead = function (state, leadId) {
    return state.lead.leads[leadId];
};
export var isFetchingLead = function (state, leadId) { var _a; return ((_a = state.lead.leadsById[leadId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchLeadError = function (state, leadId) { var _a; return (_a = state.lead.leadsById[leadId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var isUpdatingLead = function (state, leadId) { var _a; return ((_a = state.lead.leadsById[leadId]) === null || _a === void 0 ? void 0 : _a.isUpdating) || false; };
export var updateLeadError = function (state, leadId) { var _a; return (_a = state.lead.leadsById[leadId]) === null || _a === void 0 ? void 0 : _a.updateError; };
export var getLeadsForPage = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.lead.pagedLeads[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.leadIds) === null || _c === void 0 ? void 0 : _c.map(function (leadId) { return state.lead.leads[leadId]; }).filter(function (lead) { return !!lead; });
};
export var isFetchingLeadsForPage = function (state, identifier, page) { var _a, _b; return ((_b = (_a = state.lead.pagedLeads[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) || false; };
export var leadsForPageFetchError = function (state, identifier, page) { var _a, _b; return (_b = (_a = state.lead.pagedLeads[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
export var leadsCount = function (state, identifier) { var _a; return (_a = state.lead.pagedLeads[identifier]) === null || _a === void 0 ? void 0 : _a.count; };
